// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/default-param-last */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-anonymous-default-export */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import StaffActionTypes from './staff.type';
import { docodeResponse } from '../../_common/common.utils';

const INITIAL_STATE = {
	staffListFetchResponse: null,
	staffListResponseArr: [],
	staffAddResponse: null,
	staffDetailsFetchResponse: null,
	fetchStaffDetailsResponseArr: [],
	staffUpdateResponse: null,
	staffDeleteResponse: null,
};

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case StaffActionTypes.STAFF_LIST_FETCH_SUCCESS:
			return {
				...state,
				staffListFetchResponse: action.payload,
				staffListResponseArr: docodeResponse(action.payload, 'Fetch_User_List'),
			};

		case StaffActionTypes.STAFF_LIST_FETCH_FAILURE:
			return { ...state, staffListFetchResponse: action.payload };

		case StaffActionTypes.STAFF_LIST_FETCH_RESPONSE_CHANGED:
			return { ...state, staffListFetchResponse: null };

		/** *******************  STAFF FETCH LIST END ******************* */

		/** *******************  STAFF ADD START ******************* */

		case StaffActionTypes.STAFF_ADD_SUCCESS:
			return {
				...state,
				staffAddResponse: action.payload,
			};

		case StaffActionTypes.STAFF_ADD_FAILURE:
			return { ...state, staffAddResponse: action.payload };

		case StaffActionTypes.STAFF_ADD_RESPONSE_CHANGED:
			return { ...state, staffAddResponse: null };

		/** *******************  STAFF ADD END ******************* */

		/** *******************  STAFF DETAILS FETCH  START ******************* */

		case StaffActionTypes.FETCH_STAFF_DETAILS_SUCCESS:
			return {
				...state,
				staffDetailsFetchResponse: action.payload,
				fetchStaffDetailsResponseArr: docodeResponse(action.payload, 'Fetch_User_Details'),
			};

		case StaffActionTypes.FETCH_STAFF_DETAILS_FAILURE:
			return { ...state, staffDetailsFetchResponse: action.payload };

		case StaffActionTypes.FETCH_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, staffDetailsFetchResponse: null };

		/** *******************  STAFF DETAILS FETCH  END ******************* */

		/** *******************  STAFF UPDATE START ******************* */

		case StaffActionTypes.STAFF_UPDAE_SUCCESS:
			return {
				...state,
				staffUpdateResponse: action.payload,
			};

		case StaffActionTypes.STAFF_UPDAE_FAILURE:
			return { ...state, staffUpdateResponse: action.payload };

		case StaffActionTypes.STAFF_UPDAE_RESPONSE_CHANGED:
			return { ...state, staffUpdateResponse: null };

		/** *******************  STAFF UPDATE END ******************* */

		/** *******************  STAFF DELETE START ******************* */

		case StaffActionTypes.STAFF_DELETE_SUCCESS:
			return {
				...state,
				staffDeleteResponse: action.payload,
			};

		case StaffActionTypes.STAFF_DELETE_FAILURE:
			return { ...state, staffDeleteResponse: action.payload };

		case StaffActionTypes.STAFF_DELETE_RESPONSE_CHANGED:
			return { ...state, staffDeleteResponse: null };

		/** *******************  STAFF DELETE END ******************* */

		default:
			return state;
	}
};
