// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import roleActionTypes from './role.types';
import { fetchListRole, getRoleDetails, addRole, updateRoles, deleteRole } from './role.services';
import {
	roleListFetchSuccess,
	roleListFetchFailure,
	roleListFetchResponseChanged,
	fetchRoleDetailsSuccess,
	fetchRoleDetailsFailure,
	fetchRoleDetailsResponseChanged,
	roleAddSuccess,
	roleAddFailure,
	roleAddResponseChanged,
	roleUpdateSuccess,
	roleUpdateFailure,
	roleUpdateResponseChanged,
	deleteRoleSuccess,
	deleteRoleFailure,
	deleteRoleResponseChanged,
} from './role.action';
import { encodeJson } from '../../_common/enode-decode';

/** *********************** SATRT ROLE FETCH LIST ************************ */
export function* roleListFetch({ payload }: any) {
	const key = 'Fetch_Role_List';
	try {
		const { limit, companyID } = payload;

		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchListRole, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(roleListFetchFailure(responseData.data));
		} else {
			yield put(roleListFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(roleListFetchFailure(error));
	}
}

export function* roleFetchResponseReset() {
	yield put(roleListFetchResponseChanged());
}

export function* onFetchListRoles() {
	yield takeLatest(roleActionTypes.ROLE_LIST_FETCH_START, roleListFetch);
}

export function* onRoleListFetchResponseReset() {
	yield takeLatest(roleActionTypes.ROLE_LIST_RESPONSE_RESET_START, roleFetchResponseReset);
}
/** *********************** END ROLE FETCH LIST ************************ */

/** *********************** SATRT ROLE FETCH LIST ************************ */
export function* roleDetailsFetch({ payload }: any) {
	const key = 'Fetch_Role_Details';
	try {
		const { companyID, roleID } = payload;

		const jsonData = {
			CompanyID: companyID,
			RoleID: roleID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(getRoleDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchRoleDetailsFailure(responseData.data));
		} else {
			yield put(fetchRoleDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchRoleDetailsFailure(error));
	}
}

export function* roleDetailsFetchResponseReset() {
	yield put(fetchRoleDetailsResponseChanged());
}

export function* onRoleDetailsFetch() {
	yield takeLatest(roleActionTypes.FETCH_ROLE_DETAILS_START, roleDetailsFetch);
}

export function* onRoleDetailsFetchResponseReset() {
	yield takeLatest(
		roleActionTypes.FETCH_ROLE_DETAILS_RESPONSE_RESET_START,
		roleDetailsFetchResponseReset,
	);
}
/** *********************** END ROLE FETCH LIST ************************ */

/** *********************** SATRT ROLE FETCH LIST ************************ */
export function* RoleAdd({ payload }: any) {
	const key = 'Add_Role_Details';
	try {
		const { roleID, roleName, roletype, companyID, userID } = payload;

		const jsonData = {
			RoleID: roleID,
			RoleName: roleName,
			RoleType: roletype,
			CompanyID: companyID,
			CreatedBy: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(addRole, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(roleAddFailure(responseData.data));
		} else {
			yield put(roleAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(roleAddFailure(error));
	}
}

export function* roleAddResponseReset() {
	yield put(roleAddResponseChanged());
}

export function* onAddRole() {
	yield takeLatest(roleActionTypes.ROLE_ADD_START, RoleAdd);
}

export function* onRoleAddResponseReset() {
	yield takeLatest(roleActionTypes.ROLE_ADD_RESPONSE_RESET_START, roleAddResponseReset);
}
/** *********************** END ROLE ADD LIST ************************ */

/** *********************** SATRT ROLE UPDATE LIST ************************ */
export function* RoleUpdate({ payload }: any) {
	const key = 'Update_Role_Details';
	try {
		const { roleID, roleName, roletype, companyID, userID } = payload;

		const jsonData = {
			RoleID: roleID,
			RoleName: roleName,
			RoleType: roletype,
			CompanyID: companyID,
			CreatedBy: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateRoles, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(roleUpdateFailure(responseData.data));
		} else {
			yield put(roleUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(roleUpdateFailure(error));
	}
}

export function* roleUpdateResponseReset() {
	yield put(roleUpdateResponseChanged());
}

export function* onUpdateRole() {
	yield takeLatest(roleActionTypes.ROLE_UPDATE_START, RoleUpdate);
}

export function* onRoleUpdateResponseReset() {
	yield takeLatest(roleActionTypes.ROLE_UPDATE_RESPONSE_RESET_START, roleUpdateResponseReset);
}
/** *********************** END ROLE UPDATE LIST ************************ */

/** *********************** SATRT ROLE DELETE LIST ************************ */
export function* RoleDelete({ payload }: any) {
	const key = 'Delete_Role_List';
	try {
		const { roleID, companyID } = payload;

		const jsonData = {
			RoleID: roleID,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(deleteRole, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteRoleFailure(responseData.data));
		} else {
			yield put(deleteRoleSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteRoleFailure(error));
	}
}

export function* roleDeleteResponseReset() {
	yield put(deleteRoleResponseChanged());
}

export function* onDeleteRole() {
	yield takeLatest(roleActionTypes.ROLE_DELETE_START, RoleDelete);
}

export function* onRoleDeleteResponseReset() {
	yield takeLatest(roleActionTypes.ROLE_DELETE_RESPONSE_RESET_START, roleDeleteResponseReset);
}
/** *********************** END ROLE DELETE LIST ************************ */

export function* roleSaga() {
	yield all([
		call(onFetchListRoles),
		call(onRoleListFetchResponseReset),
		call(onUpdateRole),
		call(onRoleUpdateResponseReset),
		call(onRoleDetailsFetch),
		call(onRoleDetailsFetchResponseReset),
		call(onAddRole),
		call(onRoleAddResponseReset),
		call(onDeleteRole),
		call(onRoleDeleteResponseReset),
	]);
}
