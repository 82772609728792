// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/default-param-last */
import ProductActionTypes from './product.type';
import { docodeResponse } from '../../_common/common.utils';

const INITIAL_STATE = {
	productListFetchResponse: null,
	productListResponseArr: [],
	productAddResponse: null,
	productUpdateResponse: null,
	fetchProductDetailsResponse: null,
	fetchProductDetailsResponseArr: [],
	productDeleteResponse: null,
	productDeleteListResponseArr: [],
};

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case ProductActionTypes.PRODUCT_LIST_FETCH_SUCCESS:
			return {
				...state,
				productListFetchResponse: action.payload,
				productListResponseArr: docodeResponse(action.payload, 'Fetch_Product_List'),
			};

		case ProductActionTypes.PRODUCT_LIST_FETCH_FAILURE:
			return { ...state, productListFetchResponse: action.payload };

		case ProductActionTypes.PRODUCT_LIST_FETCH_RESPONSE_CHANGED:
			return { ...state, productListFetchResponse: null };

		/** ********************** ADD PRODUCT START **************** */

		case ProductActionTypes.PRODUCT_ADD_SUCCESS:
			return {
				...state,
				productAddResponse: action.payload,
			};

		case ProductActionTypes.PRODUCT_ADD_FAILURE:
			return { ...state, productAddResponse: action.payload };

		case ProductActionTypes.PRODUCT_ADD_RESPONSE_CHANGED:
			return { ...state, productAddResponse: null };

		/** ********************** ADD PRODUCT END **************** */

		/** ********************** UPDATE PRODUCT START **************** */

		case ProductActionTypes.PRODUCT_UPDATE_SUCCESS:
			return {
				...state,
				productUpdateResponse: action.payload,
			};

		case ProductActionTypes.PRODUCT_UPDATE_FAILURE:
			return { ...state, productUpdateResponse: action.payload };

		case ProductActionTypes.PRODUCT_UPDATE_RESPONSE_CHANGED:
			return { ...state, productUpdateResponse: null };

		/** ********************** UPDATE PRODUCT END **************** */

		/** ********************** PARTICULAR PRODUCT START **************** */

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS:
			return {
				...state,
				fetchProductDetailsResponse: action.payload,
				fetchProductDetailsResponseArr: docodeResponse(
					action.payload,
					'Fetch_Product_Details',
				),
			};

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_FAILURE:
			return { ...state, fetchProductDetailsResponse: action.payload };

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchProductDetailsResponse: null };

		/** ********************** PARTICULAR PRODUCT END **************** */

		/** ********************** DELETE PRODUCT START **************** */

		case ProductActionTypes.PRODUCT_DELETE_SUCCESS:
			return {
				...state,
				productDeleteResponse: action.payload,
			};

		case ProductActionTypes.PRODUCT_DELETE_FAILURE:
			return { ...state, productDeleteResponse: action.payload };

		case ProductActionTypes.PRODUCT_DELETE_RESPONSE_CHANGED:
			return { ...state, productDeleteResponse: null };

		/** ********************** DELETE PRODUCT END **************** */

		default:
			return state;
	}
};
