// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

/** *************** STAFF FETCH LIST ***************** */

export const fetchStaffList = (fetchData: JSON) => {
	const data = {
		UserListFetchToken: fetchData,
	};

	const url = `${API_URL}FetchUserList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** STAFF FETCH LIST ***************** */

export const addStaffDetails = (fetchData: JSON) => {
	const data = {
		CreateUserDetailsToken: fetchData,
	};

	const url = `${API_URL}CreateUserDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** STAFF FETCH LIST ***************** */

export const getStaffDetails = (fetchData: JSON) => {
	const data = {
		UserDetailsFetchToken: fetchData,
	};

	const url = `${API_URL}FetchUserDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** STAFF UPDATE LIST ***************** */

export const updateStaffDetails = (fetchData: JSON) => {
	const data = {
		UpdateUserDetailsToken: fetchData,
	};

	const url = `${API_URL}UpdateUserDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** STAFF UPDATE LIST ***************** */

export const deleteStaffDetails = (fetchData: JSON) => {
	const data = {
		DeleteUserDetailsToken: fetchData,
	};

	const url = `${API_URL}DeleteUserDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
