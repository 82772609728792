// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-unresolved */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import BranchActionTypes from './branch.type';
import {
	fetchBranchList,
	addBranch,
	branchFetchDetails,
	updateBranch,
	deleteBranch,
} from './branch.services';
import {
	branchListFetchSuccess,
	branchListFetchFailure,
	branchListFetchResponseChanged,
	branchAddSuccess,
	branchAddFailure,
	branchAddResponseChanged,
	fetchBranchDetailsSuccess,
	fetchBranchDetailsFailure,
	fetchBranchDetailsResponseChanged,
	branchUpdateSuccess,
	branchUpdateFailure,
	branchUpdateResponseChanged,
	branchDeleteSuccess,
	branchDeleteFailure,
	branchDeleteResponseChanged,
} from './branch.action';
import { encodeJson } from '../../_common/enode-decode';

/** *********************** SATRT BRANCH FETCH LIST ************************ */
export function* branchListFetch({ payload }: any) {
	const key = 'Fetch_Branch_List';
	try {
		const { limit, companyID } = payload;

		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchBranchList, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(branchListFetchFailure(responseData.data));
		} else {
			yield put(branchListFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(branchListFetchFailure(error));
	}
}

export function* branchFetchResponseReset() {
	yield put(branchListFetchResponseChanged());
}

export function* onFetchListBranch() {
	yield takeLatest(BranchActionTypes.BRANCH_LIST_FETCH_START, branchListFetch);
}

export function* onBranchListFetchResponseReset() {
	yield takeLatest(BranchActionTypes.BRANCH_LIST_RESPONSE_RESET_START, branchFetchResponseReset);
}
/** *********************** END BRANCH FETCH LIST ************************ */

/** *********************** SATRT MEMBER FETCH LIST ************************ */
export function* branchAdd({ payload }: any) {
	const key = 'Create_Branch_Details';
	try {
		const {
			branchID,
			companyID,
			branchName,
			branchCountryID,
			branchStateID,
			branchDistrictID,
			branchCityID,
			branchAddress,
			branchPincode,
		} = payload;

		const jsonData = {
			BranchID: branchID,
			CompanyID: companyID,
			BranchName: branchName,
			BranchContry: branchCountryID,
			BranchState: branchStateID,
			BranchDistrict: branchDistrictID,
			BranchCity: branchCityID,
			BranchAddress: branchAddress,
			BranchPincode: branchPincode,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(addBranch, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(branchAddFailure(responseData.data));
		} else {
			yield put(branchAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(branchAddFailure(error));
	}
}

export function* branchAddResponseReset() {
	yield put(branchAddResponseChanged());
}

export function* onAddBranch() {
	yield takeLatest(BranchActionTypes.BRANCH_ADD_START, branchAdd);
}

export function* onBranchAddResponseReset() {
	yield takeLatest(BranchActionTypes.BRANCH_ADD_RESPONSE_RESET_START, branchAddResponseReset);
}
/** *********************** END MEMBER FETCH LIST ************************ */

/** *********************** SATRT FETCH LIST ************************ */
export function* branchDetailsFetch({ payload }: any) {
	const key = 'Fetch_Branch_Details';
	try {
		const { companyID, branchID } = payload;

		const jsonData = {
			CompanyID: companyID,
			BranchID: branchID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(branchFetchDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBranchDetailsFailure(responseData.data));
		} else {
			yield put(fetchBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchDetailsFailure(error));
	}
}

export function* branchDetailsFetchResponseReset() {
	yield put(fetchBranchDetailsResponseChanged());
}

export function* onBranchDetailsFetch() {
	yield takeLatest(BranchActionTypes.FETCH_BRANCH_DETAILS_START, branchDetailsFetch);
}

export function* onBranchDetailsFetchResponseReset() {
	yield takeLatest(
		BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_RESET_START,
		branchDetailsFetchResponseReset,
	);
}
/** *********************** END FETCH LIST ************************ */

/** *********************** SATRT BRANCH UPDATE LIST ************************ */
export function* branchUpdate({ payload }: any) {
	const key = 'Update_Branch_Details';
	try {
		const {
			branchID,
			companyID,
			branchName,
			branchCountryID,
			branchStateID,
			branchDistrictID,
			branchCityID,
			branchAddress,
			branchPincode,
		} = payload;

		const jsonData = {
			BranchID: branchID,
			CompanyID: companyID,
			BranchName: branchName,
			BranchContry: branchCountryID,
			BranchState: branchStateID,
			BranchDistrict: branchDistrictID,
			BranchCity: branchCityID,
			BranchAddress: branchAddress,
			BranchPincode: branchPincode,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateBranch, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(branchUpdateFailure(responseData.data));
		} else {
			yield put(branchUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(branchUpdateFailure(error));
	}
}

export function* branchUpdateResponseReset() {
	yield put(branchUpdateResponseChanged());
}

export function* onUpdateBranch() {
	yield takeLatest(BranchActionTypes.BRANCH_UPDATE_START, branchUpdate);
}

export function* onBranchUpdateResponseReset() {
	yield takeLatest(
		BranchActionTypes.BRANCH_UPDATE_RESPONSE_RESET_START,
		branchUpdateResponseReset,
	);
}
/** *********************** END BRANCH UPDATE LIST ************************ */

/** *********************** SATRT BRANCH DELETE LIST ************************ */
export function* branchDelete({ payload }: any) {
	const key = 'Delete_Branch_Details';
	try {
		const { branchID } = payload;

		const jsonData = {
			BranchID: branchID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(deleteBranch, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(branchDeleteFailure(responseData.data));
		} else {
			yield put(branchDeleteSuccess(responseData.data));
		}
	} catch (error) {
		yield put(branchDeleteFailure(error));
	}
}

export function* branchDeleteResponseReset() {
	yield put(branchDeleteResponseChanged());
}

export function* onDeleteBranch() {
	yield takeLatest(BranchActionTypes.BRANCH_DELETE_START, branchDelete);
}

export function* onBranchDeleteResponseReset() {
	yield takeLatest(
		BranchActionTypes.BRANCH_DELETE_RESPONSE_RESET_START,
		branchDeleteResponseReset,
	);
}
/** *********************** END BRANCH DELETE LIST ************************ */

export function* branchSaga() {
	yield all([
		call(onFetchListBranch),
		call(onBranchListFetchResponseReset),
		call(onAddBranch),
		call(onBranchAddResponseReset),
		call(onBranchDetailsFetch),
		call(onBranchDetailsFetchResponseReset),
		call(onUpdateBranch),
		call(onBranchUpdateResponseReset),
		call(onDeleteBranch),
		call(onBranchDeleteResponseReset),
	]);
}
