/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import loginActionTypes from './login.types';

export const userSignInStart = (userCredentials: any) => ({
	type: loginActionTypes.USER_SIGN_IN_START,
	payload: userCredentials,
});

export const userSignInSuccess = (user: any) => ({
	type: loginActionTypes.USER_SIGN_IN_SUCCESS,
	payload: user,
});

export const userSignInFailure = (error: any) => ({
	type: loginActionTypes.USER_SIGN_IN_FAILURE,
	payload: error,
});

export const userSignInResponseResetStart = () => ({
	type: loginActionTypes.USER_SIGN_IN_RESPONSE_CHANGED,
});

export const userSignInResponseChanged = () => ({
	type: loginActionTypes.USER_SIGN_IN_RESPONSE_RESET,
});

export const userSignInLogOutStart = () => ({
	type: loginActionTypes.USER_SIGN_OUT_START,
});

export const userSignInLogOut = () => ({
	type: loginActionTypes.USER_SIGN_OUT,
});
