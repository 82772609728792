// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/default-param-last */
import MemberApproveActionTypes from './member-approve.types';
import { docodeResponse } from '../../_common/common.utils';

const INITIAL_STATE = {
	memberApprovalListResponse: null,
	memberApprovalListResponseArr: [],
	memberApprovalDetailsResponse: null,
	memberApprovalDetailsResponseArr: [],
	isAadharVerfied: 0,
	isAadharReject: 0,
	isAadharRemark: null,
	isVoterIDVerfied: 0,
	isVoterIDReject: 0,
	isVoterIDRemark: null,
	isPanCardVerfied: 0,
	isPanCardReject: 0,
	isPanCardRemark: null,
	isDrivingLicenceVerfied: 0,
	isDrivingLicenceReject: 0,
	isDrivingLicenceRemark: null,
	isBankDetailsVerfied: 0,
	isBankDetailsReject: 0,
	isBankDetailsRemark: null,
	isNomineeDetailsVerfied: 0,
	isNomineeDetailsReject: 0,
	isNomineeDetailsRemark: null,
	approveMemberUpdateResponse: null,
};

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case MemberApproveActionTypes.MEMBER_APPROVAL_LIST_FETCH_SUCCESS:
			return {
				...state,
				memberApprovalListResponse: action.payload,
				memberApprovalListResponseArr: docodeResponse(
					action.payload,
					'Fetch_Approval_Members_List',
				),
			};

		case MemberApproveActionTypes.MEMBER_APPROVAL_LIST_FETCH_FAILURE:
			return { ...state, memberApprovalListResponse: action.payload };

		case MemberApproveActionTypes.MEMBER_APPROVAL_LIST_FETCH_RESPONSE_CHANGED:
			return { ...state, memberApprovalListResponse: null };

		/** ***************** START FETCH APPROVAL MEMBER DETAILS  ************************ */

		case MemberApproveActionTypes.FETCH_APPROVAL_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				memberApprovalDetailsResponse: action.payload,
				memberApprovalDetailsResponseArr: docodeResponse(
					action.payload,
					'Fetch_Approval_Members_Details',
				),
			};

		case MemberApproveActionTypes.FETCH_APPROVAL_MEMBER_DETAILS_FAILURE:
			return { ...state, memberApprovalDetailsResponse: action.payload };

		case MemberApproveActionTypes.FETCH_APPROVAL_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, memberApprovalDetailsResponse: null };

		/** ***************** FETCH APPROVAL MEMBER DETAILS END ************************ */

		/** ***************** START AADHAR VERIFY  ************************ */

		case MemberApproveActionTypes.IS_AADHAR_VERIFIED:
			return { ...state, isAadharVerfied: 1, isAadharReject: 0 };

		/** ***************** AADHAR VERIFY END ************************ */

		/** ***************** START AADHAR REMARK  ************************ */

		case MemberApproveActionTypes.IS_AADHAR_REMARKED:
			return { ...state, isAadharRemark: action.payload };

		/** ***************** AADHAR REMARK END ************************ */

		/** ***************** START AADHAR REJECT  ************************ */

		case MemberApproveActionTypes.IS_AADHAR_REJECTED:
			return { ...state, isAadharReject: 1, isAadharVerfied: 0 };

		/** ***************** AADHAR REJECT END ************************ */

		/** ***************** START VOTERID VERIFY  ************************ */

		case MemberApproveActionTypes.IS_VOTERID_VERIFIED:
			return { ...state, isVoterIDVerfied: 1, isVoterIDReject: 0 };

		/** ***************** VOTERID VERIFY END ************************ */

		/** ***************** START VOTERID REMARK  ************************ */

		case MemberApproveActionTypes.IS_VOTERID_REMARKED:
			return { ...state, isVoterIDRemark: action.payload };

		/** ***************** VOTERID REMARK END ************************ */

		/** ***************** START VOTERID REJECT  ************************ */

		case MemberApproveActionTypes.IS_VOTERID_REJECTED:
			return { ...state, isVoterIDReject: 1, isVoterIDVerfied: 0 };

		/** ***************** VOTERID REJECT END ************************ */

		/** ***************** START PANCARD VERIFY  ************************ */

		case MemberApproveActionTypes.IS_PANCARD_VERIFIED:
			return { ...state, isPanCardVerfied: 1, isPanCardReject: 0 };

		/** ***************** PANCARD VERIFY END ************************ */

		/** ***************** START PANCARD REMARK  ************************ */

		case MemberApproveActionTypes.IS_PANCARD_REMARKED:
			return { ...state, isPanCardRemark: action.payload };

		/** ***************** PANCARD REMARK END ************************ */

		/** ***************** START PANCARD REJECT  ************************ */

		case MemberApproveActionTypes.IS_PANCARD_REJECTED:
			return { ...state, isPanCardReject: 1, isPanCardVerfied: 0 };

		/** ***************** PANCARD REMARK END ************************ */

		/** ***************** START DRIVING LICENCE VERIFY  ************************ */

		case MemberApproveActionTypes.IS_DRIVING_LICENCE_VERIFIED:
			return { ...state, isDrivingLicenceVerfied: 1, isDrivingLicenceReject: 0 };

		/** *****************  DRIVING LICENCE VERIFY END ************************ */

		/** ***************** START  DRIVING LICENCE REMARK  ************************ */

		case MemberApproveActionTypes.IS_DRIVING_LICENCE_REMARKED:
			return { ...state, isDrivingLicenceRemark: action.payload };

		/** *****************  DRIVING LICENCE REMARK END ************************ */

		/** ***************** START  DRIVING LICENCE REJECT  ************************ */

		case MemberApproveActionTypes.IS_DRIVING_LICENCE_REJECTED:
			return { ...state, isDrivingLicenceReject: 1, isDrivingLicenceVerfied: 0 };

		/** *****************  DRIVING LICENCE REMARK END ************************ */

		/** ***************** START BANK DETAILS VERIFY  ************************ */

		case MemberApproveActionTypes.IS_BANK_DETAILS_VERIFIED:
			return { ...state, isBankDetailsVerfied: 1, isBankDetailsReject: 0 };

		/** ***************** BANK DETAILS VERIFY END ************************ */

		/** ***************** START  BANK DETAILS REMARK  ************************ */

		case MemberApproveActionTypes.IS_BANK_DETAILS_REMARKED:
			return { ...state, isBankDetailsRemark: action.payload };

		/** *****************  BANK DETAILS REMARK END ************************ */

		/** ***************** START  BANK DETAILS REJECT  ************************ */

		case MemberApproveActionTypes.IS_BANK_DETAILS_REJECTED:
			return { ...state, isBankDetailsReject: 1, isBankDetailsVerfied: 0 };

		/** *****************  BANK DETAILS REJECT END ************************ */

		/** ***************** START NOMINEE DETAILS VERIFY  ************************ */

		case MemberApproveActionTypes.IS_NOMINEE_DETAILS_VERIFIED:
			return { ...state, isNomineeDetailsVerfied: 1, isNomineeDetailsReject: 0 };

		/** ***************** NOMINEE DETAILS VERIFY END ************************ */

		/** ***************** START  NOMINEE DETAILS REMARK  ************************ */

		case MemberApproveActionTypes.IS_NOMINEE_DETAILS_REMARKED:
			return { ...state, isNomineeDetailsRemark: action.payload };

		/** *****************  NOMINEE DETAILS REMARK END ************************ */

		/** ***************** START  NOMINEE DETAILS REMARK  ************************ */
		case MemberApproveActionTypes.IS_NOMINEE_DETAILS_REJECTED:
			return { ...state, isNomineeDetailsReject: 1, isNomineeDetailsVerfied: 0 };

		/** *****************  NOMINEE DETAILS REMARK END ************************ */

		/** ***************** START  RESET VERIFICATION ************************ */
		case MemberApproveActionTypes.IS_NOMINEE_DETAILS_REJECTED:
			return { ...state, isAadharVerfied: 0, isAadharReject: 0, isAadharRemark: null };

		/** *****************  NOMINEE RESET VERIFICATION ************************ */

		case MemberApproveActionTypes.APPROVE_MEMBER_UPDETE_SUCCESS:
			return {
				...state,
				approveMemberUpdateResponse: action.payload,
			};

		case MemberApproveActionTypes.APPROVE_MEMBER_UPDETE_FAILURE:
			return { ...state, approveMemberUpdateResponse: action.payload };

		case MemberApproveActionTypes.APPROVE_MEMBER_UPDETE_RESPONSE_CHANGED:
			return { ...state, approveMemberUpdateResponse: null };

		/** *******************  STAFF FETCH LIST END ******************* */

		default:
			return state;
	}
};
