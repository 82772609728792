import LoanDisbursementTypes from './loan-disbursement.type';

/** ******************  FETCH LOAN DISBUSEMENT LIST START      *************************** */
export const fetchLoanDisbursementListStart = (loanDisbursementList: any) => ({
	type: LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_LIST_START,
	payload: loanDisbursementList,
});

export const fetchLoanDisbursementListSuccess = (success: any) => ({
	type: LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_LIST_SUCCESS,
	payload: success,
});

export const fetchLoanDisbursementListFailure = (failure: any) => ({
	type: LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_LIST_FAILURE,
	payload: failure,
});

export const fetchLoanDisbursementListResponseResetStart = () => ({
	type: LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_LIST_RESPONSE_RESET_START,
});

export const fetchLoanDisbursementListResponseChanged = () => ({
	type: LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_LIST_RESPONSE_CHANGED,
});

/** ******************  FETCH LOAN DISBUSEMENT LIST END      *************************** */

/** ******************  FETCH LOAN DISBUSEMENT DETAILS START      *************************** */
export const fetchLoanDisbursementDetailsStart = (loanDisbursementList: any) => ({
	type: LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_DETAILS_START,
	payload: loanDisbursementList,
});

export const fetchLoanDisbursementDetailsSuccess = (success: any) => ({
	type: LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_DETAILS_SUCCESS,
	payload: success,
});

export const fetchLoanDisbursementDetailsFailure = (failure: any) => ({
	type: LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_DETAILS_FAILURE,
	payload: failure,
});

export const fetchLoanDisbursementDetailsResponseResetStart = () => ({
	type: LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_DETAILS_RESPONSE_RESET_START,
});

export const fetchLoanDisbursementDetailsResponseChanged = () => ({
	type: LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_DETAILS_RESPONSE_CHANGED,
});

/** ******************  FETCH LOAN DISBUSEMENT DETAILS END  *************************** */

/** ******************  FETCH LOAN PROPOSAL DETAILS START      *************************** */
export const disbursementLoanUpdateStart = (disbursementLoanList: any) => ({
	type: LoanDisbursementTypes.DISBURSEMENT_LOAN_UPDATE_START,
	payload: disbursementLoanList,
});

export const disbursementLoanUpdateSuccess = (success: any) => ({
	type: LoanDisbursementTypes.DISBURSEMENT_LOAN_UPDATE_SUCCESS,
	payload: success,
});

export const disbursementLoanUpdateFailure = (failure: any) => ({
	type: LoanDisbursementTypes.DISBURSEMENT_LOAN_UPDATE_FAILURE,
	payload: failure,
});

export const disbursementLoanUpdateResponseResetStart = () => ({
	type: LoanDisbursementTypes.DISBURSEMENT_LOAN_UPDATE_RESPONSE_RESET_START,
});

export const disbursementLoanUpdateResponseChanged = () => ({
	type: LoanDisbursementTypes.DISBURSEMENT_LOAN_UPDATE_RESPONSE_CHANGED,
});

/** ******************  FETCH LOAN PROPOSAL DETAILS END  *************************** */

/** ******************  VEHICLE ADD START      *************************** */
export const vehicleAddStart = (vehicleAdd: any) => ({
	type: LoanDisbursementTypes.VEHICLE_ADD_START,
	payload: vehicleAdd,
});

export const vehicleAddSuccess = (success: any) => ({
	type: LoanDisbursementTypes.VEHICLE_ADD_SUCCESS,
	payload: success,
});

export const vehicleAddFailure = (failure: any) => ({
	type: LoanDisbursementTypes.VEHICLE_ADD_FAILURE,
	payload: failure,
});

export const vehicleAddResponseResetStart = () => ({
	type: LoanDisbursementTypes.VEHICLE_ADD_RESPONSE_RESET_START,
});

export const vehicleAddResponseChanged = () => ({
	type: LoanDisbursementTypes.VEHICLE_ADD_RESPONSE_CHANGED,
});

/** ******************  VEHICLE ADD END  *************************** */
