import HighMarkCheckTypes from './highmark.type';

/** ******************  FETCH HIGHMARK CHECK LIST START      *************************** */
export const fetchHighMarkCheckListStart = (highMarkList: any) => ({
	type: HighMarkCheckTypes.FETCH_HIGHMARK_CHACK_LIST_START,
	payload: highMarkList,
});

export const fetchHighMarkCheckListSuccess = (success: any) => ({
	type: HighMarkCheckTypes.FETCH_HIGHMARK_CHACK_LIST_SUCCESS,
	payload: success,
});

export const fetchHighMarkCheckListFailure = (failure: any) => ({
	type: HighMarkCheckTypes.FETCH_HIGHMARK_CHACK_LIST_FAILURE,
	payload: failure,
});

export const fetchHighMarkCheckListResponseResetStart = () => ({
	type: HighMarkCheckTypes.FETCH_HIGHMARK_CHACK_LIST_RESPONSE_RESET_START,
});

export const fetchHighMarkCheckListResponseChanged = () => ({
	type: HighMarkCheckTypes.FETCH_HIGHMARK_CHACK_LIST_RESPONSE_CHANGED,
});

/** ******************  FETCH HIGHMARK CHECK LIST END      *************************** */

/** ******************  VEHICLE ADD START      *************************** */
export const highMarkCheckAddStart = (highMarkAdd: any) => ({
	type: HighMarkCheckTypes.HIGHMARK_CHACK_ADD_START,
	payload: highMarkAdd,
});

export const highMarkCheckAddSuccess = (success: any) => ({
	type: HighMarkCheckTypes.HIGHMARK_CHACK_ADD_SUCCESS,
	payload: success,
});

export const highMarkCheckAddFailure = (failure: any) => ({
	type: HighMarkCheckTypes.HIGHMARK_CHACK_ADD_FAILURE,
	payload: failure,
});

export const highMarkCheckAddResponseResetStart = () => ({
	type: HighMarkCheckTypes.HIGHMARK_CHACK_ADD_RESPONSE_RESET_START,
});

export const highMarkCheckAddResponseChanged = () => ({
	type: HighMarkCheckTypes.HIGHMARK_CHACK_ADD_RESPONSE_CHANGED,
});

/** ******************  VEHICLE ADD END  *************************** */
