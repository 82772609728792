/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const fetchProductList = (fetchData: JSON) => {
	const data = {
		FetchProductListToken: fetchData,
	};

	const url = `${API_URL}FetchProductList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
/** ************************** ADD PRODUCT START ********************** */

export const addProduct = (fetchData: JSON) => {
	const data = {
		ProductDetailsToken: fetchData,
	};

	const url = `${API_URL}CreateProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** ************************** UPDATE PRODUCT START ********************** */

export const updateProduct = (fetchData: JSON) => {
	const data = {
		UpdateProductDetailsToken: fetchData,
	};

	const url = `${API_URL}UpdateProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** ************************** PARTICULAR PRODUCT START ********************** */

export const getproductDetails = (fetchData: JSON) => {
	const data = {
		ProductDetailsFetchToken: fetchData,
	};

	const url = `${API_URL}FetchProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** ************************** DELETE PRODUCT START ********************** */

export const deleteProduct = (fetchData: JSON) => {
	const data = {
		DeleteProductDetailsToken: fetchData,
	};

	const url = `${API_URL}DeleteProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
