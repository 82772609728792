import MemberApproveActionTypes from './member-approve.types';

/** ***************** START FETCH APPROVAL MEMBER LIST  ************************ */
export const memberApprovalListFetchStart = (approveList: any) => ({
	type: MemberApproveActionTypes.MEMBER_APPROVAL_LIST_FETCH_START,
	payload: approveList,
});

export const memberApprovalListFetchSuccess = (success: any) => ({
	type: MemberApproveActionTypes.MEMBER_APPROVAL_LIST_FETCH_SUCCESS,
	payload: success,
});

export const memberApprovalListFetchFailure = (failure: any) => ({
	type: MemberApproveActionTypes.MEMBER_APPROVAL_LIST_FETCH_FAILURE,
	payload: failure,
});

export const memberApprovalListFetchResponseResetStart = () => ({
	type: MemberApproveActionTypes.MEMBER_APPROVAL_LIST_FETCH_RESPONSE_RESET_START,
});

export const memberApprovalListFetchResponseChanged = () => ({
	type: MemberApproveActionTypes.MEMBER_APPROVAL_LIST_FETCH_RESPONSE_CHANGED,
});
/** ***************** END FETCH APPROVAL MEMBER LIST  ************************ */

/** ***************** START FETCH APPROVAL MEMBER DETAILS  ************************ */
export const fetchApprovalMemberDetailsStart = (memberDetails: any) => ({
	type: MemberApproveActionTypes.FETCH_APPROVAL_MEMBER_DETAILS_START,
	payload: memberDetails,
});

export const fetchApprovalMemberDetailsSuccess = (success: any) => ({
	type: MemberApproveActionTypes.FETCH_APPROVAL_MEMBER_DETAILS_SUCCESS,
	payload: success,
});

export const fetchApprovalMemberDetailsFailure = (failure: any) => ({
	type: MemberApproveActionTypes.FETCH_APPROVAL_MEMBER_DETAILS_FAILURE,
	payload: failure,
});

export const fetchApprovalMemberDetailsResponseResetStart = () => ({
	type: MemberApproveActionTypes.FETCH_APPROVAL_MEMBER_DETAILS_RESPONSE_RESET_START,
});

export const fetchApprovalMemberDetailsResponseChanged = () => ({
	type: MemberApproveActionTypes.FETCH_APPROVAL_MEMBER_DETAILS_RESPONSE_CHANGED,
});
/** ***************** END FETCH APPROVAL MEMBER DETAILS  ************************ */

/** ***************** START AADHAR VERIFY  ************************ */

export const isAadharVerified = () => ({
	type: MemberApproveActionTypes.IS_AADHAR_VERIFIED,
});
/** ***************** END AADHAR VERIFY  ************************ */

/** ***************** START AADHAR REMARK  ************************ */

export const isAadharRemarked = (aadharRmark: any) => ({
	type: MemberApproveActionTypes.IS_AADHAR_REMARKED,
	payload: aadharRmark,
});
/** ***************** END AADHAR REMARK  ************************ */

/** ***************** START AADHAR REJECT  ************************ */

export const isAadharRejected = () => ({
	type: MemberApproveActionTypes.IS_AADHAR_REJECTED,
});
/** ***************** END AADHAR REJECT  ************************ */

/** ***************** START VOTERID VERIFY  ************************ */

export const isVoterIDVerified = () => ({
	type: MemberApproveActionTypes.IS_VOTERID_VERIFIED,
});
/** ***************** END VOTERID VERIFY  ************************ */

/** ***************** START VOTERID REMARK  ************************ */

export const isVoterIDRemarked = (voterRmark: any) => ({
	type: MemberApproveActionTypes.IS_VOTERID_REMARKED,
	payload: voterRmark,
});
/** ***************** END VOTERID REMARK  ************************ */

/** ***************** START VOTERID REJECT  ************************ */

export const isVoterIDRejected = () => ({
	type: MemberApproveActionTypes.IS_VOTERID_REJECTED,
});
/** ***************** END VOTERID REJECT  ************************ */

/** ***************** START PANCARD VERIFY  ************************ */

export const isPanCardVerified = () => ({
	type: MemberApproveActionTypes.IS_PANCARD_VERIFIED,
});
/** ***************** END PANCARD VERIFY  ************************ */

/** ***************** START PANCARD REMARK  ************************ */

export const isPanCardRemarked = (panRmark: any) => ({
	type: MemberApproveActionTypes.IS_PANCARD_REMARKED,
	payload: panRmark,
});
/** ***************** END PANCARD REMARK  ************************ */

/** ***************** START PANCARD REJECT  ************************ */

export const isPanCardRejected = () => ({
	type: MemberApproveActionTypes.IS_PANCARD_REJECTED,
});
/** ***************** END PANCARD REJECT  ************************ */

/** ***************** START DRIVING LICENCE VERIFY  ************************ */

export const isDrivingLicenceVerified = () => ({
	type: MemberApproveActionTypes.IS_DRIVING_LICENCE_VERIFIED,
});
/** ***************** END DRIVING LICENCE VERIFY  ************************ */

/** ***************** START DRIVING LICENCE REMARK  ************************ */

export const isDrivingLicenceRemarked = (aadharRmark: any) => ({
	type: MemberApproveActionTypes.IS_DRIVING_LICENCE_REMARKED,
	payload: aadharRmark,
});
/** ***************** END DRIVING LICENCE REMARK  ************************ */

/** ***************** START DRIVING LICENCE REJECT  ************************ */

export const isDrivingLicenceRejected = () => ({
	type: MemberApproveActionTypes.IS_DRIVING_LICENCE_REJECTED,
});
/** ***************** END DRIVING LICENCE REMARK  ************************ */

/** ***************** START BANK DETAILS VERIFY  ************************ */

export const isBankDetailsVerified = () => ({
	type: MemberApproveActionTypes.IS_BANK_DETAILS_VERIFIED,
});
/** ***************** END BANK DETAILS VERIFY  ************************ */

/** ***************** START BANK DETAILS REMARK  ************************ */

export const isBankDetailsRemarked = (bankRmark: any) => ({
	type: MemberApproveActionTypes.IS_BANK_DETAILS_REMARKED,
	payload: bankRmark,
});
/** ***************** END BANK DETAILS REMARK  ************************ */

/** ***************** START BANK DETAILS REJECT  ************************ */

export const isBankDetailsRejected = () => ({
	type: MemberApproveActionTypes.IS_BANK_DETAILS_REJECTED,
});
/** ***************** END BANK DETAILS REJECT  ************************ */

/** ***************** START NOMINEE DETAILS VERIFY  ************************ */

export const isNomineeDetailsVerified = () => ({
	type: MemberApproveActionTypes.IS_NOMINEE_DETAILS_VERIFIED,
});
/** ***************** END NOMINEE DETAILS VERIFY  ************************ */

/** ***************** START NOMINEE DETAILS REMARK  ************************ */

export const isNomineeDetailsRemarked = (bankRmark: any) => ({
	type: MemberApproveActionTypes.IS_NOMINEE_DETAILS_REMARKED,
	payload: bankRmark,
});
/** ***************** END NOMINEE DETAILS REMARK  ************************ */

/** ***************** START NOMINEE DETAILS REJECT  ************************ */
export const isNomineeDetailsRejected = () => ({
	type: MemberApproveActionTypes.IS_NOMINEE_DETAILS_REJECTED,
});
/** ***************** END NOMINEE DETAILS REJECT  ************************ */

/** ***************** START NOMINEE DETAILS REJECT  ************************ */
export const isResetVerificationStart = () => ({
	type: MemberApproveActionTypes.IS_RESET_VERIFICATION_DETAILS,
});
/** ***************** END NOMINEE DETAILS REJECT  ************************ */

/** ******************  STAFF LIST START      *************************** */

export const approveMemberUpdatehStart = (ApproveMemberList: any) => ({
	type: MemberApproveActionTypes.APPROVE_MEMBER_UPDETE_START,
	payload: ApproveMemberList,
});

export const approveMemberUpdateSuccess = (success: any) => ({
	type: MemberApproveActionTypes.APPROVE_MEMBER_UPDETE_SUCCESS,
	payload: success,
});

export const approveMemberUpdateFailure = (failure: any) => ({
	type: MemberApproveActionTypes.APPROVE_MEMBER_UPDETE_FAILURE,
	payload: failure,
});

export const approveMemberUpdateResponseResetStart = () => ({
	type: MemberApproveActionTypes.APPROVE_MEMBER_UPDETE_RESET_UPDETE_START,
});

export const approveMemberUpdateResponseChanged = () => ({
	type: MemberApproveActionTypes.APPROVE_MEMBER_UPDETE_RESPONSE_CHANGED,
});

/** ******************  STAFF LIST END      *************************** */
