// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import MemberApproveActionTypes from './member-approve.types';
import {
	memberApprovalListFetchSuccess,
	memberApprovalListFetchFailure,
	memberApprovalListFetchResponseChanged,
	fetchApprovalMemberDetailsSuccess,
	fetchApprovalMemberDetailsFailure,
	fetchApprovalMemberDetailsResponseChanged,
	approveMemberUpdateSuccess,
	approveMemberUpdateFailure,
	approveMemberUpdateResponseChanged,
} from './member-approve.action';

import {
	fetchMemberListApproval,
	fetchMemberDetailsApproval,
	updateapproveMember,
} from './member-approve.services';
import { encodeJson } from '../../_common/enode-decode';

/** ***************** START FETCH APPROVAL MEMBER LIST  ************************ */
export function* memberApprovalListFetch({ payload }: any) {
	const key = 'Fetch_Approval_Members_List';
	try {
		const { limit, branchID, companyID, memberID } = payload;

		const jsonData = {
			Limit: limit,
			BranchID: branchID,
			CompanyID: companyID,
			MemberID: memberID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchMemberListApproval, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(memberApprovalListFetchFailure(responseData.data));
		} else {
			yield put(memberApprovalListFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(memberApprovalListFetchFailure(error));
	}
}

export function* memberApprovalListResponseReset() {
	yield put(memberApprovalListFetchResponseChanged());
}

export function* onFetchMemberApprovalList() {
	yield takeLatest(
		MemberApproveActionTypes.MEMBER_APPROVAL_LIST_FETCH_START,
		memberApprovalListFetch,
	);
}

export function* onMemberApprovalListResponseReset() {
	yield takeLatest(
		MemberApproveActionTypes.MEMBER_APPROVAL_LIST_FETCH_RESPONSE_RESET_START,
		memberApprovalListResponseReset,
	);
}
/** ***************** END FETCH APPROVAL MEMBER LIST  ************************ */

/** ***************** START FETCH APPROVAL MEMBER DETAILS  ************************ */
export function* approvalMemberDetailsFetch({ payload }: any) {
	const key = 'Fetch_Approval_Members_Details';
	try {
		const { branchID, companyID, memberID } = payload;

		const jsonData = {
			BranchID: branchID,
			CompanyID: companyID,
			MemberID: memberID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchMemberDetailsApproval, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchApprovalMemberDetailsFailure(responseData.data));
		} else {
			yield put(fetchApprovalMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchApprovalMemberDetailsFailure(error));
	}
}

export function* approvalMemberDetailsResponseReset() {
	yield put(fetchApprovalMemberDetailsResponseChanged());
}

export function* onFetchApprovalMemberDetails() {
	yield takeLatest(
		MemberApproveActionTypes.FETCH_APPROVAL_MEMBER_DETAILS_START,
		approvalMemberDetailsFetch,
	);
}

export function* onFetchApprovalMemberDetailResponseReset() {
	yield takeLatest(
		MemberApproveActionTypes.FETCH_APPROVAL_MEMBER_DETAILS_RESPONSE_RESET_START,
		approvalMemberDetailsResponseReset,
	);
}
/** ***************** END FETCH APPROVAL MEMBER DETAILS  ************************ */

/** *********************** APPROVE MEMBER UPDATE START ************************ */
export function* approveMemberUpdate({ payload }: any) {
	const key = 'Approve_Member';
	try {
		const {
			memberID,
			companyID,
			branchID,
			aadharVerify,
			aadharRemark,
			voterIDVerify,
			voterIdRemark,
			panVerify,
			panRemark,
			drivingLicenceVerify,
			drivingLicenceRemark,
			bankVerify,
			bankRemark,
			nomineeDetailsVerify,
			nomineeDetailsRemark,
			verifiedOn,
			verifiedBY,
			memberStatus,
		} = payload;

		const jsonData = {
			MemberID: memberID,
			CompanyID: companyID,
			BranchID: branchID,
			IsAadharVerify: aadharVerify,
			AadharRejectRemark: aadharRemark,
			IsPanVerify: voterIDVerify,
			PanRejectRemark: voterIdRemark,
			IsVoterIDVerify: panVerify,
			VoterIDRejectRemark: panRemark,
			IsDrivingLicenceVerify: drivingLicenceVerify,
			DrivingLicenceRejectRemark: drivingLicenceRemark,
			IsBankDetailsVerify: bankVerify,
			BankDetailsRejectRemark: bankRemark,
			IsNomineeDetailsVerify: nomineeDetailsVerify,
			NomineeDetailsRejectRemark: nomineeDetailsRemark,
			VerifiedOn: verifiedOn,
			VerifiedBY: verifiedBY,
			MemberStatus: memberStatus,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateapproveMember, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(approveMemberUpdateFailure(responseData.data));
		} else {
			yield put(approveMemberUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(approveMemberUpdateFailure(error));
	}
}

export function* approveMemberUpdateResponseReset() {
	yield put(approveMemberUpdateResponseChanged());
}

export function* onUpdateApproveMember() {
	yield takeLatest(MemberApproveActionTypes.APPROVE_MEMBER_UPDETE_START, approveMemberUpdate);
}

export function* onResponseUpdateApproveMemberReset() {
	yield takeLatest(
		MemberApproveActionTypes.APPROVE_MEMBER_UPDETE_RESET_UPDETE_START,
		approveMemberUpdateResponseReset,
	);
}
/** *********************** APPROVE MEMBER UPDATE END  ************************ */

export function* memberApprovalSaga() {
	yield all([
		call(onFetchMemberApprovalList),
		call(onMemberApprovalListResponseReset),
		call(onFetchApprovalMemberDetails),
		call(onFetchApprovalMemberDetailResponseReset),
		call(onUpdateApproveMember),
		call(onResponseUpdateApproveMemberReset),
	]);
}
