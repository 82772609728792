import { createSelector } from 'reselect';

const selectUser = (state: any) => state.login;

export const selectCurrentUser = createSelector([selectUser], (login) => login.loginResponse);

export const selectCurrentDecodedUser = createSelector(
	[selectUser],
	(login) => login.loginDeocodedResponse,
);

export const getLoginObjDetails = createSelector([selectUser], (login) => {
	const { loginDeocodedResponse, currencySymbols } = login;
	let loginObj: any = {
		userID: '',
		userName: '',
		userPhoto: '',
		companyID: '',
		currency: '',

	};
	if (
		loginDeocodedResponse !== null &&
		loginDeocodedResponse !== undefined &&
		loginDeocodedResponse.length > 0
	) {
		const loginData = loginDeocodedResponse[0];
		loginObj = {
			userID: loginData.UserID,
			userName: loginData.UserName,
			userPhoto: loginData.UserPhoto,
			companyID: loginData.CompanyID,
			currency: currencySymbols,
		};
	}
	return loginObj;
});
