const RoleActionTypes = {
	ROLE_LIST_FETCH_START: 'role_list_fetch_start',
	ROLE_LIST_FETCH_SUCCESS: 'role_list_fetch_success',
	ROLE_LIST_FETCH_FAILURE: 'role_list_fetch_failure',
	ROLE_LIST_RESPONSE_RESET_START: 'role_list_response_reset_start',
	ROLE_LIST_FETCH_RESPONSE_CHANGED: 'role_list_fetch_response_changed',

	FETCH_ROLE_DETAILS_START: 'fetch_role_details_start',
	FETCH_ROLE_DETAILS_SUCCESS: 'fetch_role_details_fetchsuccess',
	FETCH_ROLE_DETAILS_FAILURE: 'particular_role_list_failure',
	FETCH_ROLE_DETAILS_RESPONSE_RESET_START: 'fetch_role_details_response_reset_start',
	FETCH_ROLE_DETAILS_RESPONSE_CHANGED: 'fetch_role_details_response_changed',

	ROLE_ADD_START: 'role_add_start',
	ROLE_ADD_SUCCESS: 'role_add_success',
	ROLE_ADD_FAILURE: 'role_add_failure',
	ROLE_ADD_RESPONSE_RESET_START: 'role_add_response_reset_start',
	ROLE_ADD_RESPONSE_CHANGED: 'role_add_response_changed',

	ROLE_UPDATE_START: 'role_update_start',
	ROLE_UPDATE_SUCCESS: 'role_update_success',
	ROLE_UPDATE_FAILURE: 'role_update_failure',
	ROLE_UPDATE_RESPONSE_RESET_START: 'role_update_response_reset_start',
	ROLE_UPDATE_RESPONSE_CHANGED: 'role_update_response_changed',

	ROLE_DELETE_START: 'role_delete_start',
	ROLE_DELETE_SUCCESS: 'role_delete_success',
	ROLE_DELETE_FAILURE: 'role_delete_failure',
	ROLE_DELETE_RESPONSE_RESET_START: 'role_delete_response_reset_start',
	ROLE_DELETE_RESPONSE_CHANGED: 'role_delete_response_changed',
};
export default RoleActionTypes;
