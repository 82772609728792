const MemberActionTypes = {
	MEMEBR_IMAGE_CHANGE_START: 'member_image_changed_start',
	MEMEBR_NOMINEE_ID_TYPE_CHANGE_START: 'member_nominee_id_type_change_start',

	MEMBER_ADD_START: 'member_add_start',
	MEMBER_ADD_SUCCESS: 'member_add_success',
	MEMBER_ADD_FAILURE: 'member_add_failure',
	MEMBER_ADD_RESET_START: 'membert_add_response_reset_start',
	MEMBER_ADD_RESPONSE_CHANGED: 'member_add_response_changed',

	MEMBER_UPDATE_START: 'member_update_start',
	MEMBER_UPDATE_SUCCESS: 'member_update_success',
	MEMBER_UPDATE_FAILURE: 'member_update_failure',
	MEMBER_UPDATE_RESET_START: 'member_update_response_reset_start',
	MEMBER_UPDATE_RESPONSE_CHANGED: 'member_update_response_changed',

	MEMBER_DELETE_START: 'member_delete_start',
	MEMBER_DELETE_SUCCESS: 'member_delete_success',
	MEMBER_DELETE_FAILURE: 'member_delete_failure',
	MEMBER_DELETE_RESET_START: 'member_delete_response_reset_start',
	MEMBER_DELETE_RESPONSE_CHANGED: 'member_delete_response_changed',

	MEMBER_LIST_FETCH_START: 'member_list_fetch_start',
	MEMBER_LIST_FETCH_SUCCESS: 'member_list_fetch_success',
	MEMBER_LIST_FETCH_FAILURE: 'member_list_fetch_failure',
	MEMBER_LIST_RESPONSE_RESET_START: 'member_list_response_reset_start',
	MEMBER_LIST_FETCH_RESPONSE_CHANGED: 'member_list_fetch_response_changed',

	FETCH_MEMBER_DETAILS_START: 'fetch_member_details_start',
	FETCH_MEMBER_DETAILS_SUCCESS: 'fetch_member_details_success',
	FETCH_MEMBER_DETAILS_FAILURE: 'fetch_member_details_failure',
	FETCH_MEMBER_DETAILS_RESPONSE_RESET_START: 'fetch_member_details_response_reset_start',
	FETCH_MEMBER_DETAILS_RESPONSE_CHANGED: 'fetch_member_details_response_changed',

	AADHAR_FETCH_START: 'aadhar_fetch_start',
	AADHAR_FETCH_SUCCESS: 'aadhar_fetch_success',
	AADHAR_FETCH_FAILURE: 'aadhar_fetch_failure',
	AADHAR_RESPONSE_RESET_START: 'aadhar_response_reset_start',
	AADHAR_FETCH_RESPONSE_CHANGED: 'aadhar_fetch_response_changed',

	AADHAR_UPDATE_START: 'aadhar_update_start',
	AADHAR_UPDATE_SUCCESS: 'aadhar_update_success',
	AADHAR_UPDATE_FAILURE: 'aadhar_update_failure',
	AADHAR_UPDATE_RESPONSE_RESET_START: 'aadhar_response_reset_start',
	AADHAR_UPDATE_RESPONSE_CHANGED: 'aadhar_update_response_changed',

	PAN_FETCH_START: 'pan_fetch_start',
	PAN_FETCH_SUCCESS: 'pan_fetch_success',
	PAN_FETCH_FAILURE: 'pan_fetch_failure',
	PAN_RESPONSE_RESET_START: 'pan_response_reset_start',
	PAN_FETCH_RESPONSE_CHANGED: 'pan_fetch_response_changed',

	PAN_UPDATE_START: 'pan_update_start',
	PAN_UPDATE_SUCCESS: 'pan_update_success',
	PAN_UPDATE_FAILURE: 'pan_update_failure',
	PAN_UPDATE_RESPONSE_RESET_START: 'pan_update_response_reset_start',
	PAN_UPDATE_RESPONSE_CHANGED: 'pan_update_response_changed',

	DRIVINGLICENCE_FETCH_START: 'dl_fetch_start',
	DRIVINGLICENCE_FETCH_SUCCESS: 'dl_fetch_success',
	DRIVINGLICENCE_FETCH_FAILURE: 'dl_fetch_failure',
	DRIVINGLICENCE_RESPONSE_RESET_START: 'dl_response_reset_start',
	DRIVINGLICENCE_FETCH_RESPONSE_CHANGED: 'dl_fetch_response_changed',

	DRIVINGLICENCE_UPDATE_START: 'dl_update_start',
	DRIVINGLICENCE_UPDATE_SUCCESS: 'dl_update_success',
	DRIVINGLICENCE_UPDATE_FAILURE: 'dl_update_failure',
	DRIVINGLICENCE_RESPONSE_UPDATE_RESET_START: 'dl_update_response_reset_start',
	DRIVINGLICENCE_UPDATE_RESPONSE_CHANGED: 'dl_update_response_changed',

	VOTERID_FETCH_START: 'voterid_fetch_start',
	VOTERID_FETCH_SUCCESS: 'voterid_fetch_success',
	VOTERID_FETCH_FAILURE: 'voterid_fetch_failure',
	VOTERID_RESPONSE_RESET_START: 'voterid_fetch_response_reset_start',
	VOTERID_FETCH_RESPONSE_CHANGED: 'voterid_fetch_response_changed',

	VOTERID_UPDATE_START: 'voterid_update_start',
	VOTERID_UPDATE_SUCCESS: 'voterid_update_success',
	VOTERID_UPDATE_FAILURE: 'voterid_update_failure',
	VOTERID_UPDATE_RESPONSE_RESET_START: 'voterid_update_response_reset_start',
	VOTERID_UPDATE_RESPONSE_CHANGED: 'voterid_update_response_changed',

	BANKDETAILS_FETCH_START: 'bankdetails_fetch_start',
	BANKDETAILS_FETCH_SUCCESS: 'bankdetails_fetch_success',
	BANKDETAILS_FETCH_FAILURE: 'bankdetails_fetch_failure',
	BANKDETAILS_RESPONSE_RESET_START: 'bankdetails_fetch_response_reset_start',
	BANKDETAILS_FETCH_RESPONSE_CHANGED: 'bankdetails_fetch_response_changed',

	BANKDETAILS_UPDATE_START: 'bankdetails_update_start',
	BANKDETAILS_UPDATE_SUCCESS: 'bankdetails_update_success',
	BANKDETAILS_UPDATE_FAILURE: 'bankdetails_update_failure',
	BANKDETAILS_UPDATE_RESPONSE_RESET_START: 'bankdetails_update_response_reset_start',
	BANKDETAILS_UPDATE_RESPONSE_CHANGED: 'bankdetails_update_response_changed',

	NOMINEE_DETAILS_FETCH_START: 'nominee_details_fetch_start',
	NOMINEE_DETAILS_FETCH_SUCCESS: 'nominee_details_fetch_success',
	NOMINEE_DETAILS_FETCH_FAILURE: 'nominee_details_fetch_failure',
	NOMINEE_DETAILS_RESPONSE_RESET_START: 'nominee_details_fetch_response_reset_start',
	NOMINEE_DETAILS_FETCH_RESPONSE_CHANGED: 'nominee_details_fetch_response_changed',

	NOMINEE_UPDATE_START: 'nominee_update_start',
	NOMINEE_UPDATE_SUCCESS: 'nominee_update_success',
	NOMINEE_UPDATE_FAILURE: 'nominee_update_failure',
	NOMINEE_UPDATE_RESPONSE_RESET_START: 'nominee_update_response_reset_start',
	NOMINEE_UPDATE_RESPONSE_CHANGED: 'nominee_update_response_changed',

	NOMINEE_ADD_START: 'nominee_add_start',
	NOMINEE_ADD_SUCCESS: 'nominee_add_success',
	NOMINEE_ADD_FAILURE: 'nominee_add_failure',
	NOMINEE_ADD_RESPONSE_RESET_START: 'nominee_add_response_reset_start',
	NOMINEE_ADD_RESPONSE_CHANGED: 'nominee_add_response_changed',

	SELECT_MEMBER_ID_CHANGE_START: 'select_member_id_start_change_start',
};
export default MemberActionTypes;
