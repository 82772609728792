import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import JWT from 'expo-jwt';
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/LogoNew';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import { mastersPageMenu } from '../../../menu';
import { selectCurrentUser } from '../../../redux/login/login.selector';
import { userSignInStart, userSignInResponseResetStart } from '../../../redux/login/login.action';

const Login = () => {
	const dispatch = useDispatch();
	const { setUser } = useContext(AuthContext);
	const getCurrentUserLoginDetails = useSelector(selectCurrentUser);

	const { darkModeStatus } = useDarkMode();
	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	// const usernameCheck = (username: string) => {
	// 	return !!getUserDataWithUsername(username);
	// };

	// const passwordCheck = (username: string, password: string) => {
	// 	return getUserDataWithUsername(username).password === password;
	// };

	useEffect(() => {
		if (getCurrentUserLoginDetails != null) {
			if (getCurrentUserLoginDetails.statusCode === '01') {
				handleOnClick();
				// navigate(`${mastersPageMenu.highMarkPage.path}`);
			} else if (getCurrentUserLoginDetails.statusCode === '100') {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Cancel' size='lg' className='me-1' />
						<span>Mobile Number Not Exist</span>
					</span>,
					'Wrong MobileNumber',
				);
				dispatch(userSignInResponseResetStart());
			}
		}
	}, [dispatch, getCurrentUserLoginDetails, handleOnClick]);

	const formik = useFormik({
		// enableReinitialize: true,
		initialValues: {
			loginPhoneNumber: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginPhoneNumber?: string; loginPassword?: string } = {};

			if (!values.loginPhoneNumber) {
				errors.loginPhoneNumber = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			dispatch(userSignInStart(values));
			// handleOnClick();

			// if (usernameCheck(values.loginPhoneNumber)) {
			// 	if (passwordCheck(values.loginPhoneNumber, values.loginPassword)) {
			// if (setUser) {
			// 	setUser(values.loginPhoneNumber);
			// }

			// 		handleOnClick();
			// 	} else {
			// 		formik.setFieldError('loginPassword', 'Username and password do not match.');
			// 	}
			// }
		},
	});

	function handleContinue() {
		setIsLoading(true);
		setTimeout(() => {
			if (formik.values.loginPhoneNumber === '') {
				formik.setFieldError('loginPhoneNumber', 'No such user found in the system.');
			} else {
				setSignInPassword(true);
			}
			setIsLoading(false);
		}, 1000);
	}

	return (
		<PageWrapper isProtected={false} title='Login' className='bg-dark'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo width={200} />
									</Link>
								</div>
								<>
									<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
									<div className='text-center h4 text-muted mb-5'>
										Sign in to continue
									</div>
								</>

								{/* <Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong> {USERS.JOHN.username}
										</div>
										<div className='col-12'>
											<strong>Password:</strong> {USERS.JOHN.password}
										</div>
									</div>
								</Alert> */}
								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='loginPhoneNumber'
											isFloating
											label='Mobile Number'
											className={classNames({
												'd-none': signInPassword,
											})}>
											<Input
												autoComplete='Phone Number'
												value={formik.values.loginPhoneNumber}
												isTouched={formik.touched.loginPhoneNumber}
												max={10}
												invalidFeedback={formik.errors.loginPhoneNumber}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur('ph')}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
										{signInPassword && (
											<div className='text-center h4 mb-3 fw-bold'>
												Hi, {formik.values.loginPhoneNumber}.
											</div>
										)}
										<FormGroup
											id='loginPassword'
											isFloating
											label='Password'
											className={classNames({
												'd-none': !signInPassword,
											})}>
											<Input
												type='password'
												autoComplete='current-password'
												value={formik.values.loginPassword}
												isTouched={formik.touched.loginPassword}
												invalidFeedback={formik.errors.loginPassword}
												validFeedback='Looks good!'
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										{!signInPassword ? (
											<Button
												color='warning'
												className='w-100 py-3'
												isDisable={!formik.values.loginPhoneNumber}
												onClick={() => {
													handleContinue();
												}}>
												{isLoading && <Spinner isSmall inButton isGrow />}
												Continue
											</Button>
										) : (
											<Button
												color='warning'
												className='w-100 py-3'
												onClick={formik.handleSubmit}>
												Login
											</Button>
										)}
									</div>
								</form>
							</CardBody>
						</Card>
						{/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3 link-dark', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div> */}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
