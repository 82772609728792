// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

/** *************** LOAN DISBURSEMENT FETCH LIST ***************** */
export const fetchListLoanDisbursement = (fetchListData: JSON) => {
	const data = {
		DisbursementListFetchToken: fetchListData,
	};

	const url = `${API_URL}DisbursementFetchList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** LOAN DISBURSEMENT FETCH LIST ***************** */

/** *************** LOAN DISBURSEMENT FETCH DETAILS ***************** */
export const fetchDetailsLoanDisbursement = (fetchListData: JSON) => {
	const data = {
		DisbursementDetailsFetchToken: fetchListData,
	};

	const url = `${API_URL}DisbursementFetchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** LOAN DISBURSEMENT FETCH DETAILS ***************** */

/** *************** LOAN DISBURSEMENT FETCH DETAILS ***************** */
export const disbursementLoanUpdate = (fetchListData: JSON) => {
	const data = {
		DisbursementLoanUpdateToken: fetchListData,
	};

	const url = `${API_URL}DisbursementLoanUpdate`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** LOAN DISBURSEMENT FETCH DETAILS ***************** */

/** *************** VEHILE ADD DETAILS ***************** */
export const addVehile = (fetchListData: JSON) => {
	const data = {
		CreateVehicleDetailsToken: fetchListData,
	};

	const url = `${API_URL}CreateVehicleDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** ***************  VEHILE ADD DETAILS ***************** */
