const LoanDisbursementTypes = {
	FETCH_LOAN_DISBURSEMENT_LIST_START: 'fetch_loan_disbursement_list_start',
	FETCH_LOAN_DISBURSEMENT_LIST_SUCCESS: 'fetch_loan_disbursement_list_success',
	FETCH_LOAN_DISBURSEMENT_LIST_FAILURE: 'fetch_loan_disbursement_list_failure',
	FETCH_LOAN_DISBURSEMENT_LIST_RESPONSE_RESET_START:
		'fetch_loan_proposal_list_response_reset_start',
	FETCH_LOAN_DISBURSEMENT_LIST_RESPONSE_CHANGED: 'fetch_loan_disbursement_list_response_changed',

	FETCH_LOAN_DISBURSEMENT_DETAILS_START: 'fetch_loan_disbursement_details_start',
	FETCH_LOAN_DISBURSEMENT_DETAILS_SUCCESS: 'fetch_loan_disbursement_details_success',
	FETCH_LOAN_DISBURSEMENT_DETAILS_FAILURE: 'fetch_loan_disbursement_details_failure',
	FETCH_LOAN_DISBURSEMENT_DETAILS_RESPONSE_RESET_START:
		'fetch_loan_proposal_details_response_reset_start',
	FETCH_LOAN_DISBURSEMENT_DETAILS_RESPONSE_CHANGED:
		'fetch_loan_disbursement_details_response_changed',

	DISBURSEMENT_LOAN_UPDATE_START: 'disbursement_loan_update_start',
	DISBURSEMENT_LOAN_UPDATE_SUCCESS: 'disbursement_loan_update_success',
	DISBURSEMENT_LOAN_UPDATE_FAILURE: 'disbursement_loan_update_failure',
	DISBURSEMENT_LOAN_UPDATE_RESPONSE_RESET_START: 'disbursement_loan_update_response_reset_start',
	DISBURSEMENT_LOAN_UPDATE_RESPONSE_CHANGED: 'disbursement_loan_update_response_changed',

	VEHICLE_ADD_START: 'vehicle_add_start',
	VEHICLE_ADD_SUCCESS: 'vehicle_add_success',
	VEHICLE_ADD_FAILURE: 'vehicle_add_failure',
	VEHICLE_ADD_RESPONSE_RESET_START: 'vehicle_add_response_reset_start',
	VEHICLE_ADD_RESPONSE_CHANGED: 'vehicle_add_response_changed',
};

export default LoanDisbursementTypes;
