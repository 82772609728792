import axios from 'axios';
import { API_URL } from '../../const';

export const fetchListRole = (fetchData: JSON) => {
	const data = {
		FetchRoleListToken: fetchData,
	};

	const url = `${API_URL}FetchRoleList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getRoleDetails = (fetchData: JSON) => {
	const data = {
		FetchRoleListToken: fetchData,
	};

	const url = `${API_URL}FetchRoleDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addRole = (fetchData: JSON) => {
	const data = {
		CreateRoleToken: fetchData,
	};

	const url = `${API_URL}CreateRole`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateRoles = (fetchData: JSON) => {
	const data = {
		UpdateRoleToken: fetchData,
	};

	const url = `${API_URL}UpdateRoleDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteRole = (fetchData: JSON) => {
	const data = {
		DeleteRoleToken: fetchData,
	};

	const url = `${API_URL}DeleteRoleDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
