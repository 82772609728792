// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
import { all, call } from 'redux-saga/effects';

import { loginSaga } from './login/login.saga';
import { generalSaga } from './general/general.saga';

/** *************** START MASTERS ******************** */
import { roleSaga } from './masters/role/role.saga';
import { highMarkSaga } from './masters/highmark/highmark.saga';
import { branchSaga } from './masters/branch/branch.saga';
import { loanProposalSaga } from './operations/loan-proposal/loan-proposal.saga';
import { memberSaga } from './masters/member/member.saga';
import { memberApprovalSaga } from './operations/member-approve/member-approve.saga';
import { productSaga } from './masters/product/product.saga';
import { staffSaga } from './masters/staff/staff.saga';
import { loanDisbursementSaga } from './operations/loan-disbursement/loan-disbursement.saga';

export default function* rootSaga() {
	yield all([
		call(loginSaga),
		call(generalSaga),
		call(roleSaga),
		call(highMarkSaga),
		call(memberSaga),
		call(memberApprovalSaga),
		call(productSaga),
		call(branchSaga),
		call(loanProposalSaga),
		call(staffSaga),
		call(loanDisbursementSaga),
	]);
}
