// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

/** *************** BRANCH FETCH DETAILS ***************** */
export const fetchBranchList = (fetchData: JSON) => {
	const data = {
		BranchListFetchToken: fetchData,
	};

	const url = `${API_URL}FetchBranchList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** BRANCH FETCH DETAILS ***************** */

/** *************** BRANCH ADD DETAILS ***************** */
export const addBranch = (fetchData: JSON) => {
	const data = {
		BranchDetailsToken: fetchData,
	};

	const url = `${API_URL}CreateBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** BRANCH ADD DETAILS ***************** */

/** *************** BRANCH FETCH DETAILS ***************** */
export const branchFetchDetails = (fetchData: JSON) => {
	const data = {
		BranchDetailsFetchToken: fetchData,
	};

	const url = `${API_URL}FetchBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** BRANCH FETCH DETAILS ***************** */

/** *************** BRANCH UPDATE DETAILS ***************** */
export const updateBranch = (fetchData: JSON) => {
	const data = {
		UpdateBranchDetailsToken: fetchData,
	};

	const url = `${API_URL}UpdateBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** BRANCH UPDATE DETAILS ***************** */

/** *************** BRANCH UPDATE DETAILS ***************** */
export const deleteBranch = (fetchData: JSON) => {
	const data = {
		DeleteBranchDetailsToken: fetchData,
	};

	const url = `${API_URL}DeleteBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** BRANCH UPDATE DETAILS ***************** */
