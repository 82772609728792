// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import ProductActionTypes from './product.type';
import {
	fetchProductList,
	addProduct,
	updateProduct,
	getproductDetails,
	deleteProduct,
} from './product.services';

import {
	productListFetchSuccess,
	productListFetchFailure,
	productListFetchResponseChanged,
	productAddSuccess,
	productAddFailure,
	productAddResponseChanged,
	productUpdateSuccess,
	productUpdateFailure,
	productUpdateResponseChanged,
	fetchProductDetailsSuccess,
	fetchProductDetailsFailure,
	fetchProductDetailsResponseChanged,
	productListDeleteSuccess,
	productDeleteFailure,
	productDeleteResponseChanged,
} from './product.action';

import { encodeJson } from '../../_common/enode-decode';

/** ******************* FETCH PRODUCT START ***************** */

export function* productListFetch({ payload }: any) {
	const key = 'Fetch_Product_List';
	try {
		const { limit, companyID } = payload;

		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchProductList, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(productListFetchFailure(responseData.data));
		} else {
			yield put(productListFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(productListFetchFailure(error));
	}
}

export function* productFetchResponseReset() {
	yield put(productListFetchResponseChanged());
}

export function* onFetchListproduct() {
	yield takeLatest(ProductActionTypes.PRODUCT_LIST_FETCH_START, productListFetch);
}

export function* onproductListFetchResponseReset() {
	yield takeLatest(
		ProductActionTypes.PRODUCT_LIST_RESPONSE_RESET_START,
		productFetchResponseReset,
	);
}
/** ******************* FETCH PRODUCT END ***************** */

/** ******************* ADD PRODUCT START ***************** */

export function* productAdd({ payload }: any) {
	const key = 'Create_Product_Details';
	try {
		const {
			productID,
			productName,
			productDescription,
			productAmount,
			interestPercentage,
			noOfInstallment,
			installmentType,
			principalAmount,
			interestAmount,
			noOfDueInterval,
			noOfYears,
			processingFee,
			processingFeePercentage,
			documentCharges,
			isPenality,
			penaltyAmount,
			penaltyPercentage,
			companyID,
			userID,
		} = payload;

		const jsonData = {
			ProductID: productID,
			ProductName: productName,
			ProductDescription: productDescription,
			ProductAmount: productAmount,
			InterestPercentage: interestPercentage,
			NoOfInstallment: noOfInstallment,
			InstallmentType: installmentType,
			PrincipalAmount: principalAmount,
			InterestAmount: interestAmount,
			NoOfDueInterval: noOfDueInterval,
			NoOfYear: noOfYears,
			ProcessingFee: processingFee,
			ProcessingFeePercentage: processingFeePercentage,
			DocumentCharges: documentCharges,
			Ispenalty: isPenality,
			PenaltyAmount: penaltyAmount,
			PenaltyPercentage: penaltyPercentage,
			CompanyID: companyID,
			CreatedBy: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(addProduct, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(productAddFailure(responseData.data));
		} else {
			yield put(productAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(productAddFailure(error));
	}
}

export function* productAddResponseReset() {
	yield put(productAddResponseChanged());
}

export function* onAddProduct() {
	yield takeLatest(ProductActionTypes.PRODUCT_ADD_START, productAdd);
}

export function* onProductAddResponseReset() {
	yield takeLatest(ProductActionTypes.PRODUCT_ADD_RESPONSE_RESET_START, productAddResponseReset);
}
/** ******************* ADD PRODUCT END ***************** */

/** ******************* ADD PRODUCT START ***************** */

export function* productUpdate({ payload }: any) {
	const key = 'Update_Product_Details';
	try {
		const {
			productID,
			productName,
			productDescription,
			productAmount,
			interestPercentage,
			noOfInstallment,
			installmentType,
			principalAmount,
			interestAmount,
			noOfDueInterval,
			noOfYears,
			processingFee,
			processingFeePercentage,
			documentCharges,
			isPenality,
			penaltyAmount,
			penaltyPercentage,
			companyID,
			userID,
		} = payload;

		const jsonData = {
			ProductID: productID,
			ProductName: productName,
			ProductDescription: productDescription,
			ProductAmount: productAmount,
			InterestPercentage: interestPercentage,
			NoOfInstallment: noOfInstallment,
			InstallmentType: installmentType,
			PrincipalAmount: principalAmount,
			InterestAmount: interestAmount,
			NoOfDueInterval: noOfDueInterval,
			NoOfYear: noOfYears,
			ProcessingFee: processingFee,
			ProcessingFeePercentage: processingFeePercentage,
			DocumentCharges: documentCharges,
			Ispenalty: isPenality,
			PenaltyAmount: penaltyAmount,
			PenaltyPercentage: penaltyPercentage,
			CompanyID: companyID,
			CreatedBy: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateProduct, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(productUpdateFailure(responseData.data));
		} else {
			yield put(productUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(productUpdateFailure(error));
	}
}

export function* productUpdateResponseReset() {
	yield put(productUpdateResponseChanged());
}

export function* onUpdateproduct() {
	yield takeLatest(ProductActionTypes.PRODUCT_UPDATE_START, productUpdate);
}

export function* onProductUpdateResponseReset() {
	yield takeLatest(
		ProductActionTypes.PRODUCT_UPDATE_RESPONSE_RESET_START,
		productUpdateResponseReset,
	);
}
/** ******************* ADD PRODUCT END ***************** */

/** ******************* PARTICULAR PRODUCT START ***************** */

export function* fetchProductDetails({ payload }: any) {
	const key = 'Fetch_Product_Details';
	try {
		const { companyID, productID } = payload;

		const jsonData = {
			CompanyID: companyID,
			ProductID: productID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(getproductDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchProductDetailsFailure(responseData.data));
		} else {
			yield put(fetchProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchProductDetailsFailure(error));
	}
}

export function* productFetchDetailsResponseReset() {
	yield put(fetchProductDetailsResponseChanged());
}

export function* onProductFetchDetails() {
	yield takeLatest(ProductActionTypes.FETCH_PRODUCT_DETAILS_START, fetchProductDetails);
}

export function* onProductFetchDetailsResponseReset() {
	yield takeLatest(
		ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_RESET_START,
		productFetchDetailsResponseReset,
	);
}
/** ******************* PARTICULAR PRODUCT END ***************** */

/** ******************* PARTICULAR PRODUCT START ***************** */

export function* productDelete({ payload }: any) {
	const key = 'Delete_Product_Details';
	try {
		const { productID } = payload;

		const jsonData = {
			ProductID: productID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(deleteProduct, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(productDeleteFailure(responseData.data));
		} else {
			yield put(productListDeleteSuccess(responseData.data));
		}
	} catch (error) {
		yield put(productDeleteFailure(error));
	}
}

export function* deleteProductResponseReset() {
	yield put(productDeleteResponseChanged());
}

export function* onDeleteProduct() {
	yield takeLatest(ProductActionTypes.PRODUCT_DELETE_START, productDelete);
}

export function* onDeleteproductResponseReset() {
	yield takeLatest(
		ProductActionTypes.PRODUCT_DELETE_RESPONSE_RESET_START,
		deleteProductResponseReset,
	);
}
/** ******************* PARTICULAR PRODUCT END ***************** */

export function* productSaga() {
	yield all([
		call(onFetchListproduct),
		call(onproductListFetchResponseReset),
		call(onAddProduct),
		call(onProductAddResponseReset),
		call(onUpdateproduct),
		call(onProductUpdateResponseReset),
		call(onProductFetchDetails),
		call(onProductFetchDetailsResponseReset),
		call(onDeleteProduct),
		call(onDeleteproductResponseReset),
	]);
}
