// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable consistent-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/default-param-last */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-anonymous-default-export */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import BranchActionTypes from './branch.type';
import { docodeResponse } from '../../_common/common.utils';

const INITIAL_STATE = {
	branchListFetchResponse: null,
	branchListResponseArr: [],
	branchAddResponse: null,
	branchFetchDetailsResponse: null,
	branchFetchDetailsResponseArr: [],
	branchUpdateResponse: null,
	branchDeleteResponse: null,
};

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case BranchActionTypes.BRANCH_LIST_FETCH_SUCCESS:
			return {
				...state,
				branchListFetchResponse: action.payload,
				branchListResponseArr: docodeResponse(action.payload, 'Fetch_Branch_List'),
			};

		case BranchActionTypes.BRANCH_LIST_FETCH_FAILURE:
			return { ...state, branchListFetchResponse: action.payload };

		case BranchActionTypes.BRANCH_LIST_FETCH_RESPONSE_CHANGED:
			return { ...state, branchListFetchResponse: null };

		/** *******************  BRANCH FETCH LIST ******************* */

		/** *******************  BRANCH ADD LIST ******************* */

		case BranchActionTypes.BRANCH_ADD_SUCCESS:
			return {
				...state,
				branchAddResponse: action.payload,
			};

		case BranchActionTypes.BRANCH_ADD_FAILURE:
			return { ...state, branchAddResponse: action.payload };

		case BranchActionTypes.BRANCH_ADD_RESPONSE_CHANGED:
			return { ...state, branchAddResponse: null };
		/** *******************  BRANCH ADD LIST ******************* */

		/** *******************  BRANCH  FETCH LIST ******************* */

		case BranchActionTypes.FETCH_BRANCH_DETAILS_SUCCESS:
			return {
				...state,
				branchFetchDetailsResponse: action.payload,
				branchFetchDetailsResponseArr: docodeResponse(
					action.payload,
					'Fetch_Branch_Details',
				),
			};

		case BranchActionTypes.FETCH_BRANCH_DETAILS_FAILURE:
			return { ...state, branchFetchDetailsResponse: action.payload };

		case BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_CHANGED:
			return { ...state, branchFetchDetailsResponse: null };
		/** *******************  BRANCH  FETCH LIST ******************* */

		/** *******************  BRANCH UPDATE LIST ******************* */

		case BranchActionTypes.BRANCH_UPDATE_SUCCESS:
			return {
				...state,
				branchUpdateResponse: action.payload,
			};

		case BranchActionTypes.BRANCH_UPDATE_FAILURE:
			return { ...state, branchUpdateResponse: action.payload };

		case BranchActionTypes.BRANCH_UPDATE_RESPONSE_CHANGED:
			return { ...state, branchUpdateResponse: null };

		/** *******************  BRANCH UPDATE  LIST ******************* */

		/** *******************  BRANCH UPDATE LIST ******************* */

		case BranchActionTypes.BRANCH_DELETE_SUCCESS:
			return {
				...state,
				branchDeleteResponse: action.payload,
			};

		case BranchActionTypes.BRANCH_DELETE_FAILURE:
			return { ...state, branchDeleteResponse: action.payload };

		case BranchActionTypes.BRANCH_DELETE_RESPONSE_CHANGED:
			return { ...state, branchDeleteResponse: null };

		/** *******************  BRANCH UPDATE  LIST ******************* */

		default:
			return state;
	}
};
