const ProductActionTypes = {
	PRODUCT_LIST_FETCH_START: 'product_list_fetch_start',
	PRODUCT_LIST_FETCH_SUCCESS: 'product_list_fetch_success',
	PRODUCT_LIST_FETCH_FAILURE: 'product_list_fetch_failure',
	PRODUCT_LIST_RESPONSE_RESET_START: 'product_list_response_reset_start',
	PRODUCT_LIST_FETCH_RESPONSE_CHANGED: 'product_list_fetch_response_changed',

	PRODUCT_ADD_START: 'product_add_start',
	PRODUCT_ADD_SUCCESS: 'product_add_success',
	PRODUCT_ADD_FAILURE: 'product_add_failure',
	PRODUCT_ADD_RESPONSE_RESET_START: 'product_add_response_reset_start',
	PRODUCT_ADD_RESPONSE_CHANGED: 'product_add_response_changed',

	PRODUCT_UPDATE_START: 'product_update_start',
	PRODUCT_UPDATE_SUCCESS: 'product_update_success',
	PRODUCT_UPDATE_FAILURE: 'product_update_failure',
	PRODUCT_UPDATE_RESPONSE_RESET_START: 'product_update_response_reset_start',
	PRODUCT_UPDATE_RESPONSE_CHANGED: 'product_update_response_changed',

	FETCH_PRODUCT_DETAILS_START: 'fetch_product_details_start',
	FETCH_PRODUCT_DETAILS_SUCCESS: 'fetch_product_details_success',
	FETCH_PRODUCT_DETAILS_FAILURE: 'fetch_product_details_failure',
	FETCH_PRODUCT_DETAILS_RESPONSE_RESET_START: 'fetch_product_details_response_reset_start',
	FETCH_PRODUCT_DETAILS_RESPONSE_CHANGED: 'fetch_product_details_response_changed',

	PRODUCT_DELETE_START: 'product_delete_start',
	PRODUCT_DELETE_SUCCESS: 'product_delete_success',
	PRODUCT_DELETE_FAILURE: 'product_delete_failure',
	PRODUCT_DELETE_RESPONSE_RESET_START: 'product_delete_response_reset_start',
	PRODUCT_DELETE_RESPONSE_CHANGED: 'product_delete_response_changed',
};
export default ProductActionTypes;
