// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/default-param-last */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-anonymous-default-export */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */

import LoanProposalActionTypes from './loan-proposal.types';
import { docodeResponse } from '../../_common/common.utils';

const INITIAL_STATE = {
	loanProposalListResponse: null,
	loanProposalListArr: [],
	addLoanProposalResponse: null,
	loanProposalDetailsResponse: null,
	loanProposalDetailsArr: [],
	loanProposalUpdateResponse: null,
	loanProposalDeleteResponse: null,
};

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_LIST_SUCCESS:
			return {
				...state,
				loanProposalListResponse: action.payload,
				loanProposalListArr: docodeResponse(action.payload, 'Fetch_Loan_Proposal_List'),
			};

		case LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_LIST_FAILURE:
			return { ...state, loanProposalListResponse: action.payload };

		case LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED:
			return { ...state, loanProposalListResponse: null };

		/** *******************  LOAN PROPOSAL FETCH LIST ******************* */

		/** *******************  LOAN PROPOSAL FETCH LIST ******************* */
		case LoanProposalActionTypes.ADD_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				addLoanProposalResponse: action.payload,
			};

		case LoanProposalActionTypes.ADD_LOAN_PROPOSAL_FAILURE:
			return { ...state, addLoanProposalResponse: action.payload };

		case LoanProposalActionTypes.ADD_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, addLoanProposalResponse: null };

		/** *******************  LOAN PROPOSAL FETCH LIST ******************* */

		/** *******************  LOAN PROPOSAL FETCH LIST ******************* */
		case LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_DETAILS_SUCCESS:
			return {
				...state,
				loanProposalDetailsResponse: action.payload,
				loanProposalDetailsArr: docodeResponse(
					action.payload,
					'Fetch_Loan_Proposal_Details',
				),
			};

		case LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_DETAILS_FAILURE:
			return { ...state, loanProposalDetailsResponse: action.payload };

		case LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_DETAILS_RESPONSE_CHANGED:
			return { ...state, loanProposalDetailsResponse: null };

		/** *******************  LOAN PROPOSAL FETCH LIST ******************* */

		/** ******************* UPDATE LOAN PROPOSAL ******************* */
		case LoanProposalActionTypes.UPDATE_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				loanProposalUpdateResponse: action.payload,
			};

		case LoanProposalActionTypes.UPDATE_LOAN_PROPOSAL_FAILURE:
			return { ...state, loanProposalUpdateResponse: action.payload };

		case LoanProposalActionTypes.UPDATE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, loanProposalUpdateResponse: null };

		/** ******************* UPDATE LOAN PROPOSAL ******************* */

		/** ******************* UPDATE LOAN PROPOSAL ******************* */
		case LoanProposalActionTypes.DELETE_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				loanProposalDeleteResponse: action.payload,
			};

		case LoanProposalActionTypes.DELETE_LOAN_PROPOSAL_FAILURE:
			return { ...state, loanProposalDeleteResponse: action.payload };

		case LoanProposalActionTypes.DELETE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, loanProposalDeleteResponse: null };

		/** ******************* UPDATE LOAN PROPOSAL ******************* */

		default:
			return state;
	}
};
