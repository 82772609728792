import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
	// componentPagesMenu,
	demoPagesMenu,
	hidePages,
	mastersPageMenu,
	// operationPageMenu
} from '../menu';

import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DashboardBookingHeader from '../pages/_layout/_headers/DashboardBookingHeader';
import ProductListHeader from '../pages/_layout/_headers/ProductListHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
	{ path: hidePages.login.path, element: null },
	{ path: hidePages.signUp.path, element: null },
	{ path: hidePages.page404.path, element: null },
	{ path: hidePages.dashboard.path, element: <DashboardHeader /> },
	// { path: operationPageMenu.operationPages.subMenu.memberApprove.path, element: null },
	{
		path: demoPagesMenu.listPages.subMenu.listFluid.path,
		element: <DashboardBookingHeader />,
	},
	{
		path: demoPagesMenu.listPages.subMenu.listBoxed.path,
		element: <ProductListHeader />,
	},
	{
		path: `${hidePages.employeeID.path}/*`,
		element: <DashboardBookingHeader />,
	},
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
