import { takeLatest, put, all, call } from 'redux-saga/effects';

import loginActionTypes from './login.types';
import { userSignIn } from './login.services';
import {
	userSignInSuccess,
	userSignInFailure,
	userSignInResponseChanged,
	userSignInLogOut,
} from './login.action';
import { encodeJson } from '../_common/enode-decode';

type AnyAction = { type: JSON; [key: string]: any };
export function* singInWithPhoneNumber({ payload }: AnyAction) {
	const key: any = 'User_Login_Details';
	try {
		const { loginPhoneNumber, loginPassword } = payload;

		const jsonData: any = {
			UserPhoneNumber: loginPhoneNumber,
			UserPassword: loginPassword,
			UserTokenID: '',
		};
		const loginData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(userSignIn, loginData);
		if (responseData.data.statusCode === '500') {
			yield put(userSignInFailure(responseData.data));
		} else {
			yield put(userSignInSuccess(responseData.data));
		}
	} catch (error) {
		yield put(userSignInFailure(error));
	}
}

export function* makeUserLoginReset() {
	yield put(userSignInResponseChanged());
}

export function* onEmailSignInReset() {
	yield takeLatest(loginActionTypes.USER_SIGN_IN_RESPONSE_RESET, makeUserLoginReset);
}

export function* onEmailSignInStart() {
	yield takeLatest(loginActionTypes.USER_SIGN_IN_START, singInWithPhoneNumber);
}

/** ********* LOGOUT *************** */
export function* makeUserSignInLogout() {
	yield put(userSignInLogOut());
}

export function* userSignInLogout() {
	yield takeLatest(loginActionTypes.USER_SIGN_OUT_START, makeUserSignInLogout);
}

export function* loginSaga() {
	yield all([call(onEmailSignInStart), call(userSignInLogout), call(onEmailSignInReset)]);
}
