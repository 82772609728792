const HighMarkCheckTypes = {
	FETCH_HIGHMARK_CHACK_LIST_START: 'fetch_highmark_chack_list_start',
	FETCH_HIGHMARK_CHACK_LIST_SUCCESS: 'fetch_highmark_chack_list_success',
	FETCH_HIGHMARK_CHACK_LIST_FAILURE: 'fetch_highmark_chack_list_failure',
	FETCH_HIGHMARK_CHACK_LIST_RESPONSE_RESET_START:
		'fetch_highmark_chack_list_response_reset_start',
	FETCH_HIGHMARK_CHACK_LIST_RESPONSE_CHANGED: 'fetch_highmark_chack_list_response_changed',

	HIGHMARK_CHACK_ADD_START: 'highmark_chack_add_start',
	HIGHMARK_CHACK_ADD_SUCCESS: 'highmark_chack_add_success',
	HIGHMARK_CHACK_ADD_FAILURE: 'highmark_chack_add_failure',
	HIGHMARK_CHACK_ADD_RESPONSE_RESET_START: 'highmark_chack_add_response_reset_start',
	HIGHMARK_CHACK_ADD_RESPONSE_CHANGED: 'highmark_chack_add_response_changed',
};

export default HighMarkCheckTypes;
