import JWT from 'expo-jwt';
import { decodeTokenJson } from './enode-decode';

// Decode
export const decodeApiResponse = (jsonData: any, key: string) => {
	const decodedData = decodeTokenJson(jsonData, key);
	return decodedData.data;
};

export const docodeResponse = (response: any, key: any) => {
	const docodeVal = decodeTokenJson(response.response, key);	
	return docodeVal.data;
};
