// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { hidePages, mastersPageMenu, demoPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	// DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	DASHBOARD: lazy(() => import('../pages/masters/highmark/highmark.component')),
};

const MASTERS = {
	HIGHMARK: lazy(() => import('../pages/masters/highmark/highmark.component')),
};


const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation: RouteProps[] = [
	/**
	 * Landing
	 */
	{
		path: hidePages.login.path,
		element: <Login />,
	},
	{
		path: hidePages.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	/** ************************************************** */

	/**
	 * Masters Start
	 */
	{
		path: mastersPageMenu.highMarkPage.path,
		element: <MASTERS.HIGHMARK />,
	},
	/**
	 * Masters End
	 */


	/**
	 * Auth Page
	 */
	{
		path: hidePages.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: hidePages.login.path,
		element: <Login />,
	},
	// {
	// 	path: hidePages.signUp.path,
	// 	element: <Login isSignUp />,
	// },

	/**
	 * END - Auth Page
	 */
	/** ************************************************** */
];

const contents = [...presentation];

export default contents;
