/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const getAllCountriesList = (country: any) => {
	const data = {
		GetCountryListToken: country,
	};
	const url = `${API_URL}fetchCountryList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllStateList = (state: any) => {
	const data = {
		CountryStateListGetToken: state,
	};
	const url = `${API_URL}fetchCountryStateList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllCityList = (city: any) => {
	const data = {
		StateCityListGetToken: city,
	};
	const url = `${API_URL}fetchStateCityList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getDistrictList = (district: any) => {
	const data = {
		DistrictCityListGetToken: district,
	};
	const url = `${API_URL}fetchStateDistrictList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allBranchesFetch = (fetchBranchesData: any) => {
	const data = {
		FetchAllBranchKeyToken: fetchBranchesData,
	};
	const url = `${API_URL}fetchAllBranches`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allBranchStaffFetch = (fetchBranchStaff: any) => {
	const data = {
		GetBranchStaffListToken: fetchBranchStaff,
	};
	const url = `${API_URL}fetchBranchStaffs`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allProductFetch = (fetchAllProduct: any) => {
	const data = {
		AllProductFetchToken: fetchAllProduct,
	};
	const url = `${API_URL}FetchAllProduct`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allMemberFetch = (fetchAllMember: any) => {
	const data = {
		AllMemberFetchToken: fetchAllMember,
	};
	const url = `${API_URL}FetchAllMember`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allRoleFetch = (fetchAllRole: any) => {
	const data = {
		AllRoleFetchToken: fetchAllRole,
	};
	const url = `${API_URL}FetchAllRole`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allBranchFetch = (fetchAllBranch: any) => {
	const data = {
		AllBranchFetchToken: fetchAllBranch,
	};
	const url = `${API_URL}FetchAllBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
