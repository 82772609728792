// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-redeclare */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import StaffActionTypes from './staff.type';

import {
	fetchStaffList,
	addStaffDetails,
	getStaffDetails,
	updateStaffDetails,
	deleteStaffDetails,
} from './staff.services';

import {
	staffListFetchSuccess,
	staffListFetchFailure,
	staffListFetchResponseChanged,
	staffAddSuccess,
	staffAddFailure,
	staffAddResponseChanged,
	fetchStaffDetailsSuccess,
	fetchStaffDetailsFailure,
	fetchStaffDetailsResponseChanged,
	staffUpdateSuccess,
	staffUpdateFailure,
	staffUpdateResponseChanged,
	staffDeleteSuccess,
	staffDeleteFailure,
	staffDeleteResponseChanged,
} from './staff.action';
import { encodeJson } from '../../_common/enode-decode';

/** *********************** SATRT FETCH LIST START ************************ */
export function* staffListFetch({ payload }: any) {
	const key = 'Fetch_User_List';
	try {
		const { limit, companyID } = payload;

		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchStaffList, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(staffListFetchFailure(responseData.data));
		} else {
			yield put(staffListFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(staffListFetchFailure(error));
	}
}

export function* staffFetchResponseReset() {
	yield put(staffListFetchResponseChanged());
}

export function* onFetchListStaff() {
	yield takeLatest(StaffActionTypes.STAFF_LIST_FETCH_START, staffListFetch);
}

export function* onStaffListFetchResponseReset() {
	yield takeLatest(StaffActionTypes.STAFF_LIST_RESPONSE_RESET_START, staffFetchResponseReset);
}
/** *********************** END STAFF FETCH LIST ************************ */

/** *********************** SATRT  ADD LIST ************************ */
export function* staffDetailsAdd({ payload }: any) {
	const key = 'Add_User_Details';
	try {
		const {
			userID,
			userName,
			userPhoneNumber,
			userEmailID,
			userPassword,
			addressLine1,
			addressLine2,
			userPhoto,
			employeeCode,
			emergenceContactNumber,
			userDOB,
			dateOfJoining,
			sex,
			userCity,
			userState,
			lastLoginOn,
			roleID,
			roleName,
			branchID,
			branchName,
			companyID,
		} = payload;

		const jsonData = {
			UserID: userID,
			UserName: userName,
			UserPhoneNumber: userPhoneNumber,
			UserEmailID: userEmailID,
			UserPassword: userPassword,
			AddressLine1: addressLine1,
			AddressLine2: addressLine2,
			UserPhoto: userPhoto,
			EmployeeCode: employeeCode,
			EmergenceContactNumber: emergenceContactNumber,
			UserDOB: userDOB,
			DateOfJoining: dateOfJoining,
			Sex: sex,
			UserCity: userCity,
			UserState: userState,
			LastLoginOn: lastLoginOn,
			CompanyID: companyID,
			BranchID: branchID,
			BranchName: branchName,
			RoleID: roleID,
			RoleName: roleName,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(addStaffDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(staffAddFailure(responseData.data));
		} else {
			yield put(staffAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(staffAddFailure(error));
	}
}

export function* staffAddResponseReset() {
	yield put(staffAddResponseChanged());
}

export function* onAddListStaff() {
	yield takeLatest(StaffActionTypes.STAFF_ADD_START, staffDetailsAdd);
}

export function* onStaffAddResponseReset() {
	yield takeLatest(StaffActionTypes.STAFF_ADD_RESET_START, staffAddResponseReset);
}
/** *********************** END STAFF ADD  ************************ */

/** *********************** SATRT FETCH LIST START ************************ */
export function* staffParticularDetailsFetch({ payload }: any) {
	const key = 'Fetch_User_Details';
	try {
		const { userID, companyID } = payload;

		const jsonData = {
			UserID: userID,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(getStaffDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchStaffDetailsFailure(responseData.data));
		} else {
			yield put(fetchStaffDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchStaffDetailsFailure(error));
	}
}

export function* fetchStafDetailsResponseReset() {
	yield put(fetchStaffDetailsResponseChanged());
}

export function* onFetchrStaffDetails() {
	yield takeLatest(StaffActionTypes.FETCH_STAFF_DETAILS_START, staffParticularDetailsFetch);
}

export function* onFetchrStaffDetailsResponseReset() {
	yield takeLatest(
		StaffActionTypes.FETCH_STAFF_DETAILS_RESET_START,
		fetchStafDetailsResponseReset,
	);
}
/** *********************** END STAFF FETCH LIST ************************ */

/** *********************** SATRT UPDATE LIST ************************ */
export function* staffDetailsUpdate({ payload }: any) {
	const key = 'Update_User_Details';
	try {
		const {
			userID,
			userName,
			userPhoneNumber,
			userEmailID,
			userPassword,
			addressLine1,
			addressLine2,
			userPhoto,
			employeeCode,
			emergenceContactNumber,
			userDOB,
			dateOfJoining,
			sex,
			userCity,
			userState,
			lastLoginOn,
			roleID,
			roleName,
			branchID,
			branchName,
			companyID,
		} = payload;

		const jsonData = {
			UserID: userID,
			UserName: userName,
			UserPhoneNumber: userPhoneNumber,
			UserEmailID: userEmailID,
			UserPassword: userPassword,
			AddressLine1: addressLine1,
			AddressLine2: addressLine2,
			UserPhoto: userPhoto,
			EmployeeCode: employeeCode,
			EmergenceContactNumber: emergenceContactNumber,
			UserDOB: userDOB,
			DateOfJoining: dateOfJoining,
			Sex: sex,
			UserCity: userCity,
			UserState: userState,
			LastLoginOn: lastLoginOn,
			CompanyID: companyID,
			BranchID: branchID,
			BranchName: branchName,
			RoleID: roleID,
			RoleName: roleName,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateStaffDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(staffUpdateFailure(responseData.data));
		} else {
			yield put(staffUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(staffUpdateFailure(error));
	}
}

export function* staffUpdateResponseReset() {
	yield put(staffUpdateResponseChanged());
}

export function* onUpdateListStaff() {
	yield takeLatest(StaffActionTypes.STAFF_UPDAE_START, staffDetailsUpdate);
}

export function* onStaffUpdateResponseReset() {
	yield takeLatest(StaffActionTypes.STAFF_UPDAE_RESET_START, staffUpdateResponseReset);
}
/** *********************** END STAFF UPDATE  ************************ */

/** *******************  STAFF DELETE START ***************** */
export function* staffDetailsDelete({ payload }: any) {
	const key = 'Delete_User_Details';
	try {
		const { userID } = payload;

		const jsonData = {
			UserID: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(deleteStaffDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(staffDeleteFailure(responseData.data));
		} else {
			yield put(staffDeleteSuccess(responseData.data));
		}
	} catch (error) {
		yield put(staffDeleteFailure(error));
	}
}

export function* deleteStaffResponseReset() {
	yield put(staffDeleteResponseChanged());
}

export function* onDeleteDetailsStaff() {
	yield takeLatest(StaffActionTypes.STAFF_DELETE_START, staffDetailsDelete);
}

export function* onDeleteStaffDetailsResponseReset() {
	yield takeLatest(StaffActionTypes.STAFF_DELETE_RESET_START, deleteStaffResponseReset);
}
/** ******************* STAFF DELETE END ***************** */

export function* staffSaga() {
	yield all([
		call(onFetchListStaff),
		call(onStaffListFetchResponseReset),
		call(onAddListStaff),
		call(onStaffAddResponseReset),
		call(onFetchrStaffDetails),
		call(onFetchrStaffDetailsResponseReset),
		call(onUpdateListStaff),
		call(onStaffUpdateResponseReset),
		call(onDeleteDetailsStaff),
		call(onDeleteStaffDetailsResponseReset),
	]);
}
