import BranchActionTypes from './branch.type';

/** ******************  BRANCH LIST START      *************************** */

export const branchListFetchStart = (branchList: any) => ({
	type: BranchActionTypes.BRANCH_LIST_FETCH_START,
	payload: branchList,
});

export const branchListFetchSuccess = (success: any) => ({
	type: BranchActionTypes.BRANCH_LIST_FETCH_SUCCESS,
	payload: success,
});

export const branchListFetchFailure = (failure: any) => ({
	type: BranchActionTypes.BRANCH_LIST_FETCH_FAILURE,
	payload: failure,
});

export const branchListResponseResetStart = () => ({
	type: BranchActionTypes.BRANCH_LIST_RESPONSE_RESET_START,
});

export const branchListFetchResponseChanged = () => ({
	type: BranchActionTypes.BRANCH_LIST_FETCH_RESPONSE_CHANGED,
});

/** ******************  BRANCH LIST END      *************************** */

/** ******************  BRANCH ADD START      *************************** */

export const branchAddStart = (branchList: any) => ({
	type: BranchActionTypes.BRANCH_ADD_START,
	payload: branchList,
});

export const branchAddSuccess = (success: any) => ({
	type: BranchActionTypes.BRANCH_ADD_SUCCESS,
	payload: success,
});

export const branchAddFailure = (failure: any) => ({
	type: BranchActionTypes.BRANCH_ADD_FAILURE,
	payload: failure,
});

export const branchAddResponseResetStart = () => ({
	type: BranchActionTypes.BRANCH_ADD_RESPONSE_RESET_START,
});

export const branchAddResponseChanged = () => ({
	type: BranchActionTypes.BRANCH_ADD_RESPONSE_CHANGED,
});

/** ******************  BRANCH ADD END      *************************** */

/** ******************  BRANCH DETAILS START      *************************** */

export const fetchBranchDetailsStart = (branchList: any) => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_START,
	payload: branchList,
});

export const fetchBranchDetailsSuccess = (success: any) => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_SUCCESS,
	payload: success,
});

export const fetchBranchDetailsFailure = (failure: any) => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_FAILURE,
	payload: failure,
});

export const fetchBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_RESET_START,
});

export const fetchBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_CHANGED,
});

/** ******************  BRANCH DETAILS END      *************************** */

/** ******************  BRANCH UPDATE START      *************************** */

export const branchUpdateStart = (branchList: any) => ({
	type: BranchActionTypes.BRANCH_UPDATE_START,
	payload: branchList,
});

export const branchUpdateSuccess = (success: any) => ({
	type: BranchActionTypes.BRANCH_UPDATE_SUCCESS,
	payload: success,
});

export const branchUpdateFailure = (failure: any) => ({
	type: BranchActionTypes.BRANCH_UPDATE_FAILURE,
	payload: failure,
});

export const branchUpdateResponseResetStart = () => ({
	type: BranchActionTypes.BRANCH_UPDATE_RESPONSE_RESET_START,
});

export const branchUpdateResponseChanged = () => ({
	type: BranchActionTypes.BRANCH_UPDATE_RESPONSE_CHANGED,
});

/** ******************  BRANCH  UPDATE END      *************************** */

/** ******************  BRANCH UPDATE START      *************************** */

export const branchDeleteStart = (branchList: any) => ({
	type: BranchActionTypes.BRANCH_DELETE_START,
	payload: branchList,
});

export const branchDeleteSuccess = (success: any) => ({
	type: BranchActionTypes.BRANCH_DELETE_SUCCESS,
	payload: success,
});

export const branchDeleteFailure = (failure: any) => ({
	type: BranchActionTypes.BRANCH_DELETE_FAILURE,
	payload: failure,
});

export const branchDeleteResponseResetStart = () => ({
	type: BranchActionTypes.BRANCH_DELETE_RESPONSE_RESET_START,
});

export const branchDeleteResponseChanged = () => ({
	type: BranchActionTypes.BRANCH_DELETE_RESPONSE_CHANGED,
});

/** ******************  BRANCH  UPDATE END      *************************** */
