/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/default-param-last */
import loginActionTypes from './login.types';
import { decodeApiResponse } from '../_common/common.utils';

const INITIAL_STATE = {
	loginResponse: null,
	loginDeocodedResponse: null,
	currencySymbols: '',
};

const loginReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case loginActionTypes.USER_SIGN_IN_SUCCESS:
			return {
				...state,
				loginResponse: action.payload,
				loginDeocodedResponse: decodeApiResponse(action.payload.response, 'User_Login_Details'),
			};

		case loginActionTypes.USER_SIGN_IN_FAILURE:
			return {
				...state,
				loginResponse: action.payload,
				loginDeocodedResponse: decodeApiResponse(action.payload.response, 'User_Login_Details'),
			};

		case loginActionTypes.USER_SIGN_IN_RESPONSE_CHANGED:
			return {
				...state,
				loginResponse: null,
			};

		case loginActionTypes.USER_SIGN_OUT:
			return {
				...state,
				loginResponse: null,
				loginDeocodedResponse: null,
			};
		// return { ...state, INITIAL_STATE };

		default:
			return state;
	}
};

export default loginReducer;
