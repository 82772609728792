// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { API_URL } from '../../const';

/** *************** HIGHMARK CHECH FETCH LIST ***************** */
export const fetchListHighMarkCheck = (fetchListData: JSON) => {
	const data = {
		AddAndHighmarkCheckMember: fetchListData,
	};

	const url = `${API_URL}FetchMemberHigmarkcheckList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** HIGHMARK CHECH FETCH LIST ***************** */

/** *************** HIGHMARK CHECH ADD ***************** */
export const addHighMarkCheck = (fetchListData: JSON) => {
	const data = {
		AddAndHighmarkCheckMember: fetchListData,
	};

	const url = `${API_URL}CreateMemberHigmarkcheck`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** HIGHMARK CHECH ADD ***************** */
