// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-anonymous-default-export */
import HighMarkCheckTypes from './highmark.type';
import { docodeResponse } from '../../_common/common.utils';

const INITIAL_STATE = {
	highMarkCheckListResponse: null,
	highMarkCheckListArr: [],
	highMarkCheckAddResponse: null,
};

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case HighMarkCheckTypes.FETCH_HIGHMARK_CHACK_LIST_SUCCESS:
			return {
				...state,
				highMarkCheckListResponse: action.payload,
				highMarkCheckListArr: docodeResponse(action.payload, 'Fetch_Member_Highmark_List'),
			};

		case HighMarkCheckTypes.FETCH_HIGHMARK_CHACK_LIST_FAILURE:
			return { ...state, highMarkCheckListResponse: action.payload };

		case HighMarkCheckTypes.FETCH_HIGHMARK_CHACK_LIST_RESPONSE_CHANGED:
			return { ...state, highMarkCheckListResponse: null };

		/** *******************  LOAN PROPOSAL FETCH LIST ******************* */

		/** *******************  LOAN PROPOSAL ADD START ******************* */

		case HighMarkCheckTypes.HIGHMARK_CHACK_ADD_SUCCESS:
			return {
				...state,
				highMarkCheckAddResponse: action.payload,
			};

		case HighMarkCheckTypes.HIGHMARK_CHACK_ADD_FAILURE:
			return { ...state, highMarkCheckAddResponse: action.payload };

		case HighMarkCheckTypes.HIGHMARK_CHACK_ADD_RESPONSE_CHANGED:
			return { ...state, highMarkCheckAddResponse: null };

		/** *******************  LOAN PROPOSAL ADD END ******************* */

		default:
			return state;
	}
};
