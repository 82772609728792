// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import GeneralActionTypes from './general.types';
import {
	getAllCountriesList,
	getAllStateList,
	getAllCityList,
	getDistrictList,
	allBranchesFetch,
	allBranchStaffFetch,
	allProductFetch,
	allMemberFetch,
	allRoleFetch,
	allBranchFetch,
} from './general.service';
import {
	getCountryListSuccess,
	getCountryListFailure,
	getCountryListResponseChanged,
	getStateListSuccess,
	getStateListFailure,
	getStateListResponseChanged,
	getCityListSuccess,
	getCityListFailure,
	getCityListResponseChanged,
	getDistrictListSuccess,
	getDistrictListFailure,
	getDistrictListResponseChanged,
	fetchAllBranchesSuccess,
	fetchAllBranchesFailure,
	fetchAllBranchesResponseChanged,
	fetchBranchStaffsSuccess,
	fetchBranchStaffsFailure,
	fetchBranchStaffsResponseChanged,
	fetchAllProductSuccess,
	fetchAllProductFailure,
	fetchAllProductResponseChanged,
	fetchAllMemberSuccess,
	fetchAllMemberFailure,
	fetchAllMemberResponseChanged,
	fetchAllRoleSuccess,
	fetchAllRoleFailure,
	fetchAllRoleResponseChanged,
	fetchAllBranchSuccess,
	fetchAllBranchFailure,
	fetchAllBranchResponseChanged,
} from './general.action';
import { encodeJson } from '../_common/enode-decode';

type AnyAction = { type: JSON; [key: string]: any };
/** *********************  START COUNTRY LIST  ******************** */
export function* getCountryList() {
	try {
		const jsonData = {
			CountryID: '',
		};
		const countryData = encodeJson(jsonData, 'GetCountryListKey');
		// @ts-ignore
		const responseData = yield call(getAllCountriesList, countryData);
		if (responseData.data.statusCode === '100') {
			yield put(getCountryListFailure(responseData.data));
		} else {
			yield put(getCountryListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCountryListFailure(error));
	}
}

export function* resetCountryList() {
	yield put(getCountryListResponseChanged());
}

export function* onGetCountryList() {
	yield takeLatest(GeneralActionTypes.GET_COUNTRY_LIST_START, getCountryList);
}

export function* onResetCountryResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_COUNTRY_LIST_RESPONSE_RESET, resetCountryList);
}
/** *********************  END COUNTRY LIST  ******************** */

/** *********************  START STATE LIST  ******************** */
export function* getStateList({ payload }: AnyAction) {
	try {
		const jsonData = {
			CountryID: payload.countryID,
		};
		const stateData = encodeJson(jsonData, 'CountryStateListGetKey');
		// @ts-ignore
		const responseData = yield call(getAllStateList, stateData);
		if (responseData.data.statusCode === '100') {
			yield put(getStateListFailure(responseData.data));
		} else {
			yield put(getStateListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getStateListFailure(error));
	}
}

export function* resetStateList() {
	yield put(getStateListResponseChanged());
}

export function* onGetStateList() {
	yield takeLatest(GeneralActionTypes.GET_STATE_LIST_START, getStateList);
}

export function* onResetStateResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_STATE_LIST_RESPONSE_RESET, resetStateList);
}
/** *********************  END STATE LIST  ******************** */

/** *********************  START CITY LIST  ******************** */
export function* getCityList({ payload }: AnyAction) {
	try {
		const jsonData = {
			StateID: payload.stateID,
		};
		const cityData = encodeJson(jsonData, 'StateCityListGetKey');
		// @ts-ignore
		const responseData = yield call(getAllCityList, cityData);
		if (responseData.data.statusCode === '100') {
			yield put(getCityListFailure(responseData.data));
		} else {
			yield put(getCityListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCityListFailure(error));
	}
}

export function* resetCityList() {
	yield put(getCityListResponseChanged());
}

export function* onGetCityList() {
	yield takeLatest(GeneralActionTypes.GET_CITY_LIST_START, getCityList);
}

export function* onResetCityResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_CITY_LIST_RESPONSE_RESET, resetCityList);
}
/** *********************  END CITY LIST  ******************** */

/** *********************  START DISTRICT LIST  ******************** */
export function* districtListGet({ payload }: AnyAction) {
	try {
		const jsonData = {
			StateID: payload.stateID,
		};
		const districtData = encodeJson(jsonData, 'DistrictCityListGetKey');
		// @ts-ignore
		const responseData = yield call(getDistrictList, districtData);
		if (responseData.data.statusCode === '100') {
			yield put(getDistrictListFailure(responseData.data));
		} else {
			yield put(getDistrictListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDistrictListFailure(error));
	}
}

export function* resetDistrictList() {
	yield put(getDistrictListResponseChanged());
}

export function* onGetDistrictList() {
	yield takeLatest(GeneralActionTypes.GET_DISTRICT_LIST_START, districtListGet);
}

export function* onResetDistrictResponseStart() {
	yield takeLatest(GeneralActionTypes.GET_DISTRICT_LIST_RESPONSE_RESET, resetDistrictList);
}
/** *********************  END DISTRICT LIST  ******************** */

/** *********************  START FETCH ALL BRANCHES LIST  ******************** */
export function* fetchAllBranchesList({ payload }: AnyAction) {
	try {
		const key = 'FetchAllBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(allBranchesFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllBranchesFailure(responseData.data));
		} else {
			yield put(fetchAllBranchesSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllBranchesFailure(error));
	}
}

export function* resetFetchAllBranchesListResponse() {
	yield put(fetchAllBranchesResponseChanged());
}

export function* onFetchAllBranchesListDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_BRANCHES_START, fetchAllBranchesList);
}

export function* onFetchAllBranchesResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_ALL_BRANCHES_RESPONSE_RESET,
		resetFetchAllBranchesListResponse,
	);
}
/** *********************  END FETCH ALL BRANCHES LIST ******************** */

/** *********************  START FETCH STAFF BASED ON BRANCH LIST  ******************** */
export function* fetchBranchStaffsList({ payload }: AnyAction) {
	try {
		const key = 'GetBranchStaffList';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(allBranchStaffFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBranchStaffsFailure(responseData.data));
		} else {
			yield put(fetchBranchStaffsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchStaffsFailure(error));
	}
}

export function* resetFetchBranchStaffsResponse() {
	yield put(fetchBranchStaffsResponseChanged());
}

export function* onFetchBranchStaffsDetails() {
	yield takeLatest(GeneralActionTypes.FETCH_BRANCH_STAFF_START, fetchBranchStaffsList);
}

export function* onFetchBranchStaffsResponseReset() {
	yield takeLatest(
		GeneralActionTypes.FETCH_BRANCH_STAFF_RESPONSE_RESET_START,
		resetFetchBranchStaffsResponse,
	);
}
/** *********************  END STAFF BASED ON BRANCH LIST ******************** */

/** *********************  FETCH ALL PRODUCT LIST  ******************** */
export function* FetchAllProduct({ payload }: AnyAction) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
		};
		const fetchData = encodeJson(jsonData, 'Fetch_All_Product');
		// @ts-ignore
		const responseData = yield call(allProductFetch, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllProductFailure(responseData.data));
		} else {
			yield put(fetchAllProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllProductFailure(error));
	}
}

export function* fetchAllProduct() {
	yield put(fetchAllProductResponseChanged());
}

export function* onGetFetchAllProduct() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_PRODUCT_START, FetchAllProduct);
}

export function* onResetAllProductResponseStart() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_PRODUCT_RESPONSE_RESET_START, fetchAllProduct);
}
/** *********************  FETCH ALL PRODUCT LIST  ******************** */

/** *********************  FETCH ALL MEMBER LIST  ******************** */
export function* FetchAllMember({ payload }: AnyAction) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
		};
		const fetchData = encodeJson(jsonData, 'Fetch_All_Member');
		// @ts-ignore
		const responseData = yield call(allMemberFetch, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllMemberFailure(responseData.data));
		} else {
			yield put(fetchAllMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllMemberFailure(error));
	}
}

export function* fetchAllMember() {
	yield put(fetchAllMemberResponseChanged());
}

export function* onGetFetchAllMember() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_MEMBER_START, FetchAllMember);
}

export function* onResetAllMemberResponseStart() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_MEMBER_RESPONSE_RESET_START, fetchAllMember);
}
/** *********************  FETCH ALL MEMBER LIST  ******************** */

/** *********************  FETCH ALL ROLE LIST  ******************** */
export function* FetchAllRole({ payload }: AnyAction) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
		};
		const fetchData = encodeJson(jsonData, 'Fetch_All_Role');
		// @ts-ignore
		const responseData = yield call(allRoleFetch, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllRoleFailure(responseData.data));
		} else {
			yield put(fetchAllRoleSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllRoleFailure(error));
	}
}

export function* fetchAllRole() {
	yield put(fetchAllRoleResponseChanged());
}

export function* onGetFetchAllRole() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_ROLE_START, FetchAllRole);
}

export function* onResetAllRoleResponseStart() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_ROLE_RESPONSE_RESET_START, fetchAllRole);
}
/** *********************  FETCH ALL ROLE LIST  ******************** */

/** *********************  FETCH ALL BRANCH LIST  ******************** */
export function* FetchAllBranch({ payload }: AnyAction) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
		};
		const fetchData = encodeJson(jsonData, 'Fetch_All_Branch');
		// @ts-ignore
		const responseData = yield call(allBranchFetch, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllBranchFailure(responseData.data));
		} else {
			yield put(fetchAllBranchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllBranchFailure(error));
	}
}

export function* fetchAllBranch() {
	yield put(fetchAllBranchResponseChanged());
}

export function* onGetFetchAllBranch() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_BRANCH_START, FetchAllBranch);
}

export function* onResetAllBranchResponseStart() {
	yield takeLatest(GeneralActionTypes.FETCH_ALL_BRANCH_RESPONSE_RESET_START, fetchAllBranch);
}
/** *********************  FETCH ALL BRANCH LIST  ******************** */

export function* generalSaga() {
	yield all([
		call(onGetCountryList),
		call(onResetCountryResponseStart),
		call(onGetStateList),
		call(onResetStateResponseStart),
		call(onGetCityList),
		call(onResetCityResponseStart),
		call(onGetDistrictList),
		call(onResetDistrictResponseStart),
		call(onFetchAllBranchesListDetails),
		call(onFetchAllBranchesResponseReset),
		call(onFetchBranchStaffsDetails),
		call(onFetchBranchStaffsResponseReset),
		call(onGetFetchAllProduct),
		call(onResetAllProductResponseStart),
		call(onGetFetchAllMember),
		call(onResetAllMemberResponseStart),
		call(onGetFetchAllRole),
		call(onResetAllRoleResponseStart),
		call(onGetFetchAllBranch),
		call(onResetAllBranchResponseStart),
	]);
}
