// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/default-param-last */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-anonymous-default-export */
import LoanDisbursementTypes from './loan-disbursement.type';
import { docodeResponse } from '../../_common/common.utils';

const INITIAL_STATE = {
	loanDisbursementListResponse: null,
	loanDisbursementListArr: [],
	loanDisbursementDetailsResponse: null,
	loanDisbursementDetailsArr: [],
	disbursementLoanUpdateResponse: null,
	vehicleAddResponse: null,
};

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_LIST_SUCCESS:
			return {
				...state,
				loanDisbursementListResponse: action.payload,
				loanDisbursementListArr: docodeResponse(action.payload, 'Disbursement_List_Fetch'),
			};

		case LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_LIST_FAILURE:
			return { ...state, loanDisbursementListResponse: action.payload };

		case LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_LIST_RESPONSE_CHANGED:
			return { ...state, loanDisbursementListResponse: null };

		/** *******************  LOAN PROPOSAL FETCH LIST ******************* */

		case LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_DETAILS_SUCCESS:
			return {
				...state,
				loanDisbursementDetailsResponse: action.payload,
				loanDisbursementDetailsArr: docodeResponse(
					action.payload,
					'Disbursement_Details_Fetch',
				),
			};

		case LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_DETAILS_FAILURE:
			return { ...state, loanDisbursementDetailsResponse: action.payload };

		case LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_DETAILS_RESPONSE_CHANGED:
			return { ...state, loanDisbursementDetailsResponse: null };

		/** *******************  LOAN PROPOSAL FETCH DETAILS ******************* */

		/** *******************  LOAN PROPOSAL FETCH LIST ******************* */

		case LoanDisbursementTypes.DISBURSEMENT_LOAN_UPDATE_SUCCESS:
			return {
				...state,
				disbursementLoanUpdateResponse: action.payload,
			};

		case LoanDisbursementTypes.DISBURSEMENT_LOAN_UPDATE_FAILURE:
			return { ...state, disbursementLoanUpdateResponse: action.payload };

		case LoanDisbursementTypes.DISBURSEMENT_LOAN_UPDATE_RESPONSE_CHANGED:
			return { ...state, disbursementLoanUpdateResponse: null };

		/** *******************  LOAN PROPOSAL FETCH DETAILS ******************* */

		/** *******************  ADD VEHICLE DETAILS ******************* */

		case LoanDisbursementTypes.VEHICLE_ADD_SUCCESS:
			return {
				...state,
				vehicleAddResponse: action.payload,
			};

		case LoanDisbursementTypes.VEHICLE_ADD_FAILURE:
			return { ...state, vehicleAddResponse: action.payload };

		case LoanDisbursementTypes.VEHICLE_ADD_RESPONSE_CHANGED:
			return { ...state, vehicleAddResponse: null };

		/** *******************  LOAN PROPOSAL FETCH DETAILS ******************* */

		default:
			return state;
	}
};
