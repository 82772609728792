import LoanProposalActionTypes from './loan-proposal.types';

/** ******************  FETCH LOAN PROPOSAL LIST START      *************************** */
export const fetchLoanProposalListStart = (loanProposalList: any) => ({
	type: LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_LIST_START,
	payload: loanProposalList,
});

export const fetchLoanProposalListSuccess = (success: any) => ({
	type: LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_LIST_SUCCESS,
	payload: success,
});

export const fetchLoanProposalListFailure = (failure: any) => ({
	type: LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_LIST_FAILURE,
	payload: failure,
});

export const fetchLoanProposalListResponseResetStart = () => ({
	type: LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_LIST_RESPONSE_RESET_START,
});

export const fetchLoanProposalListResponseChanged = () => ({
	type: LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED,
});

/** ******************  FETCH LOAN PROPOSAL LIST END      *************************** */

/** ******************  ADD LOAN PROPOSAL  START      *************************** */

export const addLoanProposalStart = (loanProposalList: any) => ({
	type: LoanProposalActionTypes.ADD_LOAN_PROPOSAL_START,
	payload: loanProposalList,
});

export const addLoanProposalSuccess = (success: any) => ({
	type: LoanProposalActionTypes.ADD_LOAN_PROPOSAL_SUCCESS,
	payload: success,
});

export const addLoanProposalFailure = (failure: any) => ({
	type: LoanProposalActionTypes.ADD_LOAN_PROPOSAL_FAILURE,
	payload: failure,
});

export const addLoanProposalResponseResetStart = () => ({
	type: LoanProposalActionTypes.ADD_LOAN_PROPOSAL_RESPONSE_RESET_START,
});

export const addLoanProposalResponseChanged = () => ({
	type: LoanProposalActionTypes.ADD_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ******************  LOAN PROPOSAL ADD END  *************************** */

/** ******************  FETCH LOAN PROPOSAL DETAILS START      *************************** */

export const fetchLoanProposalDetailStart = (loanProposalList: any) => ({
	type: LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_DETAILS_START,
	payload: loanProposalList,
});

export const fetchLoanProposalDetailSuccess = (success: any) => ({
	type: LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_DETAILS_SUCCESS,
	payload: success,
});

export const fetchLoanProposalDetailFailure = (failure: any) => ({
	type: LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_DETAILS_FAILURE,
	payload: failure,
});

export const fetchLoanProposalDetailResponseResetStart = () => ({
	type: LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_DETAILS_RESPONSE_RESET_START,
});

export const fetchLoanProposalDetailResponseChanged = () => ({
	type: LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_DETAILS_RESPONSE_CHANGED,
});

/** ******************  FETCH LOAN PROPOSAL DETAILS END      *************************** */

/** ******************  UPDATE LOAN PROPOSAL START      *************************** */
export const updateLoanProposalStart = (loanProposalList: any) => ({
	type: LoanProposalActionTypes.UPDATE_LOAN_PROPOSAL_START,
	payload: loanProposalList,
});

export const updateLoanProposalSuccess = (success: any) => ({
	type: LoanProposalActionTypes.UPDATE_LOAN_PROPOSAL_SUCCESS,
	payload: success,
});

export const updateLoanProposalFailure = (failure: any) => ({
	type: LoanProposalActionTypes.UPDATE_LOAN_PROPOSAL_FAILURE,
	payload: failure,
});

export const updateLoanProposalResponseResetStart = () => ({
	type: LoanProposalActionTypes.UPDATE_LOAN_PROPOSAL_RESPONSE_RESET_START,
});

export const updateLoanProposalResponseChanged = () => ({
	type: LoanProposalActionTypes.UPDATE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ******************  UPDATE LOAN PROPOSAL END      *************************** */

/** ******************  DELETE LOAN PROPOSAL START      *************************** */

export const deleteLoanProposalStart = (loanProposalList: any) => ({
	type: LoanProposalActionTypes.DELETE_LOAN_PROPOSAL_START,
	payload: loanProposalList,
});

export const deleteLoanProposalSuccess = (success: any) => ({
	type: LoanProposalActionTypes.DELETE_LOAN_PROPOSAL_SUCCESS,
	payload: success,
});

export const deleteLoanProposalFailure = (failure: any) => ({
	type: LoanProposalActionTypes.DELETE_LOAN_PROPOSAL_FAILURE,
	payload: failure,
});

export const deleteLoanProposalResponseResetStart = () => ({
	type: LoanProposalActionTypes.DELETE_LOAN_PROPOSAL_RESPONSE_RESET_START,
});

export const deleteLoanProposalResponseChanged = () => ({
	type: LoanProposalActionTypes.DELETE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ******************  DELETE LOAN PROPOSAL END      *************************** */
