const StaffActionTypes = {
	STAFF_LIST_FETCH_START: 'staff_list_fetch_start',
	STAFF_LIST_FETCH_SUCCESS: 'staff_list_fetch_success',
	STAFF_LIST_FETCH_FAILURE: 'staff_list_fetch_failure',
	STAFF_LIST_RESPONSE_RESET_START: 'staff_list_response_reset_start',
	STAFF_LIST_FETCH_RESPONSE_CHANGED: 'staff_list_fetch_response_changed',

	STAFF_ADD_START: 'staff_add_start',
	STAFF_ADD_SUCCESS: 'staff_add_success',
	STAFF_ADD_FAILURE: 'staff_add_failure',
	STAFF_ADD_RESET_START: 'staff_add_response_reset_start',
	STAFF_ADD_RESPONSE_CHANGED: 'staff_add_response_changed',

	FETCH_STAFF_DETAILS_START: 'fetch_stsff_details_start',
	FETCH_STAFF_DETAILS_SUCCESS: 'fetch_stsff_details_success',
	FETCH_STAFF_DETAILS_FAILURE: 'fetch_stsff_details_failure',
	FETCH_STAFF_DETAILS_RESET_START: 'fetch_stsff_details_response_reset_start',
	FETCH_STAFF_DETAILS_RESPONSE_CHANGED: 'fetch_stsff_details_response_changed',

	STAFF_UPDAE_START: 'staff_update_start',
	STAFF_UPDAE_SUCCESS: 'staff_update_success',
	STAFF_UPDAE_FAILURE: 'staff_update_failure',
	STAFF_UPDAE_RESET_START: 'staff_update_response_reset_start',
	STAFF_UPDAE_RESPONSE_CHANGED: 'staff_update_response_changed',

	STAFF_DELETE_START: 'staff_delete_start',
	STAFF_DELETE_SUCCESS: 'staff_delete_success',
	STAFF_DELETE_FAILURE: 'staff_delete_failure',
	STAFF_DELETE_RESET_START: 'staff_delete_response_reset_start',
	STAFF_DELETE_RESPONSE_CHANGED: 'staff_delete_response_changed',
};

export default StaffActionTypes;
