const MemberApproveActionTypes = {
	MEMBER_APPROVAL_LIST_FETCH_START: 'member_approve_list_fetch_start',
	MEMBER_APPROVAL_LIST_FETCH_SUCCESS: 'member_approve_list_fetch_success',
	MEMBER_APPROVAL_LIST_FETCH_FAILURE: 'member_approve_list_fetch_failure',
	MEMBER_APPROVAL_LIST_FETCH_RESPONSE_RESET_START:
		'member_approve_list_fetch_response_reset_start',
	MEMBER_APPROVAL_LIST_FETCH_RESPONSE_CHANGED: 'member_approve_list_fetch_response_changed',

	FETCH_APPROVAL_MEMBER_DETAILS_START: 'fetch_approval_member_details_start',
	FETCH_APPROVAL_MEMBER_DETAILS_SUCCESS: 'fetch_approval_member_details_success',
	FETCH_APPROVAL_MEMBER_DETAILS_FAILURE: 'fetch_approval_member_details_failure',
	FETCH_APPROVAL_MEMBER_DETAILS_RESPONSE_RESET_START:
		'fetch_approval_member_details_response_reset_start',
	FETCH_APPROVAL_MEMBER_DETAILS_RESPONSE_CHANGED:
		'fetch_approval_member_details_response_changed',

	IS_AADHAR_VERIFIED: 'is_aadhar_verified',
	IS_AADHAR_REMARKED: 'is_aadhar_remarked',
	IS_AADHAR_REJECTED: 'is_aadhar_rejected',

	IS_VOTERID_VERIFIED: 'is_voterid_verified',
	IS_VOTERID_REMARKED: 'is_voterid_remarked',
	IS_VOTERID_REJECTED: 'is_voterid_rejected',

	IS_PANCARD_VERIFIED: 'is_pancard_verified',
	IS_PANCARD_REMARKED: 'is_pancard_remarked',
	IS_PANCARD_REJECTED: 'is_pancard_rejected',

	IS_DRIVING_LICENCE_VERIFIED: 'is_driving_licence_verified',
	IS_DRIVING_LICENCE_REMARKED: 'is_driving_licence_remarked',
	IS_DRIVING_LICENCE_REJECTED: 'is_driving_licence_rejected',

	IS_BANK_DETAILS_VERIFIED: 'is_bank_details_verified',
	IS_BANK_DETAILS_REMARKED: 'is_bank_details_remarked',
	IS_BANK_DETAILS_REJECTED: 'is_bank_details_rejected',

	IS_NOMINEE_DETAILS_VERIFIED: 'is_nominee_details_verified',
	IS_NOMINEE_DETAILS_REMARKED: 'is_nominee_details_remarked',
	IS_NOMINEE_DETAILS_REJECTED: 'is_nominee_details_rejected',

	IS_RESET_VERIFICATION_DETAILS: 'is_reset_verification_details',

	APPROVE_MEMBER_UPDETE_START: 'approve_member_start',
	APPROVE_MEMBER_UPDETE_SUCCESS: 'approve_member_success',
	APPROVE_MEMBER_UPDETE_FAILURE: 'approve_member_failure',
	APPROVE_MEMBER_UPDETE_RESET_UPDETE_START: 'approve_member_response_reset_start',
	APPROVE_MEMBER_UPDETE_RESPONSE_CHANGED: 'approve_member_response_changed',
};
export default MemberApproveActionTypes;
