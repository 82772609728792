// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-anonymous-default-export */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/default-param-last */
import RoleActionTypes from './role.types';
import { docodeResponse } from '../../_common/common.utils';

const INITIAL_STATE = {
	roleListFetchResponse: null,
	roleListResponseArr: [],
	roleDetailsFetchResponse: null,
	roleDetailsFetchResponseArr: [],
	addRoleResponse: null,
	updateRoleResponse: null,
	deleteRoleResponse: null,
};

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case RoleActionTypes.ROLE_LIST_FETCH_SUCCESS:
			return {
				...state,
				roleListFetchResponse: action.payload,
				roleListResponseArr: docodeResponse(action.payload, 'Fetch_Role_List'),
			};

		case RoleActionTypes.ROLE_LIST_FETCH_FAILURE:
			return { ...state, roleListFetchResponse: action.payload };

		case RoleActionTypes.ROLE_LIST_FETCH_RESPONSE_CHANGED:
			return { ...state, roleListFetchResponse: null };

		/** ***************** Fetch  Role Details ******************************* */

		case RoleActionTypes.FETCH_ROLE_DETAILS_SUCCESS:
			return {
				...state,
				roleDetailsFetchResponse: action.payload,
				roleDetailsFetchResponseArr: docodeResponse(action.payload, 'Fetch_Role_Details'),
			};

		case RoleActionTypes.FETCH_ROLE_DETAILS_FAILURE:
			return { ...state, roleDetailsFetchResponse: action.payload };

		case RoleActionTypes.FETCH_ROLE_DETAILS_RESPONSE_CHANGED:
			return { ...state, roleDetailsFetchResponse: null };

		/** ***************** Add Role Details ******************************* */

		case RoleActionTypes.ROLE_ADD_SUCCESS:
			return {
				...state,
				addRoleResponse: action.payload,
			};

		case RoleActionTypes.ROLE_ADD_FAILURE:
			return { ...state, addRoleResponse: action.payload };

		case RoleActionTypes.ROLE_ADD_RESPONSE_CHANGED:
			return { ...state, addRoleResponse: null };

		/** ***************** Update Role Details ******************************* */

		case RoleActionTypes.ROLE_UPDATE_SUCCESS:
			return {
				...state,
				updateRoleResponse: action.payload,
			};

		case RoleActionTypes.ROLE_UPDATE_FAILURE:
			return { ...state, updateRoleResponse: action.payload };

		case RoleActionTypes.ROLE_UPDATE_RESPONSE_CHANGED:
			return { ...state, updateRoleResponse: null };

		/** ***************** Delete Role Details ******************************* */

		case RoleActionTypes.ROLE_DELETE_SUCCESS:
			return {
				...state,
				deleteRoleResponse: action.payload,
			};

		case RoleActionTypes.ROLE_DELETE_FAILURE:
			return { ...state, deleteRoleResponse: action.payload };

		case RoleActionTypes.ROLE_DELETE_RESPONSE_CHANGED:
			return { ...state, deleteRoleResponse: null };

		default:
			return state;
	}
};
