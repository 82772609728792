// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import HighMarkCheckTypes from './highmark.type';

import { fetchListHighMarkCheck, addHighMarkCheck } from './highmark.services';

import {
	fetchHighMarkCheckListSuccess,
	fetchHighMarkCheckListFailure,
	fetchHighMarkCheckListResponseChanged,
	highMarkCheckAddSuccess,
	highMarkCheckAddFailure,
	highMarkCheckAddResponseChanged,
} from './highmark.action';
import { encodeJson } from '../../_common/enode-decode';

/** *********************** SATRT HIGHMARK CHECH FETCH LIST ************************ */
export function* highMarkCheckListFetch({ payload }: any) {
	const key = 'Fetch_Member_Highmark_List';
	try {
		const { limit, companyID } = payload;

		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchListHighMarkCheck, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchHighMarkCheckListFailure(responseData.data));
		} else {
			yield put(fetchHighMarkCheckListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchHighMarkCheckListFailure(error));
	}
}

export function* highMarkCheckListFetchResponseReset() {
	yield put(fetchHighMarkCheckListResponseChanged());
}

export function* onHighMarkCheckFetchList() {
	yield takeLatest(HighMarkCheckTypes.FETCH_HIGHMARK_CHACK_LIST_START, highMarkCheckListFetch);
}

export function* onHighMarkCheckFetchResponseReset() {
	yield takeLatest(
		HighMarkCheckTypes.FETCH_HIGHMARK_CHACK_LIST_RESPONSE_RESET_START,
		highMarkCheckListFetchResponseReset,
	);
}
/** *********************** END HIGHMARK CHECH FETCH LIST ************************ */

/** *********************** SATRT HIGHMARK CHECH FETCH LIST ************************ */
export function* highMarkCheckAdd({ payload }: any) {
	const key = 'Add_Member_And_Highmark_check';
	try {
		const {
			phoneNumber,
			emailID,
			aadharNumber,
			aadharName,
			panCardNumber,
			dateOfBirth,
			fatherName,
			adderss,
			pincode,
			gender,
			voterIDNumber,
			drivingLincence,
			passportNumber,
			cityName,
			stateName,
			companyID,
			userID,
		} = payload;

		const jsonData = {
			PhoneNumber: phoneNumber,
			EmailID: emailID,
			AadharNumber: aadharNumber,
			AadharName: aadharName,
			PanCardNumber: panCardNumber,
			DateOfBirth: dateOfBirth,
			FatherName: fatherName,
			Address: adderss,
			Pincode: pincode,
			Gender: gender,
			VoterIDNumber: voterIDNumber,
			DrivingLincence: drivingLincence,
			PassportNumber: passportNumber,
			CityName: cityName,
			StateName: stateName,
			CompanyID: companyID,
			CreatedBy: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(addHighMarkCheck, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(highMarkCheckAddFailure(responseData.data));
		} else {
			yield put(highMarkCheckAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(highMarkCheckAddFailure(error));
	}
}

export function* highMarkCheckAddResponseReset() {
	yield put(highMarkCheckAddResponseChanged());
}

export function* onHighMarkCheckAdd() {
	yield takeLatest(HighMarkCheckTypes.HIGHMARK_CHACK_ADD_START, highMarkCheckAdd);
}

export function* onHighMarkCheckAddResponseReset() {
	yield takeLatest(
		HighMarkCheckTypes.HIGHMARK_CHACK_ADD_RESPONSE_RESET_START,
		highMarkCheckAddResponseReset,
	);
}
/** *********************** END HIGHMARK CHECH FETCH LIST ************************ */

export function* highMarkSaga() {
	yield all([
		call(onHighMarkCheckFetchList),
		call(onHighMarkCheckFetchResponseReset),
		call(onHighMarkCheckAdd),
		call(onHighMarkCheckAddResponseReset),
	]);
}
