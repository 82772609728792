// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */

const LoanProposalActionTypes = {
	FETCH_LOAN_PROPOSAL_LIST_START: 'fetch_loan_proposal_list_start',
	FETCH_LOAN_PROPOSAL_LIST_SUCCESS: 'fetch_loan_proposal_list_success',
	FETCH_LOAN_PROPOSAL_LIST_FAILURE: 'fetch_loan_proposal_list_failure',
	FETCH_LOAN_PROPOSAL_LIST_RESPONSE_RESET_START: 'fetch_loan_proposal_list_response_reset_start',
	FETCH_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED: 'fetch_loan_proposal_list_response_changed',

	ADD_LOAN_PROPOSAL_START: 'add_loan_proposal_start',
	ADD_LOAN_PROPOSAL_SUCCESS: 'add_loan_proposal_success',
	ADD_LOAN_PROPOSAL_FAILURE: 'add_loan_proposal_failure',
	ADD_LOAN_PROPOSAL_RESPONSE_RESET_START: 'add_loan_proposal_response_reset_start',
	ADD_LOAN_PROPOSAL_RESPONSE_CHANGED: 'add_loan_proposal_response_changed',

	FETCH_LOAN_PROPOSAL_DETAILS_START: 'fetch_loan_proposal_details_start',
	FETCH_LOAN_PROPOSAL_DETAILS_SUCCESS: 'fetch_loan_proposal_details_success',
	FETCH_LOAN_PROPOSAL_DETAILS_FAILURE: 'fetch_loan_proposal_details_failure',
	FETCH_LOAN_PROPOSAL_DETAILS_RESPONSE_RESET_START:
		'fetch_loan_proposal_details_response_reset_start',
	FETCH_LOAN_PROPOSAL_DETAILS_RESPONSE_CHANGED: 'fetch_loan_proposal_details_response_changed',

	UPDATE_LOAN_PROPOSAL_START: 'update_loan_proposal_start',
	UPDATE_LOAN_PROPOSAL_SUCCESS: 'update_loan_proposal_success',
	UPDATE_LOAN_PROPOSAL_FAILURE: 'update_loan_proposal_failure',
	UPDATE_LOAN_PROPOSAL_RESPONSE_RESET_START: 'update_loan_proposal_response_reset_start',
	UPDATE_LOAN_PROPOSAL_RESPONSE_CHANGED: 'update_loan_proposal_response_changed',

	DELETE_LOAN_PROPOSAL_START: 'delete_loan_proposal_start',
	DELETE_LOAN_PROPOSAL_SUCCESS: 'delete_loan_proposal_success',
	DELETE_LOAN_PROPOSAL_FAILURE: 'delete_loan_proposal_failure',
	DELETE_LOAN_PROPOSAL_RESPONSE_RESET_START: 'delete_loan_proposal_response_reset_start',
	DELETE_LOAN_PROPOSAL_RESPONSE_CHANGED: 'delete_loan_proposal_response_changed',
};

export default LoanProposalActionTypes;
