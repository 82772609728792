// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import StaffActionTypes from './staff.type';

/** ******************  STAFF LIST START      *************************** */

export const staffListFetchStart = (staffList: any) => ({
	type: StaffActionTypes.STAFF_LIST_FETCH_START,
	payload: staffList,
});

export const staffListFetchSuccess = (success: any) => ({
	type: StaffActionTypes.STAFF_LIST_FETCH_SUCCESS,
	payload: success,
});

export const staffListFetchFailure = (failure: any) => ({
	type: StaffActionTypes.STAFF_LIST_FETCH_FAILURE,
	payload: failure,
});

export const staffListResponseResetStart = () => ({
	type: StaffActionTypes.STAFF_LIST_RESPONSE_RESET_START,
});

export const staffListFetchResponseChanged = () => ({
	type: StaffActionTypes.STAFF_LIST_FETCH_RESPONSE_CHANGED,
});

/** ******************  STAFF LIST END      *************************** */

/** ******************  STAFF ADD START      *************************** */

export const staffAddStart = (staffList: any) => ({
	type: StaffActionTypes.STAFF_ADD_START,
	payload: staffList,
});

export const staffAddSuccess = (success: any) => ({
	type: StaffActionTypes.STAFF_ADD_SUCCESS,
	payload: success,
});

export const staffAddFailure = (failure: any) => ({
	type: StaffActionTypes.STAFF_ADD_FAILURE,
	payload: failure,
});

export const staffAddResponseResetStart = () => ({
	type: StaffActionTypes.STAFF_ADD_RESET_START,
});

export const staffAddResponseChanged = () => ({
	type: StaffActionTypes.STAFF_ADD_RESPONSE_CHANGED,
});

/** ****************** STAFF ADD END      *************************** */

/** ******************  STAFF FETCH DETAILS START      *************************** */

export const fetchStaffDetailsStart = (staffList: any) => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_START,
	payload: staffList,
});

export const fetchStaffDetailsSuccess = (success: any) => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_SUCCESS,
	payload: success,
});

export const fetchStaffDetailsFailure = (failure: any) => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_FAILURE,
	payload: failure,
});

export const fetchStaffDetailsResponseResetStart = () => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_RESET_START,
});

export const fetchStaffDetailsResponseChanged = () => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_RESPONSE_CHANGED,
});

/** ****************** STAFF FETCH DETAILS END      *************************** */

/** ******************  STAFF UPDATE START      *************************** */

export const staffUpdateStart = (staffList: any) => ({
	type: StaffActionTypes.STAFF_UPDAE_START,
	payload: staffList,
});

export const staffUpdateSuccess = (success: any) => ({
	type: StaffActionTypes.STAFF_UPDAE_SUCCESS,
	payload: success,
});

export const staffUpdateFailure = (failure: any) => ({
	type: StaffActionTypes.STAFF_UPDAE_FAILURE,
	payload: failure,
});

export const staffUpdateResponseResetStart = () => ({
	type: StaffActionTypes.STAFF_UPDAE_RESET_START,
});

export const staffUpdateResponseChanged = () => ({
	type: StaffActionTypes.STAFF_UPDAE_RESPONSE_CHANGED,
});

/** ****************** STAFF UPDATE END      *************************** */

/** ******************  STAFF DELETE START      *************************** */

export const staffDeleteStart = (staffList: any) => ({
	type: StaffActionTypes.STAFF_DELETE_START,
	payload: staffList,
});

export const staffDeleteSuccess = (success: any) => ({
	type: StaffActionTypes.STAFF_DELETE_SUCCESS,
	payload: success,
});

export const staffDeleteFailure = (failure: any) => ({
	type: StaffActionTypes.STAFF_DELETE_FAILURE,
	payload: failure,
});

export const staffDeleteResponseResetStart = () => ({
	type: StaffActionTypes.STAFF_DELETE_RESET_START,
});

export const staffDeleteResponseChanged = () => ({
	type: StaffActionTypes.STAFF_DELETE_RESPONSE_CHANGED,
});

/** ****************** STAFF DELETE END *************************** */
