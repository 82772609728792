const GeneralActionTypes = {
	IS_EDIT_DETAILS_START: 'is_edit_details_start',
	IS_OPEN_OFFCANVAS_START: 'is_open_offcanvas_start',
	SIDE_TAB_VALUE_CHANGED: 'side_tab_value_changed',
	DOCUMENT_TAB_BAR_FOR_TYPE_CHANGED: 'document_tab_for_type_changed',
	DOCUMENT_TAB_BAR_VALUE_CHANGED: 'document_tab_bar_value_changed',

	GET_COUNTRY_LIST_START: 'get_country_list_start',
	GET_COUNTRY_LIST_SUCCESS: 'get_country_list_success',
	GET_COUNTRY_LIST_FAILURE: 'get_country_list_failure',
	GET_COUNTRY_LIST_RESPONSE_RESET: 'get_country_list_response_reset',
	GET_COUNTRY_LIST_RESPONSE_CHANGED: 'get_country_list_response_changed',

	GET_STATE_LIST_START: 'get_state_list_start',
	GET_STATE_LIST_SUCCESS: 'get_state_list_success',
	GET_STATE_LIST_FAILURE: 'get_state_list_failure',
	GET_STATE_LIST_RESPONSE_RESET: 'get_state_list_response_reset',
	GET_STATE_LIST_RESPONSE_CHANGED: 'get_state_list_response_changed',

	GET_CITY_LIST_START: 'get_city_list_start',
	GET_CITY_LIST_SUCCESS: 'get_city_list_success',
	GET_CITY_LIST_FAILURE: 'get_city_list_failure',
	GET_CITY_LIST_RESPONSE_RESET: 'get_city_list_response_reset',
	GET_CITY_LIST_RESPONSE_CHANGED: 'get_city_list_response_changed',

	GET_DISTRICT_LIST_START: 'get_district_list_start',
	GET_DISTRICT_LIST_SUCCESS: 'get_district_list_success',
	GET_DISTRICT_LIST_FAILURE: 'get_district_list_failure',
	GET_DISTRICT_LIST_RESPONSE_RESET: 'get_district_list_response_reset',
	GET_DISTRICT_LIST_RESPONSE_CHANGED: 'get_district_list_response_changed',

	FETCH_ALL_BRANCHES_START: 'fetch_all_branches_start',
	FETCH_ALL_BRANCHES_SUCCESS: 'fetch_all_branches_success',
	FETCH_ALL_BRANCHES_FAILURE: 'fetch_all_branches_failure',
	FETCH_ALL_BRANCHES_RESPONSE_RESET: 'fetch_all_branches_response_reset',
	FETCH_ALL_BRANCHES_RESPONSE_CHANGED: 'fetch_all_branches_response_changed',

	FETCH_BRANCH_STAFF_START: 'fetch_branch_staff_start',
	FETCH_BRANCH_STAFF_SUCCESS: 'fetch_branch_staff_success',
	FETCH_BRANCH_STAFF_FAILURE: 'fetch_branch_staff_failure',
	FETCH_BRANCH_STAFF_RESPONSE_RESET_START: 'fetch_branch_staff_response_reset_start',
	FETCH_BRANCH_STAFF_RESPONSE_CHANGED: 'fetch_branch_staff_response_changed',

	FETCH_ALL_PRODUCT_START: 'fetch_all_product_start',
	FETCH_ALL_PRODUCT_SUCCESS: 'fetch_all_product_success',
	FETCH_ALL_PRODUCT_FAILURE: 'fetch_all_product_failure',
	FETCH_ALL_PRODUCT_RESPONSE_RESET_START: 'fetch_all_product_response_reset_start',
	FETCH_ALL_PRODUCT_RESPONSE_CHANGED: 'fetch_all_product_response_changed',

	FETCH_ALL_MEMBER_START: 'fetch_all_member_start',
	FETCH_ALL_MEMBER_SUCCESS: 'fetch_all_member_success',
	FETCH_ALL_MEMBER_FAILURE: 'fetch_all_member_failure',
	FETCH_ALL_MEMBER_RESPONSE_RESET_START: 'fetch_all_member_response_reset_start',
	FETCH_ALL_MEMBER_RESPONSE_CHANGED: 'fetch_all_member_response_changed',

	FETCH_ALL_ROLE_START: 'fetch_all_role_start',
	FETCH_ALL_ROLE_SUCCESS: 'fetch_all_role_success',
	FETCH_ALL_ROLE_FAILURE: 'fetch_all_role_failure',
	FETCH_ALL_ROLE_RESPONSE_RESET_START: 'fetch_all_role_response_reset_start',
	FETCH_ALL_ROLE_RESPONSE_CHANGED: 'fetch_all_role_response_changed',

	FETCH_ALL_BRANCH_START: 'fetch_all_branch_start',
	FETCH_ALL_BRANCH_SUCCESS: 'fetch_all_branch_success',
	FETCH_ALL_BRANCH_FAILURE: 'fetch_all_branch_failure',
	FETCH_ALL_BRANCH_RESPONSE_RESET_START: 'fetch_all_branch_response_reset_start',
	FETCH_ALL_BRANCH_RESPONSE_CHANGED: 'fetch_all_branch_response_changed',
};
export default GeneralActionTypes;
