import MemberActionTypes from './member.types';

export const isMemberImageStart = (isChangeImage: any) => ({
	type: MemberActionTypes.MEMEBR_IMAGE_CHANGE_START,
	payload: isChangeImage,
});

export const isMemberNomineeIdTypeChangeStart = (nomineeIdType: any) => ({
	type: MemberActionTypes.MEMEBR_NOMINEE_ID_TYPE_CHANGE_START,
	payload: nomineeIdType,
});

/** ******************  MEMBER ADD START      *************************** */

export const MemberAddStart = (memberList: any) => ({
	type: MemberActionTypes.MEMBER_ADD_START,
	payload: memberList,
});

export const memberAddSuccess = (success: any) => ({
	type: MemberActionTypes.MEMBER_ADD_SUCCESS,
	payload: success,
});

export const memberAddFailure = (failure: any) => ({
	type: MemberActionTypes.MEMBER_ADD_FAILURE,
	payload: failure,
});

export const memberAddResponseResetStart = () => ({
	type: MemberActionTypes.MEMBER_ADD_RESET_START,
});

export const memberAddResponseChanged = () => ({
	type: MemberActionTypes.MEMBER_ADD_RESPONSE_CHANGED,
});

/** ******************  MEMBER ADD END      *************************** */

/** ******************  MEMBER UPDATE START      *************************** */

export const MemberUpdateStart = (memberList: any) => ({
	type: MemberActionTypes.MEMBER_UPDATE_START,
	payload: memberList,
});

export const memberUpdateSuccess = (success: any) => ({
	type: MemberActionTypes.MEMBER_UPDATE_SUCCESS,
	payload: success,
});

export const memberUpdateFailure = (failure: any) => ({
	type: MemberActionTypes.MEMBER_UPDATE_FAILURE,
	payload: failure,
});

export const memberUpdateResponseResetStart = () => ({
	type: MemberActionTypes.MEMBER_UPDATE_RESET_START,
});

export const memberUpdateResponseChanged = () => ({
	type: MemberActionTypes.MEMBER_UPDATE_RESPONSE_CHANGED,
});

/** ******************  MEMBER UPDATE END      *************************** */

/** ******************  MEMBER DELETE START      *************************** */

export const MemberDeleteStart = (memberList: any) => ({
	type: MemberActionTypes.MEMBER_DELETE_START,
	payload: memberList,
});

export const memberDeleteSuccess = (success: any) => ({
	type: MemberActionTypes.MEMBER_DELETE_SUCCESS,
	payload: success,
});

export const memberDeleteFailure = (failure: any) => ({
	type: MemberActionTypes.MEMBER_DELETE_FAILURE,
	payload: failure,
});

export const memberDeleteResponseResetStart = () => ({
	type: MemberActionTypes.MEMBER_DELETE_RESET_START,
});

export const memberDeleteResponseChanged = () => ({
	type: MemberActionTypes.MEMBER_DELETE_RESPONSE_CHANGED,
});

/** ******************  MEMBER UPDATE END      *************************** */

/** ******************  MEMBER LIST START      *************************** */

export const MemberListFetchStart = (memberList: any) => ({
	type: MemberActionTypes.MEMBER_LIST_FETCH_START,
	payload: memberList,
});

export const memberListFetchSuccess = (success: any) => ({
	type: MemberActionTypes.MEMBER_LIST_FETCH_SUCCESS,
	payload: success,
});

export const memberListFetchFailure = (failure: any) => ({
	type: MemberActionTypes.MEMBER_LIST_FETCH_FAILURE,
	payload: failure,
});

export const memberListResponseResetStart = () => ({
	type: MemberActionTypes.MEMBER_LIST_RESPONSE_RESET_START,
});

export const memberListFetchResponseChanged = () => ({
	type: MemberActionTypes.MEMBER_LIST_FETCH_RESPONSE_CHANGED,
});

/** ******************  MEMBER LIST END      *************************** */

/** ******************  MEMBER FETCH DETAILS START      *************************** */

export const fetchMemberDetailsStart = (memberList: any) => ({
	type: MemberActionTypes.FETCH_MEMBER_DETAILS_START,
	payload: memberList,
});

export const fetchMemberDetailsSuccess = (success: any) => ({
	type: MemberActionTypes.FETCH_MEMBER_DETAILS_SUCCESS,
	payload: success,
});

export const fetchMemberDetailsFailure = (failure: any) => ({
	type: MemberActionTypes.FETCH_MEMBER_DETAILS_FAILURE,
	payload: failure,
});

export const fetchMemberDetailsResponseResetStart = () => ({
	type: MemberActionTypes.FETCH_MEMBER_DETAILS_RESPONSE_RESET_START,
});

export const fetchMemberDetailsResponseChanged = () => ({
	type: MemberActionTypes.FETCH_MEMBER_DETAILS_RESPONSE_CHANGED,
});

/** ******************  MEMBER FETCH DETAILS END      *************************** */

/** ****************** AADHAR FETCH START      *************************** */

export const aadharFetchStart = (aadharFetch: any) => ({
	type: MemberActionTypes.AADHAR_FETCH_START,
	payload: aadharFetch,
});

export const aadharDetailsFetchSuccess = (aadharFetchSuccess: any) => ({
	type: MemberActionTypes.AADHAR_FETCH_SUCCESS,
	payload: aadharFetchSuccess,
});

export const aadharDetailsFetchFailure = (aadharFetchFailure: any) => ({
	type: MemberActionTypes.AADHAR_FETCH_FAILURE,
	payload: aadharFetchFailure,
});

export const aadharResponseResetStart = () => ({
	type: MemberActionTypes.AADHAR_RESPONSE_RESET_START,
});

export const aadharFetchResponseChanged = () => ({
	type: MemberActionTypes.AADHAR_FETCH_RESPONSE_CHANGED,
});

/** ******************  AADHAR FETCH  END      *************************** */

/** ****************** AADHAR UPDATE START      *************************** */

export const aadharUpdateStart = (memberList: any) => ({
	type: MemberActionTypes.AADHAR_UPDATE_START,
	payload: memberList,
});

export const aadharUpdateSuccess = (success: any) => ({
	type: MemberActionTypes.AADHAR_UPDATE_SUCCESS,
	payload: success,
});

export const aadharUpdateFailure = (failure: any) => ({
	type: MemberActionTypes.AADHAR_UPDATE_FAILURE,
	payload: failure,
});

export const aadharUpdateResponseResetStart = () => ({
	type: MemberActionTypes.AADHAR_UPDATE_RESPONSE_RESET_START,
});

export const aadharUpdateResponseChanged = () => ({
	type: MemberActionTypes.AADHAR_UPDATE_RESPONSE_CHANGED,
});

/** ******************  AADHAR UPDATE  END      *************************** */

/** ****************** PAN FETCH START      *************************** */

export const panFetchStart = (memberList: any) => ({
	type: MemberActionTypes.PAN_FETCH_START,
	payload: memberList,
});

export const panDetailsFetchSuccess = (success: any) => ({
	type: MemberActionTypes.PAN_FETCH_SUCCESS,
	payload: success,
});

export const panDetailsFetchFailure = (failure: any) => ({
	type: MemberActionTypes.PAN_FETCH_FAILURE,
	payload: failure,
});

export const panResponseResetStart = () => ({
	type: MemberActionTypes.PAN_RESPONSE_RESET_START,
});

export const panFetchResponseChanged = () => ({
	type: MemberActionTypes.PAN_FETCH_RESPONSE_CHANGED,
});

/** ******************  PAN FETCH  END      *************************** */

/** ****************** PAN UPDATE START      *************************** */

export const panUpdateStart = (memberList: any) => ({
	type: MemberActionTypes.PAN_UPDATE_START,
	payload: memberList,
});

export const panUpdateSuccess = (success: any) => ({
	type: MemberActionTypes.PAN_UPDATE_SUCCESS,
	payload: success,
});

export const panUpdateFailure = (failure: any) => ({
	type: MemberActionTypes.PAN_UPDATE_FAILURE,
	payload: failure,
});

export const panUpdateResponseResetStart = () => ({
	type: MemberActionTypes.PAN_UPDATE_RESPONSE_RESET_START,
});

export const panUpdateResponseChanged = () => ({
	type: MemberActionTypes.PAN_UPDATE_RESPONSE_CHANGED,
});

/** ******************  PAN UPDATE  END      *************************** */

/** ****************** DRIVINGLICENCE FETCH START      *************************** */

export const drivingLicenceFetchStart = (memberList: any) => ({
	type: MemberActionTypes.DRIVINGLICENCE_FETCH_START,
	payload: memberList,
});

export const drivingLicenceFetchSuccess = (success: any) => ({
	type: MemberActionTypes.DRIVINGLICENCE_FETCH_SUCCESS,
	payload: success,
});

export const drivingLicenceFetchFailure = (failure: any) => ({
	type: MemberActionTypes.DRIVINGLICENCE_FETCH_FAILURE,
	payload: failure,
});

export const drivingLicenceResponseResetStart = () => ({
	type: MemberActionTypes.DRIVINGLICENCE_RESPONSE_RESET_START,
});

export const drivingLicenceFetchResponseChanged = () => ({
	type: MemberActionTypes.DRIVINGLICENCE_FETCH_RESPONSE_CHANGED,
});

/** ******************  DRIVINGLICENCE FETCH  END      *************************** */

/** ****************** DRIVINGLICENCE UPDATE START      *************************** */

export const drivingLicenceUpdateStart = (memberList: any) => ({
	type: MemberActionTypes.DRIVINGLICENCE_UPDATE_START,
	payload: memberList,
});

export const drivingLicenceUpdateSuccess = (success: any) => ({
	type: MemberActionTypes.DRIVINGLICENCE_UPDATE_SUCCESS,
	payload: success,
});

export const drivingLicenceUpdateFailure = (failure: any) => ({
	type: MemberActionTypes.DRIVINGLICENCE_UPDATE_FAILURE,
	payload: failure,
});

export const drivingLicenceUpdateResponseResetStart = () => ({
	type: MemberActionTypes.DRIVINGLICENCE_RESPONSE_UPDATE_RESET_START,
});

export const drivingLicenceUpdateResponseChanged = () => ({
	type: MemberActionTypes.DRIVINGLICENCE_UPDATE_RESPONSE_CHANGED,
});

/** ******************  DRIVINGLICENCE UPDATE  END      *************************** */

/** ****************** VOTERID FETCH START      *************************** */

export const voterIDFetchStart = (memberList: any) => ({
	type: MemberActionTypes.VOTERID_FETCH_START,
	payload: memberList,
});

export const voterIDFetchSuccess = (success: any) => ({
	type: MemberActionTypes.VOTERID_FETCH_SUCCESS,
	payload: success,
});

export const voterIDFetchFailure = (failure: any) => ({
	type: MemberActionTypes.VOTERID_FETCH_FAILURE,
	payload: failure,
});

export const voterIDFetchResponseResetStart = () => ({
	type: MemberActionTypes.VOTERID_RESPONSE_RESET_START,
});

export const voterIDFetchResponseChanged = () => ({
	type: MemberActionTypes.VOTERID_FETCH_RESPONSE_CHANGED,
});

/** ******************  VOTERID FETCH END      *************************** */

/** ****************** VOTERID UPDATE START      *************************** */

export const voterIDUpdateStart = (memberList: any) => ({
	type: MemberActionTypes.VOTERID_UPDATE_START,
	payload: memberList,
});

export const voterIDUpdateSuccess = (success: any) => ({
	type: MemberActionTypes.VOTERID_UPDATE_SUCCESS,
	payload: success,
});

export const voterIDUpdateFailure = (failure: any) => ({
	type: MemberActionTypes.VOTERID_UPDATE_FAILURE,
	payload: failure,
});

export const voterIDUpdateResponseResetStart = () => ({
	type: MemberActionTypes.VOTERID_UPDATE_RESPONSE_RESET_START,
});

export const voterIDUpdateResponseChanged = () => ({
	type: MemberActionTypes.VOTERID_UPDATE_RESPONSE_CHANGED,
});

/** ******************  VOTERID UPDATE END      *************************** */

/** ****************** BANK DETAILS FETCH START      *************************** */

export const bankDetailsFetchStart = (memberList: any) => ({
	type: MemberActionTypes.BANKDETAILS_FETCH_START,
	payload: memberList,
});

export const bankDetailsFetchSuccess = (success: any) => ({
	type: MemberActionTypes.BANKDETAILS_FETCH_SUCCESS,
	payload: success,
});

export const bankDetailsFetchFailure = (failure: any) => ({
	type: MemberActionTypes.BANKDETAILS_FETCH_FAILURE,
	payload: failure,
});

export const bankDetailsFetchResponseResetStart = () => ({
	type: MemberActionTypes.BANKDETAILS_RESPONSE_RESET_START,
});

export const bankDetailsFetchResponseChanged = () => ({
	type: MemberActionTypes.BANKDETAILS_FETCH_RESPONSE_CHANGED,
});

/** ******************  BANK DETAILS FETCH END      *************************** */

/** ****************** BANK DETAILS UPDATE START      *************************** */

export const bankDetailsUpdateStart = (memberList: any) => ({
	type: MemberActionTypes.BANKDETAILS_UPDATE_START,
	payload: memberList,
});

export const bankDetailsUpdateSuccess = (success: any) => ({
	type: MemberActionTypes.BANKDETAILS_UPDATE_SUCCESS,
	payload: success,
});

export const bankDetailsUpdateFailure = (failure: any) => ({
	type: MemberActionTypes.BANKDETAILS_UPDATE_FAILURE,
	payload: failure,
});

export const bankDetailsUpdateResponseResetStart = () => ({
	type: MemberActionTypes.BANKDETAILS_UPDATE_RESPONSE_RESET_START,
});

export const bankDetailsUpdateResponseChanged = () => ({
	type: MemberActionTypes.BANKDETAILS_UPDATE_RESPONSE_CHANGED,
});

/** ******************  BANK DETAILS UPDATE END *************************** */

/** ****************** NOMINEE DETAILS FETCH START      *************************** */

export const nomineeDetailsFetchStart = (memberList: any) => ({
	type: MemberActionTypes.NOMINEE_DETAILS_FETCH_START,
	payload: memberList,
});

export const nomineeDetailsFetchSuccess = (success: any) => ({
	type: MemberActionTypes.NOMINEE_DETAILS_FETCH_SUCCESS,
	payload: success,
});

export const nomineeDetailsFetchFailure = (failure: any) => ({
	type: MemberActionTypes.NOMINEE_DETAILS_FETCH_FAILURE,
	payload: failure,
});

export const nomineeDetailsFetchResponseResetStart = () => ({
	type: MemberActionTypes.NOMINEE_DETAILS_RESPONSE_RESET_START,
});

export const nomineeDetailsFetchResponseChanged = () => ({
	type: MemberActionTypes.NOMINEE_DETAILS_FETCH_RESPONSE_CHANGED,
});

/** ****************** NOMINEE  UODATE START *************************** */

export const nomineeUpdateStart = (memberList: any) => ({
	type: MemberActionTypes.NOMINEE_UPDATE_START,
	payload: memberList,
});

export const nomineeUpdateSuccess = (success: any) => ({
	type: MemberActionTypes.NOMINEE_UPDATE_SUCCESS,
	payload: success,
});

export const nomineeUpdateFailure = (failure: any) => ({
	type: MemberActionTypes.NOMINEE_UPDATE_FAILURE,
	payload: failure,
});

export const nomineeUpdateResponseResetStart = () => ({
	type: MemberActionTypes.NOMINEE_UPDATE_RESPONSE_RESET_START,
});

export const nomineeUpdateResponseChanged = () => ({
	type: MemberActionTypes.NOMINEE_UPDATE_RESPONSE_CHANGED,
});

/** ****************** NOMINEE ADD START      *************************** */

export const nomineeAddStart = (memberList: any) => ({
	type: MemberActionTypes.NOMINEE_ADD_START,
	payload: memberList,
});

export const nomineeAddSuccess = (success: any) => ({
	type: MemberActionTypes.NOMINEE_ADD_SUCCESS,
	payload: success,
});

export const nomineeAddFailure = (failure: any) => ({
	type: MemberActionTypes.NOMINEE_ADD_FAILURE,
	payload: failure,
});

export const nomineeAddResponseResetStart = () => ({
	type: MemberActionTypes.NOMINEE_ADD_RESPONSE_RESET_START,
});

export const nomineeAddResponseChanged = () => ({
	type: MemberActionTypes.NOMINEE_ADD_RESPONSE_CHANGED,
});

/** ******************MEMBER ID START      *************************** */

export const selectMemberIDChangeStart = (memberList: any) => ({
	type: MemberActionTypes.SELECT_MEMBER_ID_CHANGE_START,
	payload: memberList,
});
