import RoleActionTypes from './role.types';

export const roleListFetchStart = (roleList: any) => ({
	type: RoleActionTypes.ROLE_LIST_FETCH_START,
	payload: roleList,
});

export const roleListFetchSuccess = (success: any) => ({
	type: RoleActionTypes.ROLE_LIST_FETCH_SUCCESS,
	payload: success,
});

export const roleListFetchFailure = (failure: any) => ({
	type: RoleActionTypes.ROLE_LIST_FETCH_FAILURE,
	payload: failure,
});

export const roleListResponseResetStart = () => ({
	type: RoleActionTypes.ROLE_LIST_RESPONSE_RESET_START,
});

export const roleListFetchResponseChanged = () => ({
	type: RoleActionTypes.ROLE_LIST_FETCH_RESPONSE_CHANGED,
});

/** ******************** FETCH_ROLE_DETAILS_START **************** */

export const fetchRoleDetailsStart = (roleList: any) => ({
	type: RoleActionTypes.FETCH_ROLE_DETAILS_START,
	payload: roleList,
});

export const fetchRoleDetailsSuccess = (success: any) => ({
	type: RoleActionTypes.FETCH_ROLE_DETAILS_SUCCESS,
	payload: success,
});

export const fetchRoleDetailsFailure = (failure: any) => ({
	type: RoleActionTypes.FETCH_ROLE_DETAILS_FAILURE,
	payload: failure,
});

export const fetchRoleDetailsResponseResetStart = () => ({
	type: RoleActionTypes.FETCH_ROLE_DETAILS_RESPONSE_RESET_START,
});

export const fetchRoleDetailsResponseChanged = () => ({
	type: RoleActionTypes.FETCH_ROLE_DETAILS_RESPONSE_CHANGED,
});

/** ******************** ROLE ADD START **************** */

export const roleAddStart = (roleList: any) => ({
	type: RoleActionTypes.ROLE_ADD_START,
	payload: roleList,
});

export const roleAddSuccess = (success: any) => ({
	type: RoleActionTypes.ROLE_ADD_SUCCESS,
	payload: success,
});

export const roleAddFailure = (failure: any) => ({
	type: RoleActionTypes.ROLE_ADD_FAILURE,
	payload: failure,
});

export const roleAddResponseResetStart = () => ({
	type: RoleActionTypes.ROLE_ADD_RESPONSE_RESET_START,
});

export const roleAddResponseChanged = () => ({
	type: RoleActionTypes.ROLE_ADD_RESPONSE_CHANGED,
});

/** ******************** ROLE UPDATE START **************** */

export const roleUpdateStart = (roleList: any) => ({
	type: RoleActionTypes.ROLE_UPDATE_START,
	payload: roleList,
});

export const roleUpdateSuccess = (success: any) => ({
	type: RoleActionTypes.ROLE_UPDATE_SUCCESS,
	payload: success,
});

export const roleUpdateFailure = (failure: any) => ({
	type: RoleActionTypes.ROLE_UPDATE_FAILURE,
	payload: failure,
});

export const roleUpdateResponseResetStart = () => ({
	type: RoleActionTypes.ROLE_UPDATE_RESPONSE_RESET_START,
});

export const roleUpdateResponseChanged = () => ({
	type: RoleActionTypes.ROLE_UPDATE_RESPONSE_CHANGED,
});
/** ******************** ROLE UPDATE END **************** */

/** ***************** DELETE ROLE DETAILS START *********************** */
export const deleteRoleStart = (roleList: any) => ({
	type: RoleActionTypes.ROLE_DELETE_START,
	payload: roleList,
});

export const deleteRoleSuccess = (success: any) => ({
	type: RoleActionTypes.ROLE_DELETE_SUCCESS,
	payload: success,
});

export const deleteRoleFailure = (failure: any) => ({
	type: RoleActionTypes.ROLE_DELETE_FAILURE,
	payload: failure,
});

export const deleteRoleResponseResetStart = () => ({
	type: RoleActionTypes.ROLE_DELETE_RESPONSE_RESET_START,
});

export const deleteRoleResponseChanged = () => ({
	type: RoleActionTypes.ROLE_DELETE_RESPONSE_CHANGED,
});

/** ***************** DELETE ROLE DETAILS END *********************** */
