const BranchActionTypes = {
	BRANCH_LIST_FETCH_START: 'branch_list_fetch_start',
	BRANCH_LIST_FETCH_SUCCESS: 'branch_list_fetch_success',
	BRANCH_LIST_FETCH_FAILURE: 'branch_list_fetch_failure',
	BRANCH_LIST_RESPONSE_RESET_START: 'branch_list_response_reset_start',
	BRANCH_LIST_FETCH_RESPONSE_CHANGED: 'branch_list_fetch_response_changed',

	BRANCH_ADD_START: 'branch_add_start',
	BRANCH_ADD_SUCCESS: 'branch_add_success',
	BRANCH_ADD_FAILURE: 'branch_add_failure',
	BRANCH_ADD_RESPONSE_RESET_START: 'branch_add_response_reset_start',
	BRANCH_ADD_RESPONSE_CHANGED: 'branch_add_response_changed',

	FETCH_BRANCH_DETAILS_START: 'fetch_branch_details_start',
	FETCH_BRANCH_DETAILS_SUCCESS: 'fetch_branch_details_success',
	FETCH_BRANCH_DETAILS_FAILURE: 'fetch_branch_details_failure',
	FETCH_BRANCH_DETAILS_RESPONSE_RESET_START: 'fetch_branch_details_response_reset_start',
	FETCH_BRANCH_DETAILS_RESPONSE_CHANGED: 'fetch_branch_details_response_changed',

	BRANCH_UPDATE_START: 'branch_update_start',
	BRANCH_UPDATE_SUCCESS: 'branch_update_success',
	BRANCH_UPDATE_FAILURE: 'branch_update_failure',
	BRANCH_UPDATE_RESPONSE_RESET_START: 'branch_update_response_reset_start',
	BRANCH_UPDATE_RESPONSE_CHANGED: 'branch_update_response_changed',

	BRANCH_DELETE_START: 'branch_delete_start',
	BRANCH_DELETE_SUCCESS: 'branch_delete_success',
	BRANCH_DELETE_FAILURE: 'branch_delete_failure',
	BRANCH_DELETE_RESPONSE_RESET_START: 'branch_delete_response_reset_start',
	BRANCH_DELETE_RESPONSE_CHANGED: 'branch_delete_response_changed',
};

export default BranchActionTypes;
