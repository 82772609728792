// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-anonymous-default-export */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/default-param-last */
import GeneralActionTypes from './general.types';
import { docodeResponse } from '../_common/common.utils';

const INITIAL_STATE = {
	isDetailsEdit: false,
	isOffcanvasOpen: false,
	sideTabValue: 'Member Details',
	documentBarType: '',
	documentTabBardValue: 'Aadhar',
	getCountryListResponse: [],
	getStateListResponse: [],
	getCityListResponse: [],
	getDistrictListResponse: [],
	fetchAllBranchesResponse: null,
	fetchAllBranchesArr: [],
	fetchBranchStaffResponse: null,
	fetchBranchStaffArr: [],
	fetchAllProductResponse: null,
	fetchAllProductArr: [],
	fetchAllMemberResponse: null,
	fetchAllMemberArr: [],
	fetchAllRoleResponse: null,
	fetchAllRoleArr: [],
	fetchAllBranchResponse: null,
	fetchAllBranchArr: [],
};

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case GeneralActionTypes.IS_EDIT_DETAILS_START:
			return { ...state, isDetailsEdit: action.payload };

		case GeneralActionTypes.IS_OPEN_OFFCANVAS_START:
			return { ...state, isOffcanvasOpen: action.payload };

		case GeneralActionTypes.SIDE_TAB_VALUE_CHANGED:
			return { ...state, sideTabValue: action.payload };

		case GeneralActionTypes.DOCUMENT_TAB_BAR_FOR_TYPE_CHANGED:
			return { ...state, documentBarType: action.payload };

		case GeneralActionTypes.DOCUMENT_TAB_BAR_VALUE_CHANGED:
			return { ...state, documentTabBardValue: action.payload };

		case GeneralActionTypes.GET_COUNTRY_LIST_SUCCESS:
			return {
				...state,
				getCountryListResponse: docodeResponse(action.payload, 'GetCountryListKey'),
			};

		case GeneralActionTypes.GET_COUNTRY_LIST_FAILURE:
			return { ...state, getCountryListResponse: [] };

		case GeneralActionTypes.GET_COUNTRY_LIST_RESPONSE_CHANGED:
			return { ...state, getCountryListResponse: [] };

		case GeneralActionTypes.GET_STATE_LIST_SUCCESS:
			return {
				...state,
				getStateListResponse: docodeResponse(action.payload, 'CountryStateListGetKey'),
			};

		case GeneralActionTypes.GET_STATE_LIST_FAILURE:
			return { ...state, getStateListResponse: [] };

		case GeneralActionTypes.GET_STATE_LIST_RESPONSE_CHANGED:
			return { ...state, getStateListResponse: [] };

		case GeneralActionTypes.GET_CITY_LIST_SUCCESS:
			return {
				...state,
				getCityListResponse: docodeResponse(action.payload, 'StateCityListGetKey'),
			};

		case GeneralActionTypes.GET_CITY_LIST_FAILURE:
			return { ...state, getCityListResponse: [] };

		case GeneralActionTypes.GET_CITY_LIST_RESPONSE_CHANGED:
			return { ...state, getCityListResponse: [] };

		case GeneralActionTypes.GET_DISTRICT_LIST_SUCCESS:
			return {
				...state,
				getDistrictListResponse: docodeResponse(action.payload, 'DistrictCityListGetKey'),
			};

		case GeneralActionTypes.GET_DISTRICT_LIST_FAILURE:
			return { ...state, getDistrictListResponse: [] };

		case GeneralActionTypes.GET_DISTRICT_LIST_RESPONSE_CHANGED:
			return { ...state, getDistrictListResponse: [] };

		case GeneralActionTypes.FETCH_ALL_BRANCHES_SUCCESS:
			return {
				...state,
				fetchAllBranchesResponse: action.payload,
				fetchAllBranchesArr: docodeResponse(action.payload, 'FetchAllBranchKey'),
			};

		case GeneralActionTypes.FETCH_ALL_BRANCHES_FAILURE:
			return { ...state, fetchAllBranchesResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_BRANCHES_RESPONSE_CHANGED:
			return { ...state, fetchAllBranchesResponse: null };

		case GeneralActionTypes.FETCH_BRANCH_STAFF_SUCCESS:
			return {
				...state,
				fetchBranchStaffResponse: action.payload,
				fetchBranchStaffArr: docodeResponse(action.payload, 'GetBranchStaffList'),
			};

		case GeneralActionTypes.FETCH_BRANCH_STAFF_FAILURE:
			return { ...state, fetchBranchStaffResponse: action.payload };

		case GeneralActionTypes.FETCH_BRANCH_STAFF_RESPONSE_CHANGED:
			return { ...state, fetchBranchStaffResponse: null };

		/** ************************* ALL PRODUCT FETCH *********************** */
		case GeneralActionTypes.FETCH_ALL_PRODUCT_SUCCESS:
			return {
				...state,
				fetchAllProductResponse: action.payload,
				fetchAllProductArr: docodeResponse(action.payload, 'Fetch_All_Product'),
			};

		case GeneralActionTypes.FETCH_ALL_PRODUCT_FAILURE:
			return { ...state, fetchAllProductResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_PRODUCT_RESPONSE_CHANGED:
			return { ...state, fetchAllProductResponse: null };

		/** ************************* ALL MEMBER FETCH *********************** */
		case GeneralActionTypes.FETCH_ALL_MEMBER_SUCCESS:
			return {
				...state,
				fetchAllMemberResponse: action.payload,
				fetchAllMemberArr: docodeResponse(action.payload, 'Fetch_All_Member'),
			};

		case GeneralActionTypes.FETCH_ALL_MEMBER_FAILURE:
			return { ...state, fetchAllMemberResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_MEMBER_RESPONSE_CHANGED:
			return { ...state, fetchAllMemberResponse: null };

		/** ************************* ALL ROLE FETCH *********************** */
		case GeneralActionTypes.FETCH_ALL_ROLE_SUCCESS:
			return {
				...state,
				fetchAllRoleResponse: action.payload,
				fetchAllRoleArr: docodeResponse(action.payload, 'Fetch_All_Role'),
			};

		case GeneralActionTypes.FETCH_ALL_ROLE_FAILURE:
			return { ...state, fetchAllRoleResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_ROLE_RESPONSE_CHANGED:
			return { ...state, fetchAllRoleResponse: null };

		/** ************************* ALL Branch FETCH *********************** */
		case GeneralActionTypes.FETCH_ALL_BRANCH_SUCCESS:
			return {
				...state,
				fetchAllBranchResponse: action.payload,
				fetchAllBranchArr: docodeResponse(action.payload, 'Fetch_All_Branch'),
			};

		case GeneralActionTypes.FETCH_ALL_BRANCH_FAILURE:
			return { ...state, fetchAllBranchResponse: action.payload };

		case GeneralActionTypes.FETCH_ALL_BRANCH_RESPONSE_CHANGED:
			return { ...state, fetchAllBranchResponse: null };

		default:
			return state;
	}
};
