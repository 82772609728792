// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

/** *************** LOAN PROPOSAL FETCH DETAILS ***************** */
export const fetchListLoanProposal = (fetchListData: JSON) => {
	const data = {
		LoanProposalListFetchToken: fetchListData,
	};

	const url = `${API_URL}fetchLoanProposalList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** LOAN PROPOSAL FETCH DETAILS ***************** */

/** *************** LOAN PROPOSAL ADD DETAILS ***************** */
export const addDetailsLoanProposal = (addData: JSON) => {
	const data = {
		LoanProposalAddToken: addData,
	};

	const url = `${API_URL}addLoanProposal`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** LOAN PROPOSAL ADD DETAILS ***************** */

/** *************** LOAN PROPOSAL DETAILS FETCH ***************** */
export const getLoanProposalDetails = (poprosalDetailsFetch: JSON) => {
	const data = {
		LoanProposalDetailsToken: poprosalDetailsFetch,
	};

	const url = `${API_URL}loanProposalDetailFetch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** LOAN PROPOSAL DETAILS FETCH ***************** */

/** *************** LOAN PROPOSAL UPDATE ***************** */
export const updateLoanProposal = (updateData: JSON) => {
	const data = {
		UpdateLoanProposalToken: updateData,
	};

	const url = `${API_URL}updateLoanProposal`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** LOAN PROPOSAL UPDATE  ***************** */

/** *************** LOAN PROPOSAL DELETE ***************** */
export const deleteLoanProposal = (updateData: JSON) => {
	const data = {
		DeleteLoanProposalToken: updateData,
	};

	const url = `${API_URL}DeleteLoanProposal`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** LOAN PROPOSAL UPDATE DELETE ***************** */
