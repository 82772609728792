import ProductActionTypes from './product.type';

export const productListFetchStart = (productList: any) => ({
	type: ProductActionTypes.PRODUCT_LIST_FETCH_START,
	payload: productList,
});

export const productListFetchSuccess = (success: any) => ({
	type: ProductActionTypes.PRODUCT_LIST_FETCH_SUCCESS,
	payload: success,
});

export const productListFetchFailure = (failure: any) => ({
	type: ProductActionTypes.PRODUCT_LIST_FETCH_FAILURE,
	payload: failure,
});

export const productListResponseResetStart = () => ({
	type: ProductActionTypes.PRODUCT_LIST_RESPONSE_RESET_START,
});

export const productListFetchResponseChanged = () => ({
	type: ProductActionTypes.PRODUCT_LIST_FETCH_RESPONSE_CHANGED,
});

/** ************************* ADD PRODUCT START *************** */

export const productAddStart = (productList: any) => ({
	type: ProductActionTypes.PRODUCT_ADD_START,
	payload: productList,
});

export const productAddSuccess = (success: any) => ({
	type: ProductActionTypes.PRODUCT_ADD_SUCCESS,
	payload: success,
});

export const productAddFailure = (failure: any) => ({
	type: ProductActionTypes.PRODUCT_ADD_FAILURE,
	payload: failure,
});

export const productAddResponseResetStart = () => ({
	type: ProductActionTypes.PRODUCT_ADD_RESPONSE_RESET_START,
});

export const productAddResponseChanged = () => ({
	type: ProductActionTypes.PRODUCT_ADD_RESPONSE_CHANGED,
});

/** ************************* ADD PRODUCT END *************** */

/** ************************* UPDATE PRODUCT START *************** */

export const productUpdateStart = (productList: any) => ({
	type: ProductActionTypes.PRODUCT_UPDATE_START,
	payload: productList,
});

export const productUpdateSuccess = (success: any) => ({
	type: ProductActionTypes.PRODUCT_UPDATE_SUCCESS,
	payload: success,
});

export const productUpdateFailure = (failure: any) => ({
	type: ProductActionTypes.PRODUCT_UPDATE_FAILURE,
	payload: failure,
});

export const productUpdateResponseResetStart = () => ({
	type: ProductActionTypes.PRODUCT_UPDATE_RESPONSE_RESET_START,
});

export const productUpdateResponseChanged = () => ({
	type: ProductActionTypes.PRODUCT_UPDATE_RESPONSE_CHANGED,
});

/** ************************* UPDATE PRODUCT END *************** */

/** ************************* FETCH PRODUCT DETAILS START *************** */

export const fetchProductDetailsStart = (productList: any) => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_START,
	payload: productList,
});

export const fetchProductDetailsSuccess = (success: any) => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS,
	payload: success,
});

export const fetchProductDetailsFailure = (failure: any) => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_FAILURE,
	payload: failure,
});

export const fetchProductDetailsResponseResetStart = () => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_RESET_START,
});

export const fetchProductDetailsResponseChanged = () => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_CHANGED,
});

/** ************************* FETCH PRODUCT DETAILS END *************** */

/** ************************* DELETE PRODUCT START *************** */

export const productDeleteStart = (productList: any) => ({
	type: ProductActionTypes.PRODUCT_DELETE_START,
	payload: productList,
});

export const productListDeleteSuccess = (success: any) => ({
	type: ProductActionTypes.PRODUCT_DELETE_SUCCESS,
	payload: success,
});

export const productDeleteFailure = (failure: any) => ({
	type: ProductActionTypes.PRODUCT_DELETE_FAILURE,
	payload: failure,
});

export const productDeleteResponseResetStart = () => ({
	type: ProductActionTypes.PRODUCT_DELETE_RESPONSE_RESET_START,
});

export const productDeleteResponseChanged = () => ({
	type: ProductActionTypes.PRODUCT_DELETE_RESPONSE_CHANGED,
});

/** ************************* DELETE PRODUCT END *************** */
