// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/default-param-last */
import MemberActionTypes from './member.types';
import { docodeResponse } from '../../_common/common.utils';

const INITIAL_STATE = {
	memberImageUrl: '',
	memberNomineeIdType: '',
	memberUpdateResponse: null,
	memberDeleteResponse: null,
	memberAddResponse: null,
	memberListFetchResponse: null,
	memberListResponseArr: [],
	fetchMemberDetailsResponse: null,
	fetchMemberDetailsArr: [],
	aadharListFetchResponse: null,
	aadharListResponseArr: [],
	aadharListUpdateResponse: null,
	aadharListUpdateArr: null,
	panListFetchResponse: null,
	panListResponseArr: [],
	panListUpdateResponse: null,
	panListUpdateResponseArr: [],
	dlListFetchResponse: null,
	dlListFetchResponseArr: [],
	dlListUpdateResponse: null,
	dlListUpdateResponseArr: [],
	voteridListFetchResponse: null,
	voteridListFetchResponseArr: [],
	voteridListUpdateResponse: null,
	voteridListUpdateResponseArr: [],
	bankDetailsFetchResponse: null,
	bankDetailsFetchResponseArr: [],
	bankDetailsUpdateResponse: null,
	bankDetailsUpdateResponseArr: [],
	nomineeDetailsFetchResponse: null,
	nomineeDetailsFetchResponseArr: [],
	nomineeUpdateResponse: null,
	nomineeAddResponse: null,
};

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case MemberActionTypes.MEMEBR_IMAGE_CHANGE_START:
			return { ...state, memberImageUrl: action.payload };

		/** *******************  MEMBER ADD ******************* */
		case MemberActionTypes.MEMBER_ADD_SUCCESS:
			return {
				...state,
				memberAddResponse: action.payload,
			};

		case MemberActionTypes.MEMBER_ADD_FAILURE:
			return { ...state, memberAddResponse: action.payload };

		case MemberActionTypes.MEMBER_ADD_RESPONSE_CHANGED:
			return { ...state, memberAddResponse: null };

		/** *******************  MEMBER ADD ******************* */

		/** *******************  MEMBER UPDATE ******************* */

		case MemberActionTypes.MEMBER_UPDATE_SUCCESS:
			return {
				...state,
				memberUpdateResponse: action.payload,
			};

		case MemberActionTypes.MEMBER_UPDATE_FAILURE:
			return { ...state, memberUpdateResponse: action.payload };

		case MemberActionTypes.MEMBER_UPDATE_RESPONSE_CHANGED:
			return { ...state, memberUpdateResponse: null };

		/** *******************  MEMBER DELETE ******************* */

		case MemberActionTypes.MEMBER_DELETE_SUCCESS:
			return {
				...state,
				memberDeleteResponse: action.payload,
			};

		case MemberActionTypes.MEMBER_DELETE_FAILURE:
			return { ...state, memberDeleteResponse: action.payload };

		case MemberActionTypes.MEMBER_DELETE_RESPONSE_CHANGED:
			return { ...state, memberDeleteResponse: null };

		/** *******************  MEMBER FETCH ******************* */

		case MemberActionTypes.MEMBER_LIST_FETCH_SUCCESS:
			return {
				...state,
				memberListFetchResponse: action.payload,
				memberListResponseArr: docodeResponse(action.payload, 'Fetch_Member_List'),
			};

		case MemberActionTypes.MEMBER_LIST_FETCH_FAILURE:
			return { ...state, memberListFetchResponse: action.payload };

		case MemberActionTypes.MEMBER_LIST_FETCH_RESPONSE_CHANGED:
			return { ...state, memberListFetchResponse: null };

		/** *******************  PARTICULAR MEMBER DETAILS FETCH ******************* */

		case MemberActionTypes.FETCH_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				fetchMemberDetailsResponse: action.payload,
				fetchMemberDetailsArr: docodeResponse(action.payload, 'Fetch_Member_Details'),
			};

		case MemberActionTypes.FETCH_MEMBER_DETAILS_FAILURE:
			return { ...state, fetchMemberDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchMemberDetailsResponse: null };

		/** *******************  AADHAR ******************* */

		case MemberActionTypes.AADHAR_FETCH_SUCCESS:
			return {
				...state,
				aadharListFetchResponse: action.payload,
				aadharListResponseArr: docodeResponse(action.payload, 'Fetch_Aadhar_Details'),
			};

		case MemberActionTypes.AADHAR_FETCH_FAILURE:
			return { ...state, aadharListFetchResponse: action.payload };

		case MemberActionTypes.AADHAR_FETCH_RESPONSE_CHANGED:
			return { ...state, aadharListFetchResponse: '' };

		/** *******************  AADHAR ******************* */

		/** ******************* AADHAR UPDATE **************** */

		case MemberActionTypes.AADHAR_UPDATE_SUCCESS:
			return {
				...state,
				aadharListUpdateResponse: action.payload,
				aadharListUpdateArr: docodeResponse(action.payload, 'Update_Aadhar_Details'),
			};

		case MemberActionTypes.AADHAR_UPDATE_FAILURE:
			return { ...state, aadharListUpdateResponse: action.payload };

		case MemberActionTypes.AADHAR_UPDATE_RESPONSE_CHANGED:
			return { ...state, aadharListUpdateResponse: null };

		/** *******************  AADHAR UPDATE******************* */

		/** *******************  PAN FETCH ******************* */

		case MemberActionTypes.PAN_FETCH_SUCCESS:
			return {
				...state,
				panListFetchResponse: action.payload,
				panListResponseArr: docodeResponse(action.payload, 'Fetch_Pan_Card'),
			};

		case MemberActionTypes.PAN_FETCH_FAILURE:
			return { ...state, panListFetchResponse: action.payload };

		case MemberActionTypes.PAN_FETCH_RESPONSE_CHANGED:
			return { ...state, panListFetchResponse: '' };

		/** *******************  PAN FETCH ******************* */

		/** *******************  PAN UPDATE ******************* */

		case MemberActionTypes.PAN_UPDATE_SUCCESS:
			return {
				...state,
				panListUpdateResponse: action.payload,
				panListUpdateResponseArr: docodeResponse(action.payload, 'Update_Pan_Card'),
			};

		case MemberActionTypes.PAN_UPDATE_FAILURE:
			return { ...state, panListUpdateResponse: action.payload };

		case MemberActionTypes.PAN_UPDATE_RESPONSE_CHANGED:
			return { ...state, panListUpdateResponse: null };

		/** *******************  PAN UPDATE ******************* */

		/** *******************  DRIVINGLICENCE FETCH ******************* */

		case MemberActionTypes.DRIVINGLICENCE_FETCH_SUCCESS:
			return {
				...state,
				dlListFetchResponse: action.payload,
				dlListFetchResponseArr: docodeResponse(action.payload, 'Fetch_Driving_Licence'),
			};

		case MemberActionTypes.DRIVINGLICENCE_FETCH_FAILURE:
			return { ...state, dlListFetchResponse: action.payload };

		case MemberActionTypes.DRIVINGLICENCE_FETCH_RESPONSE_CHANGED:
			return { ...state, dlListFetchResponse: '' };

		/** *******************  DRIVINGLICENCE FETCH ******************* */

		/** *******************  DRIVINGLICENCE UPDATE ******************* */

		case MemberActionTypes.DRIVINGLICENCE_UPDATE_SUCCESS:
			return {
				...state,
				dlListUpdateResponse: action.payload,
				dlListUpdateResponseArr: docodeResponse(action.payload, 'Update_Driving_Licen'),
			};

		case MemberActionTypes.DRIVINGLICENCE_UPDATE_FAILURE:
			return { ...state, dlListUpdateResponse: action.payload };

		case MemberActionTypes.DRIVINGLICENCE_UPDATE_RESPONSE_CHANGED:
			return { ...state, dlListUpdateResponse: null };

		/** *******************  DRIVINGLICENCE UPDATE ******************* */

		/** *******************  VOTERID FETCH ******************* */

		case MemberActionTypes.VOTERID_FETCH_SUCCESS:
			return {
				...state,
				voteridListFetchResponse: action.payload,
				voteridListFetchResponseArr: docodeResponse(action.payload, 'Fetch_Voter_ID'),
			};

		case MemberActionTypes.VOTERID_FETCH_FAILURE:
			return { ...state, voteridListFetchResponse: action.payload };

		case MemberActionTypes.VOTERID_FETCH_RESPONSE_CHANGED:
			return { ...state, voteridListFetchResponse: '' };

		/** *******************  VOTERID FETCH ******************* */

		/** *******************  VOTERID UPDATE ******************* */

		case MemberActionTypes.VOTERID_UPDATE_SUCCESS:
			return {
				...state,
				voteridListUpdateResponse: action.payload,
				voteridListUpdateResponseArr: docodeResponse(action.payload, 'Update_Voter_ID'),
			};

		case MemberActionTypes.VOTERID_UPDATE_FAILURE:
			return { ...state, voteridListUpdateResponse: action.payload };

		case MemberActionTypes.VOTERID_UPDATE_RESPONSE_CHANGED:
			return { ...state, voteridListUpdateResponse: null };

		/** *******************  VOTERID UPDATE ******************* */

		/** *******************  BANK DETAILS FETCH ******************* */

		case MemberActionTypes.BANKDETAILS_FETCH_SUCCESS:
			return {
				...state,
				bankDetailsFetchResponse: action.payload,
				bankDetailsFetchResponseArr: docodeResponse(action.payload, 'Fetch_Bank_Details'),
			};

		case MemberActionTypes.BANKDETAILS_FETCH_FAILURE:
			return { ...state, bankDetailsFetchResponse: action.payload };

		case MemberActionTypes.BANKDETAILS_FETCH_RESPONSE_CHANGED:
			return { ...state, bankDetailsFetchResponse: '' };

		/** *******************  BANK DETAILS FETCH ******************* */

		/** *******************  BANK DETAILS FETCH ******************* */

		case MemberActionTypes.BANKDETAILS_UPDATE_SUCCESS:
			return {
				...state,
				bankDetailsUpdateResponse: action.payload,
				bankDetailsUpdateResponseArr: docodeResponse(action.payload, 'Update_Bank_Details'),
			};

		case MemberActionTypes.BANKDETAILS_UPDATE_FAILURE:
			return { ...state, bankDetailsUpdateResponse: action.payload };

		case MemberActionTypes.BANKDETAILS_UPDATE_RESPONSE_CHANGED:
			return { ...state, bankDetailsUpdateResponse: null };

		/** *******************  BANK DETAILS FETCH ******************* */

		/** *******************  BANK DETAILS FETCH ******************* */

		case MemberActionTypes.NOMINEE_DETAILS_FETCH_SUCCESS:
			return {
				...state,
				nomineeDetailsFetchResponse: action.payload,
				nomineeDetailsFetchResponseArr: docodeResponse(
					action.payload,
					'Fetch_Nominee_Details',
				),
			};

		case MemberActionTypes.NOMINEE_DETAILS_FETCH_FAILURE:
			return { ...state, nomineeDetailsFetchResponse: action.payload };

		case MemberActionTypes.NOMINEE_DETAILS_FETCH_RESPONSE_CHANGED:
			return { ...state, nomineeDetailsFetchResponse: '' };

		/** *******************  BANK DETAILS FETCH ******************* */

		/** *******************  NOMINEE UPDATE START******************* */

		case MemberActionTypes.NOMINEE_UPDATE_SUCCESS:
			return {
				...state,
				nomineeUpdateResponse: action.payload,
			};

		case MemberActionTypes.NOMINEE_UPDATE_FAILURE:
			return { ...state, nomineeUpdateResponse: action.payload };

		case MemberActionTypes.NOMINEE_UPDATE_RESPONSE_CHANGED:
			return { ...state, nomineeUpdateResponse: null };

		/** ******************* NOMINEE UPDATE END ******************* */

		/** *******************  NOMINEE UPDATE START******************* */

		case MemberActionTypes.NOMINEE_ADD_SUCCESS:
			return {
				...state,
				nomineeAddResponse: action.payload,
			};

		case MemberActionTypes.NOMINEE_ADD_FAILURE:
			return { ...state, nomineeAddResponse: action.payload };

		case MemberActionTypes.NOMINEE_ADD_RESPONSE_CHANGED:
			return { ...state, nomineeAddResponse: null };

		/** ******************* NOMINEE UPDATE END ******************* */

		/** *******************  MEMBER ID START******************* */

		case MemberActionTypes.SELECT_MEMBER_ID_CHANGE_START:
			return {
				...state,
				selectedMemberID: action.payload,
			};

		/** ******************* MEMBER ID END ******************* */

		default:
			return state;
	}
};
