// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-duplicates */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import LoginReducer from './login/login.reducer';
import GeneralReducer from './general/general.reducer';

/** ******************* MASTER START ********************* */
import MemberReducer from './masters/member/member.reducer';
import RoleReducer from './masters/role/role.reducer';
import HighMarkReducer from './masters/highmark/highmark.reducer';
import ProductReducer from './masters/product/product.reducer';
import BranchdetailsReducer from './masters/branch/branch.reducer';
import StaffReducer from './masters/staff/staff.reducer';
/** ******************* MASTER END ********************* */

/** ******************* OPERATION START ********************* */
import LoanProposalReducer from './operations/loan-proposal/loan-proposal.reducer';
import MemberApproveReducer from './operations/member-approve/member-approve.reducer';
import LoanDisbursementReducer from './operations/loan-disbursement/loan-disbursement.reducer';
/** ******************* OPERATION END ********************* */

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['login'],
};
const rootReducer = combineReducers({
	login: LoginReducer,
	general: GeneralReducer,
	// Master
	role: RoleReducer,
	highMark_Check: HighMarkReducer,
	member_details: MemberReducer,
	memberapprove: MemberApproveReducer,
	product: ProductReducer,
	branch_details: BranchdetailsReducer,
	loan_proposal: LoanProposalReducer,
	staff_details: StaffReducer,
	loan_Disbursement: LoanDisbursementReducer,
});

export default persistReducer(persistConfig, rootReducer);
