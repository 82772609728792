// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import LoanProposalActionTypes from './loan-proposal.types';
import {
	fetchListLoanProposal,
	addDetailsLoanProposal,
	getLoanProposalDetails,
	updateLoanProposal,
	deleteLoanProposal,
} from './loan-proposal.services';

import {
	fetchLoanProposalListSuccess,
	fetchLoanProposalListFailure,
	fetchLoanProposalListResponseChanged,
	addLoanProposalSuccess,
	addLoanProposalFailure,
	addLoanProposalResponseChanged,
	fetchLoanProposalDetailSuccess,
	fetchLoanProposalDetailFailure,
	fetchLoanProposalDetailResponseChanged,
	updateLoanProposalSuccess,
	updateLoanProposalFailure,
	updateLoanProposalResponseChanged,
	deleteLoanProposalSuccess,
	deleteLoanProposalFailure,
	deleteLoanProposalResponseChanged,
} from './loan-proposal.action';
import { encodeJson } from '../../_common/enode-decode';

/** *********************** SATRT LOAN PROPOSAL FETCH LIST ************************ */
export function* loanProposalListFetch({ payload }: any) {
	const key = 'Fetch_Loan_Proposal_List';
	try {
		const { limit, loanIndexType, branchID, companyID } = payload;

		const jsonData = {
			Limit: limit,
			LoanIndexType: loanIndexType,
			BranchID: branchID,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchListLoanProposal, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchLoanProposalListFailure(responseData.data));
		} else {
			yield put(fetchLoanProposalListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchLoanProposalListFailure(error));
	}
}

export function* loanProposalListFetchResponseReset() {
	yield put(fetchLoanProposalListResponseChanged());
}

export function* onLoanProposalFetchList() {
	yield takeLatest(LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_LIST_START, loanProposalListFetch);
}

export function* onLoanProposalLIstFetchResponseReset() {
	yield takeLatest(
		LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_LIST_RESPONSE_RESET_START,
		loanProposalListFetchResponseReset,
	);
}
/** *********************** END LOAN PROPOSAL FETCH LIST ************************ */

/** *********************** SATRT ADD LOAN PROPOSAL ************************ */
export function* addLoanProposal({ payload }: any) {
	const key = 'Add_Loan_Proposal';
	try {
		const {
			loanProposalID,
			memberID,
			memberName,
			productID,
			productName,
			loanAmount,
			principalAmount,
			interestPercentage,
			interestAmount,
			loanProposalDate,
			loanStartDate,
			loanEndDate,
			loanPurposeCategory,
			purposeOfLoan,
			companyID,
			userID,
		} = payload;

		const jsonData = {
			LoanProposalID: loanProposalID,
			MemberID: memberID,
			MemberName: memberName,
			ProductID: productID,
			ProductName: productName,
			LoanAmount: loanAmount,
			PrincipalAmount: principalAmount,
			InterestPercentage: interestPercentage,
			InterestAmount: interestAmount,
			LoanProposalDate: loanProposalDate,
			LoanStartDate: loanStartDate,
			LoanEndDate: loanEndDate,
			LoanPurposeCategory: loanPurposeCategory,
			PurposeOfLoan: purposeOfLoan,
			CompanyID: companyID,
			IsClosedBy: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(addDetailsLoanProposal, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(addLoanProposalFailure(responseData.data));
		} else {
			yield put(addLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addLoanProposalFailure(error));
	}
}

export function* loanProposalAddResponseReset() {
	yield put(addLoanProposalResponseChanged());
}

export function* onAddLoanProposalDetails() {
	yield takeLatest(LoanProposalActionTypes.ADD_LOAN_PROPOSAL_START, addLoanProposal);
}

export function* onAddLoanProposalResponseReset() {
	yield takeLatest(
		LoanProposalActionTypes.ADD_LOAN_PROPOSAL_RESPONSE_RESET_START,
		loanProposalAddResponseReset,
	);
}
/** *********************** END ADD LOAN PROPOSAL ************************ */

/** *********************** SATRT FETCH LOAN PROPOSAL DETAILS ************************ */
export function* loanProposalDetailsFetch({ payload }: any) {
	const key = 'Fetch_Loan_Proposal_Details';
	try {
		const { loanRequestID, memberID, branchID, companyID } = payload;

		const jsonData = {
			LoanRequestID: loanRequestID,
			MemberID: memberID,
			BranchID: branchID,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(getLoanProposalDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchLoanProposalDetailFailure(responseData.data));
		} else {
			yield put(fetchLoanProposalDetailSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchLoanProposalDetailFailure(error));
	}
}

export function* loanProposalDetailsFetchResponseReset() {
	yield put(fetchLoanProposalDetailResponseChanged());
}

export function* onLoanProposalDetailsFetch() {
	yield takeLatest(
		LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_DETAILS_START,
		loanProposalDetailsFetch,
	);
}

export function* onLoanProposalDetailsFetchResponseReset() {
	yield takeLatest(
		LoanProposalActionTypes.FETCH_LOAN_PROPOSAL_DETAILS_RESPONSE_RESET_START,
		loanProposalDetailsFetchResponseReset,
	);
}
/** *********************** END FETCH LOAN PROPOSAL DETAILS ************************ */

/** *********************** SATRT UPDATE LOAN PROPOSAL Details ************************ */
export function* loanProposaUpdate({ payload }: any) {
	const key = 'Update_Loan_Proposal';
	try {
		const {
			loanRequestID,
			memberID,
			memberName,
			productID,
			productName,
			loanAmount,
			principalAmount,
			interestPercentage,
			interestAmount,
			loanProposalDate,
			loanStartDate,
			loanEndDate,
			loanPurposeCategory,
			purposeOfLoan,
			companyID,
			userID,
		} = payload;

		const jsonData = {
			LoanRequestID: loanRequestID,
			MemberID: memberID,
			MemberName: memberName,
			ProductID: productID,
			ProductName: productName,
			LoanAmount: loanAmount,
			PrincipalAmount: principalAmount,
			InterestPercentage: interestPercentage,
			InterestAmount: interestAmount,
			LoanProposalDate: loanProposalDate,
			LoanStartDate: loanStartDate,
			LoanEndDate: loanEndDate,
			LoanPurposeCategory: loanPurposeCategory,
			PurposeOfLoan: purposeOfLoan,
			CompanyID: companyID,
			IsClosedBy: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateLoanProposal, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(updateLoanProposalFailure(responseData.data));
		} else {
			yield put(updateLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateLoanProposalFailure(error));
	}
}

export function* loanProposalUpdateResponseReset() {
	yield put(updateLoanProposalResponseChanged());
}

export function* onUpdateLoanProposal() {
	yield takeLatest(LoanProposalActionTypes.UPDATE_LOAN_PROPOSAL_START, loanProposaUpdate);
}

export function* onLoanProposalUpdateResponseReset() {
	yield takeLatest(
		LoanProposalActionTypes.UPDATE_LOAN_PROPOSAL_RESPONSE_RESET_START,
		loanProposalUpdateResponseReset,
	);
}
/** *********************** END LOAN PROPOSAL UPDATE LIST ************************ */

/** *********************** SATRT UPDATE LOAN PROPOSAL Details ************************ */
export function* loanProposalDelete({ payload }: any) {
	const key = 'Delete_Loan_Proposal';
	try {
		const { loanRequestID } = payload;

		const jsonData = {
			LoanRequestID: loanRequestID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(deleteLoanProposal, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteLoanProposalFailure(responseData.data));
		} else {
			yield put(deleteLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteLoanProposalFailure(error));
	}
}

export function* loanProposalDeleteResponseReset() {
	yield put(deleteLoanProposalResponseChanged());
}

export function* onDeleteLoanProposal() {
	yield takeLatest(LoanProposalActionTypes.DELETE_LOAN_PROPOSAL_START, loanProposalDelete);
}

export function* onLoanProposalDeleteResponseReset() {
	yield takeLatest(
		LoanProposalActionTypes.DELETE_LOAN_PROPOSAL_RESPONSE_RESET_START,
		loanProposalDeleteResponseReset,
	);
}
/** *********************** END LOAN PROPOSAL UPDATE LIST ************************ */

export function* loanProposalSaga() {
	yield all([
		call(onLoanProposalFetchList),
		call(onLoanProposalLIstFetchResponseReset),
		call(onAddLoanProposalDetails),
		call(onAddLoanProposalResponseReset),
		call(onLoanProposalDetailsFetch),
		call(onLoanProposalDetailsFetchResponseReset),
		call(onUpdateLoanProposal),
		call(onLoanProposalUpdateResponseReset),
		call(onDeleteLoanProposal),
		call(onLoanProposalDeleteResponseReset),
	]);
}
