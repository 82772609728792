// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const fetchMemberListApproval = (fetchData: JSON) => {
	const data = {
		FetchMemberApprovalListToken: fetchData,
	};
	const url = `${API_URL}FetchMemberApprovalList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchMemberDetailsApproval = (fetchData: JSON) => {
	const data = {
		FetchApprovalMemberDetailToken: fetchData,
	};
	const url = `${API_URL}FetchApprovalMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** APPROVE MEMBER UPDATE START ***************** */

export const updateapproveMember = (fetchData: JSON) => {
	const data = {
		ApproveMemberToken: fetchData,
	};

	const url = `${API_URL}CreateApproveMember`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
