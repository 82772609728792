export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

// MASTER MENU TAB
export const mastersPageMenu = {
	// pages: {
	// 	id: 'masters',
	// 	text: 'Masters',
	// 	icon: 'Extension',
	// },
	// mastersPages: {
	// 	id: 'mastersPages',
	// 	text: 'Masters',
	// 	path: 'masters-pages',
	// 	icon: 'AutoStories',
	// 	subMenu: {
	// 		highMarkPage: {
	// 			id: 'highMarkPage',
	// 			text: 'HighMark',
	// 			path: 'master-pages/highMark-Page',
	// 			icon: 'CrueltyFree',
	// 		},
	// 	},
	// },
	highMarkPage: {
		id: 'highMarkPage',
		text: 'CRIF Check',
		path: 'master-pages/highMark-Page',
		icon: 'AutoAwesome',
	},
};

export const hidePages = {
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
		hide: true,
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
		hide: true,
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
		hide: true,
	},

	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		hide: true,
	},

	employeeID: {
		id: 'employeeID',
		text: 'employeeID',
		path: 'appointment/employee',
		hide: true,
	},
};

export const demoPagesMenu = {
	pages: {
		id: 'pages',
		text: 'Pages',
		icon: 'Extension',
	},
	listPages: {
		id: 'listPages',
		text: 'List Pages',
		path: 'list-pages',
		icon: 'Dvr',
		subMenu: {
			listBoxed: {
				id: 'listBoxed',
				text: 'Boxed List',
				path: 'list-pages/boxed-list',
				icon: 'ViewArray',
			},
			listFluid: {
				id: 'listFluid',
				text: 'Fluid List',
				path: 'list-pages/fluid-list',
				icon: 'ViewDay',
			},
		},
	},

	// login: {
	// 	id: 'login',
	// 	text: 'Login',
	// 	path: 'auth-pages/login',
	// 	icon: 'Login',
	// 	hide: true,
	// },
	// signUp: {
	// 	id: 'signUp',
	// 	text: 'Sign Up',
	// 	path: 'auth-pages/sign-up',
	// 	icon: 'PersonAdd',
	// 	hide: true,
	// },

	// page404: {
	// 	id: 'Page404',
	// 	text: '404 Page',
	// 	path: 'auth-pages/404',
	// 	icon: 'ReportGmailerrorred',
	// 	hide: true,
	// },

	// app: {
	// 	id: 'app',
	// 	text: 'Apps',
	// 	icon: 'Extension',
	// },

	// auth: {
	// 	id: 'auth',
	// 	text: 'Auth Pages',
	// 	icon: 'Extension',
	// },
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
