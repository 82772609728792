import GeneralActionTypes from './general.types';

export const isEditDetailsStart = (isEdit: any) => ({
	type: GeneralActionTypes.IS_EDIT_DETAILS_START,
	payload: isEdit,
});

export const isOpenCanvasStart = (isOpen: any) => ({
	type: GeneralActionTypes.IS_OPEN_OFFCANVAS_START,
	payload: isOpen,
});

export const isSideTabChangeStart = (siedTabChanged: any) => ({
	type: GeneralActionTypes.SIDE_TAB_VALUE_CHANGED,
	payload: siedTabChanged,
});

export const isDocumentTabBarTypeChangeStart = (tabBarType: any) => ({
	type: GeneralActionTypes.DOCUMENT_TAB_BAR_FOR_TYPE_CHANGED,
	payload: tabBarType,
});

export const isDocumentTabBarChangeStart = (isChangeImage: any) => ({
	type: GeneralActionTypes.DOCUMENT_TAB_BAR_VALUE_CHANGED,
	payload: isChangeImage,
});

/** ***************** GET COUNTRY START *********************** */
export const getCountryListStart = () => ({
	type: GeneralActionTypes.GET_COUNTRY_LIST_START,
});

export const getCountryListSuccess = (successData: any) => ({
	type: GeneralActionTypes.GET_COUNTRY_LIST_SUCCESS,
	payload: successData,
});

export const getCountryListFailure = (failureData: any) => ({
	type: GeneralActionTypes.GET_COUNTRY_LIST_FAILURE,
	payload: failureData,
});

export const getCountryListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_COUNTRY_LIST_RESPONSE_RESET,
});

export const getCountryListResponseChanged = () => ({
	type: GeneralActionTypes.GET_COUNTRY_LIST_RESPONSE_CHANGED,
});
/** ***************** GET COUNTRY END *********************** */

/** ***************** GET STATE START *********************** */
export const getStateListStart = (stateData: any) => ({
	type: GeneralActionTypes.GET_STATE_LIST_START,
	payload: stateData,
});

export const getStateListSuccess = (successData: any) => ({
	type: GeneralActionTypes.GET_STATE_LIST_SUCCESS,
	payload: successData,
});

export const getStateListFailure = (failureData: any) => ({
	type: GeneralActionTypes.GET_STATE_LIST_FAILURE,
	payload: failureData,
});

export const getStateListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_STATE_LIST_RESPONSE_RESET,
});

export const getStateListResponseChanged = () => ({
	type: GeneralActionTypes.GET_STATE_LIST_RESPONSE_CHANGED,
});
/** ***************** GET STATE END *********************** */

/** ***************** GET CITY START *********************** */
export const getCityListStart = (cityData: any) => ({
	type: GeneralActionTypes.GET_CITY_LIST_START,
	payload: cityData,
});

export const getCityListSuccess = (successData: any) => ({
	type: GeneralActionTypes.GET_CITY_LIST_SUCCESS,
	payload: successData,
});

export const getCityListFailure = (failureData: any) => ({
	type: GeneralActionTypes.GET_CITY_LIST_FAILURE,
	payload: failureData,
});

export const getCityListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_CITY_LIST_RESPONSE_RESET,
});

export const getCityListResponseChanged = () => ({
	type: GeneralActionTypes.GET_CITY_LIST_RESPONSE_CHANGED,
});
/** ***************** GET CITY END *********************** */

/** ***************** GET DISTRICT START *********************** */
export const getDistrictListStart = (districtData: any) => ({
	type: GeneralActionTypes.GET_DISTRICT_LIST_START,
	payload: districtData,
});

export const getDistrictListSuccess = (successData: any) => ({
	type: GeneralActionTypes.GET_DISTRICT_LIST_SUCCESS,
	payload: successData,
});

export const getDistrictListFailure = (failureData: any) => ({
	type: GeneralActionTypes.GET_DISTRICT_LIST_FAILURE,
	payload: failureData,
});

export const getDistrictListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_DISTRICT_LIST_RESPONSE_RESET,
});

export const getDistrictListResponseChanged = () => ({
	type: GeneralActionTypes.GET_DISTRICT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET DISTRICT END *********************** */

/** ***************** FETCH ALL BRANCHES START *********************** */
export const fetchAllBranchesStart = (fetchBranches: any) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCHES_START,
	payload: fetchBranches,
});

export const fetchAllBranchesSuccess = (successData: any) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCHES_SUCCESS,
	payload: successData,
});

export const fetchAllBranchesFailure = (failureData: any) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCHES_FAILURE,
	payload: failureData,
});

export const fetchAllBranchesResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCHES_RESPONSE_RESET,
});

export const fetchAllBranchesResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCHES_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL BRANCHES END *********************** */

/** ***************** FETCH STAFF BASESD ON BRANCH START *********************** */
export const fetchBranchStaffsStart = (fetchBranchStaff: any) => ({
	type: GeneralActionTypes.FETCH_BRANCH_STAFF_START,
	payload: fetchBranchStaff,
});

export const fetchBranchStaffsSuccess = (successData: any) => ({
	type: GeneralActionTypes.FETCH_BRANCH_STAFF_SUCCESS,
	payload: successData,
});

export const fetchBranchStaffsFailure = (failureData: any) => ({
	type: GeneralActionTypes.FETCH_BRANCH_STAFF_FAILURE,
	payload: failureData,
});

export const fetchBranchStaffsResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_BRANCH_STAFF_RESPONSE_RESET_START,
});

export const fetchBranchStaffsResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_BRANCH_STAFF_RESPONSE_CHANGED,
});
/** ***************** FETCH STAFF BASESD ON BRANCH END *********************** */

/** ***************** FETCH ALL PRODUCT *********************** */
export const fetchAllProductStart = (fetchAllProduct: any) => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_START,
	payload: fetchAllProduct,
});

export const fetchAllProductSuccess = (successData: any) => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_SUCCESS,
	payload: successData,
});

export const fetchAllProductFailure = (failureData: any) => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_FAILURE,
	payload: failureData,
});

export const fetchAllProductResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_RESPONSE_RESET_START,
});

export const fetchAllProductResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL PRODUCT *********************** */

/** ***************** FETCH ALL MEMBER *********************** */
export const fetchAllMemberStart = (fetchAllMember: any) => ({
	type: GeneralActionTypes.FETCH_ALL_MEMBER_START,
	payload: fetchAllMember,
});

export const fetchAllMemberSuccess = (successData: any) => ({
	type: GeneralActionTypes.FETCH_ALL_MEMBER_SUCCESS,
	payload: successData,
});

export const fetchAllMemberFailure = (failureData: any) => ({
	type: GeneralActionTypes.FETCH_ALL_MEMBER_FAILURE,
	payload: failureData,
});

export const fetchAllMemberResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_MEMBER_RESPONSE_RESET_START,
});

export const fetchAllMemberResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_MEMBER_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL MEMBER *********************** */

/** ***************** FETCH ALL ROLE *********************** */
export const fetchAllRoleStart = (fetchAllRole: any) => ({
	type: GeneralActionTypes.FETCH_ALL_ROLE_START,
	payload: fetchAllRole,
});

export const fetchAllRoleSuccess = (successData: any) => ({
	type: GeneralActionTypes.FETCH_ALL_ROLE_SUCCESS,
	payload: successData,
});

export const fetchAllRoleFailure = (failureData: any) => ({
	type: GeneralActionTypes.FETCH_ALL_ROLE_FAILURE,
	payload: failureData,
});

export const fetchAllRoleResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_ROLE_RESPONSE_RESET_START,
});

export const fetchAllRoleResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_ROLE_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL ROLE *********************** */

/** ***************** FETCH ALL BRANCH *********************** */
export const fetchAllBranchStart = (fetchAllBranch: any) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCH_START,
	payload: fetchAllBranch,
});

export const fetchAllBranchSuccess = (successData: any) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCH_SUCCESS,
	payload: successData,
});

export const fetchAllBranchFailure = (failureData: any) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCH_FAILURE,
	payload: failureData,
});

export const fetchAllBranchResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCH_RESPONSE_RESET_START,
});

export const fetchAllBranchResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCH_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL BRANCH *********************** */
