const loginActionTypes = {
	SET_CURRENT_USER: 'set_current_user',

	USER_SIGN_IN_START: 'user_sign_in_start',
	USER_SIGN_IN_SUCCESS: 'user_sign_in_success',
	USER_SIGN_IN_FAILURE: 'user_sign_in_failure',
	USER_SIGN_IN_RESPONSE_RESET: 'user_sign_in_response_reset',
	USER_SIGN_IN_RESPONSE_CHANGED: 'user_sign_in_response_changed',

	USER_SIGN_OUT_START: 'user_sign_out_start',
	USER_SIGN_OUT: 'user_sign_out',

	SIGN_IN_COMPANYID: 'sign_in_company_id',
	SIGN_IN_USER: 'sign_in_user',
	SIGN_IN_USERTYPE: 'sign_in_user_type',
};
export default loginActionTypes;
