// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import LoanDisbursementTypes from './loan-disbursement.type';

import {
	fetchListLoanDisbursement,
	fetchDetailsLoanDisbursement,
	disbursementLoanUpdate,
	addVehile,
} from './loan-disbursement.services';

import {
	fetchLoanDisbursementListSuccess,
	fetchLoanDisbursementListFailure,
	fetchLoanDisbursementListResponseChanged,
	fetchLoanDisbursementDetailsSuccess,
	fetchLoanDisbursementDetailsFailure,
	fetchLoanDisbursementDetailsResponseChanged,
	disbursementLoanUpdateSuccess,
	disbursementLoanUpdateFailure,
	disbursementLoanUpdateResponseChanged,
	vehicleAddSuccess,
	vehicleAddFailure,
	vehicleAddResponseChanged,
} from './loan-disbursement.action';
import { encodeJson } from '../../_common/enode-decode';

/** *********************** SATRT LOAN DISBURSEMENT FETCH LIST ************************ */
export function* loanDisbursementListFetch({ payload }: any) {
	const key = 'Disbursement_List_Fetch';
	try {
		const { limit, branchID, companyID } = payload;

		const jsonData = {
			Limit: limit,
			BranchID: branchID,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchListLoanDisbursement, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchLoanDisbursementListFailure(responseData.data));
		} else {
			yield put(fetchLoanDisbursementListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchLoanDisbursementListFailure(error));
	}
}

export function* loanDisbursementListFetchResponseReset() {
	yield put(fetchLoanDisbursementListResponseChanged());
}

export function* onLoanDisbursementFetchList() {
	yield takeLatest(
		LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_LIST_START,
		loanDisbursementListFetch,
	);
}

export function* onLoanDisbursementListFetchResponseReset() {
	yield takeLatest(
		LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_LIST_RESPONSE_RESET_START,
		loanDisbursementListFetchResponseReset,
	);
}
/** *********************** END LOAN DISBURSEMENT FETCH LIST ************************ */

/** *********************** SATRT LOAN DISBURSEMENT FETCH DETAILS ************************ */
export function* loanDisbursementDetailsFetch({ payload }: any) {
	const key = 'Disbursement_Details_Fetch';
	try {
		const { branchID, companyID, loanRequestID, memberID } = payload;

		const jsonData = {
			BranchID: branchID,
			CompanyID: companyID,
			LoanRequestID: loanRequestID,
			MemberID: memberID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchDetailsLoanDisbursement, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchLoanDisbursementDetailsFailure(responseData.data));
		} else {
			yield put(fetchLoanDisbursementDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchLoanDisbursementDetailsFailure(error));
	}
}

export function* loanDisbursementDetailsFetchResponseReset() {
	yield put(fetchLoanDisbursementDetailsResponseChanged());
}

export function* onLoanDisbursementFetchDetails() {
	yield takeLatest(
		LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_DETAILS_START,
		loanDisbursementDetailsFetch,
	);
}

export function* onLoanDisbursementDetailsFetchResponseReset() {
	yield takeLatest(
		LoanDisbursementTypes.FETCH_LOAN_DISBURSEMENT_DETAILS_RESPONSE_RESET_START,
		loanDisbursementDetailsFetchResponseReset,
	);
}
/** *********************** END LOAN DISBURSEMENT FETCH DETAILS ************************ */

/** *********************** SATRT LOAN DISBURSEMENT FETCH DETAILS ************************ */
export function* disbursementLoanUpdateFetch({ payload }: any) {
	const key = 'Disbusement_Loan_Update';
	try {
		const { branchID, companyID, memberID, loanRequestID, loanInfoID, loanCardSchedule } =
			payload;

		const jsonData = {
			BranchID: branchID,
			CompanyID: companyID,
			MemberID: memberID,
			LoanRequestID: loanRequestID,
			LoanInfoID: loanInfoID,
			LoanShedule: loanCardSchedule,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(disbursementLoanUpdate, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(disbursementLoanUpdateFailure(responseData.data));
		} else {
			yield put(disbursementLoanUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(disbursementLoanUpdateFailure(error));
	}
}

export function* disbursementLoanUpdateResponseReset() {
	yield put(disbursementLoanUpdateResponseChanged());
}

export function* onDisbursementLoanUpdate() {
	yield takeLatest(
		LoanDisbursementTypes.DISBURSEMENT_LOAN_UPDATE_START,
		disbursementLoanUpdateFetch,
	);
}

export function* onDisbursementLoanUpdateResponseReset() {
	yield takeLatest(
		LoanDisbursementTypes.DISBURSEMENT_LOAN_UPDATE_RESPONSE_RESET_START,
		disbursementLoanUpdateResponseReset,
	);
}
/** *********************** END LOAN DISBURSEMENT FETCH DETAILS ************************ */

/** *********************** SATRT VEHICLE ADD DETAILS ************************ */
export function* vehicleDetailsAdd({ payload }: any) {
	const key = 'Create_Vehicle_Details';
	try {
		const {
			branchID,
			companyID,
			memberID,
			vehicleName,
			vehicleModle,
			vehicleType,
			engineNumber,
			chassisNumber,
			vehicleNumberPlate,
			vehicleRcBookNumber,
			vehicleInsuranceNumber,
			vehicleRcBook,
			vehicleInsurance,
			numberPlateImage,
			frontImage,
			leftSideImage,
			rightSideImage,
			backImage,
		} = payload;

		const jsonData = {
			BranchID: branchID,
			CompanyID: companyID,
			MemberID: memberID,
			VehicleName: vehicleName,
			VehicleModle: vehicleModle,
			VehicleType: vehicleType,
			EngineNumber: engineNumber,
			ChassisNumber: chassisNumber,
			VehicleNumberPlate: vehicleNumberPlate,
			VehicleRcBookNumber: vehicleRcBookNumber,
			VehicleInsuranceNumber: vehicleInsuranceNumber,
			VehicleRcBook: vehicleRcBook,
			VehicleInsurance: vehicleInsurance,
			NumberPlateImage: numberPlateImage,
			FrontImage: frontImage,
			LeftSideImage: leftSideImage,
			RightSideImage: rightSideImage,
			BackImage: backImage,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(addVehile, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(vehicleAddFailure(responseData.data));
		} else {
			yield put(vehicleAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(vehicleAddFailure(error));
	}
}

export function* vehicleAddResponseReset() {
	yield put(vehicleAddResponseChanged());
}

export function* onVehicleAdd() {
	yield takeLatest(LoanDisbursementTypes.VEHICLE_ADD_START, vehicleDetailsAdd);
}

export function* onVehicleAddResponseReset() {
	yield takeLatest(
		LoanDisbursementTypes.VEHICLE_ADD_RESPONSE_RESET_START,
		vehicleAddResponseReset,
	);
}
/** *********************** END SATRT VEHICLE ADD DETAILS ************************ */

export function* loanDisbursementSaga() {
	yield all([
		call(onLoanDisbursementFetchList),
		call(onLoanDisbursementListFetchResponseReset),
		call(onLoanDisbursementFetchDetails),
		call(onLoanDisbursementDetailsFetchResponseReset),
		call(onDisbursementLoanUpdate),
		call(onDisbursementLoanUpdateResponseReset),
		call(onVehicleAdd),
		call(onVehicleAddResponseReset),
	]);
}
