import JWT from 'expo-jwt';


// useJwt Token Encode
// @ts-ignore
export const encodeJson = (encodeData: any, key: any) => JWT.encode(encodeData, key, { algorithm: 'HS512' });

// useJwt Token Decode
export const decodeJson = (decodeData: any, key: any) =>
// @ts-ignore
	JWT.encode(JSON.stringify(decodeData), key, { algorithm: 'HS512' });

// Decode
export const decodeTokenJson = (jsonData: string, key: string) => {
	const decodedData = JWT.decode(jsonData, key);
	return decodedData;
};
