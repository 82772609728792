import axios from 'axios';
import { API_URL } from '../../const';

/** *************** MEMBER UPDATE DETAILS ***************** */

export const addMember = (fetchData: JSON) => {
	const data = {
		AddMemberToken: fetchData,
	};

	const url = `${API_URL}CreateMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** MEMBER UPDATE DETAILS ***************** */

/** *************** MEMBER UPDATE DETAILS ***************** */

export const updateMember = (fetchData: JSON) => {
	const data = {
		UpdateMemberDetailsToken: fetchData,
	};

	const url = `${API_URL}UpdateMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** MEMBER Delete DETAILS ***************** */

export const deleteMember = (fetchData: JSON) => {
	const data = {
		DeleteMemberDetailsToken: fetchData,
	};

	const url = `${API_URL}DeleteMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** MEMBER FETCH LIST ***************** */

export const fetchMembersList = (fetchData: JSON) => {
	const data = {
		FetchMemberListToken: fetchData,
	};

	const url = `${API_URL}FetchMemberList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** MEMBER FETCH PARTICULAR DETAILS ***************** */

export const fetchMembersDetails = (fetchData: JSON) => {
	const data = {
		MemberDetailsFetchToken: fetchData,
	};

	const url = `${API_URL}FetchMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** AADHAR FETCH DETAILS ***************** */

export const fetchAadhar = (fetchData: JSON) => {
	const data = {
		AadharDetailsFetchToken: fetchData,
	};
	const url = `${API_URL}FetchAadharDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** AADHAR UPDATE DETAILS ***************** */

export const updateAadharDetails = (fetchData: JSON) => {
	const data = {
		AadharDetailsUpdateToken: fetchData,
	};
	const url = `${API_URL}UpdateAadharDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** PAN FETCH DETAILS ***************** */

export const fetchPanCard = (fetchData: JSON) => {
	const data = {
		PanCardDetailsFetchToken: fetchData,
	};

	const url = `${API_URL}FetchPanCardDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** PAN UPDATE DETAILS ***************** */

export const updatePanCard = (fetchData: JSON) => {
	const data = {
		UpdatePancardToken: fetchData,
	};

	const url = `${API_URL}UpdatePanCard`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** DL FETCH DETAILS ***************** */

export const fetchDrivingLicence = (fetchData: JSON) => {
	const data = {
		FetchDrivinglicenceToken: fetchData,
	};

	const url = `${API_URL}FetchDrivingLicence`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** DL UPDATE DETAILS ***************** */

export const DrivingLicenceUpdateDetails = (fetchData: JSON) => {
	const data = {
		UpdateDrivingLicencerToken: fetchData,
	};

	const url = `${API_URL}UpdateDrivingLicence`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** VOTERID FETCH DETAILS ***************** */

export const fetchVoterID = (fetchData: JSON) => {
	const data = {
		VoterIDFetchToken: fetchData,
	};

	const url = `${API_URL}FetchVoterIDDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** VOTERID UPDATE DETAILS ***************** */

export const updateVoterID = (fetchData: JSON) => {
	const data = {
		UpdateVoterIDToken: fetchData,
	};

	const url = `${API_URL}UpdateVoterID`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** BANK DETAILS FETCH  ***************** */

export const fetchBankDetails = (fetchData: JSON) => {
	const data = {
		BankDetailsFetchToken: fetchData,
	};

	const url = `${API_URL}FetchBankDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** BANK DETAILS UPDATE  ***************** */

export const updateBankDetails = (fetchData: JSON) => {
	const data = {
		UpdateBankDetailsToken: fetchData,
	};

	const url = `${API_URL}UpdateBankDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** NOMINEE DETAILS FETCH  ***************** */

export const fetchNomineeDetails = (fetchData: JSON) => {
	const data = {
		NomineeDetailsFetchToken: fetchData,
	};

	const url = `${API_URL}FetchNomineeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** NOMINEE DETAILS FETCH  ***************** */

export const updateNomineeDetails = (fetchData: JSON) => {
	const data = {
		UpdateNomineeDetailsToken: fetchData,
	};

	const url = `${API_URL}UpdateNomineeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** *************** NOMINEE ADD DETAILS FETCH  ***************** */

export const addNominee = (fetchData: JSON) => {
	const data = {
		CreateNomineeDetailsToken: fetchData,
	};

	const url = `${API_URL}CreateNomineeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
