// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-debugger */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/named */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import MemberActionTypes from './member.types';
import {
	updateMember,
	deleteMember,
	addMember,
	fetchMembersList,
	fetchMembersDetails,
	fetchAadhar,
	updateAadharDetails,
	fetchPanCard,
	updatePanCard,
	fetchDrivingLicence,
	DrivingLicenceUpdateDetails,
	fetchVoterID,
	updateVoterID,
	fetchBankDetails,
	updateBankDetails,
	fetchNomineeDetails,
	updateNomineeDetails,
	addNominee,
} from './member.services';
import {
	memberAddSuccess,
	memberAddFailure,
	memberAddResponseChanged,
	memberUpdateSuccess,
	memberUpdateFailure,
	memberUpdateResponseChanged,
	memberDeleteSuccess,
	memberDeleteFailure,
	memberDeleteResponseChanged,
	memberListFetchSuccess,
	memberListFetchFailure,
	fetchMemberDetailsSuccess,
	fetchMemberDetailsFailure,
	fetchMemberDetailsResponseChanged,
	memberListFetchResponseChanged,
	aadharDetailsFetchSuccess,
	aadharDetailsFetchFailure,
	aadharFetchResponseChanged,
	aadharUpdateSuccess,
	aadharUpdateFailure,
	aadharUpdateResponseChanged,
	panDetailsFetchSuccess,
	panDetailsFetchFailure,
	panFetchResponseChanged,
	panUpdateSuccess,
	panUpdateFailure,
	panUpdateResponseChanged,
	drivingLicenceFetchSuccess,
	drivingLicenceFetchFailure,
	drivingLicenceFetchResponseChanged,
	drivingLicenceUpdateSuccess,
	drivingLicenceUpdateFailure,
	drivingLicenceUpdateResponseChanged,
	voterIDFetchSuccess,
	voterIDFetchFailure,
	voterIDFetchResponseChanged,
	voterIDUpdateSuccess,
	voterIDUpdateFailure,
	voterIDUpdateResponseChanged,
	bankDetailsFetchSuccess,
	bankDetailsFetchFailure,
	bankDetailsFetchResponseChanged,
	bankDetailsUpdateSuccess,
	bankDetailsUpdateFailure,
	bankDetailsUpdateResponseChanged,
	nomineeDetailsFetchSuccess,
	nomineeDetailsFetchFailure,
	nomineeDetailsFetchResponseChanged,
	nomineeUpdateSuccess,
	nomineeUpdateFailure,
	nomineeUpdateResponseChanged,
	nomineeAddSuccess,
	nomineeAddFailure,
	nomineeAddResponseChanged,
} from './member.action';
import { encodeJson } from '../../_common/enode-decode';

/** *********************** SATRT MEMBER FETCH LIST ************************ */
export function* memberDetailsAdd({ payload }: any) {
	const key = 'Add_Member_Details';
	try {
		const {
			memberName,
			memberPhoneNumber,
			memberPhoto,
			memberDOB,
			fatherName,
			spouseName,
			maritalStatus,
			qualification,
			gender,
			occupation,
			panNumber,
			voterIDNumber,
			drivingLicenceNumber,
			permanentAddressLine1,
			permanentAddressLine2,
			permanentAddressState,
			permanentAddressCity,
			permanentPincode,
			currentAddressLine1,
			currentAddressLine2,
			currentState,
			currentCity,
			currentPincode,
			anualIncome,
			monthlyIncome,
			nomineeType,
			aadharNumber,
			companyID,
			branchID,
			userID,
		} = payload;

		const jsonData = {
			MemberName: memberName,
			MemberPhoto: memberPhoto,
			MemberPhoneNumber: memberPhoneNumber,
			FatherName: fatherName,
			SpouseName: spouseName,
			MemberDOB: memberDOB,
			MaritalStatus: maritalStatus,
			Qualification: qualification,
			Gender: gender,
			Occupation: occupation,
			PermanentAddressLine1: permanentAddressLine1,
			PermanentAddressLine2: permanentAddressLine2,
			PermanentAddressState: permanentAddressState,
			PermanentAddressCity: permanentAddressCity,
			PermanentPincode: permanentPincode,
			CurrentAddress1: currentAddressLine1,
			CurrentAddress2: currentAddressLine2,
			CurrentState: currentState,
			CurrentPincode: currentPincode,
			CurrentCity: currentCity,
			AnualIncome: anualIncome,
			MonthlyIncome: monthlyIncome,
			NomineeType: nomineeType,
			AadharNumber: aadharNumber,
			DrivingLicenceNumber: drivingLicenceNumber,
			PanNumber: panNumber,
			VoterIDNumber: voterIDNumber,
			CompanyID: companyID,
			BranchID: branchID,
			CreatedBy: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(addMember, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(memberAddFailure(responseData.data));
		} else {
			yield put(memberAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(memberAddFailure(error));
	}
}

export function* memberAddResponseReset() {
	yield put(memberAddResponseChanged());
}

export function* onAddMember() {
	yield takeLatest(MemberActionTypes.MEMBER_ADD_START, memberDetailsAdd);
}

export function* onMemberAddResponseReset() {
	yield takeLatest(MemberActionTypes.MEMBER_ADD_RESET_START, memberAddResponseReset);
}
/** *********************** END MEMBER FETCH LIST ************************ */

/** *********************** SATRT MEMBER FETCH LIST ************************ */
export function* updateExitsMember({ payload }: any) {
	const key = 'Update_Member_Details';
	try {
		const {
			memberID,
			memberName,
			memberPhoneNumber,
			memberPhoto,
			memberDOB,
			fatherName,
			spouseName,
			maritalStatus,
			qualification,
			gender,
			occupation,
			panNumber,
			voterIDNumber,
			drivingLicenceNumber,
			permanentAddressLine1,
			permanentAddressLine2,
			permanentAddressState,
			permanentAddressCity,
			currentAddressLine1,
			currentAddressLine2,
			currentState,
			currentCity,
			currentPincode,
			anualIncome,
			monthlyIncome,
			nomineeType,
			aadharNumber,
			companyID,
			branchID,
			userID,
		} = payload;

		const jsonData = {
			MemberID: memberID,
			MemberName: memberName,
			MemberPhoneNumber: memberPhoneNumber,
			MemberPhoto: memberPhoto,
			FatherName: fatherName,
			SpouseName: spouseName,
			MemberDOB: memberDOB,
			MaritalStatus: maritalStatus,
			Qualification: qualification,
			Gender: gender,
			Occupation: occupation,
			PermanentAddressLine1: permanentAddressLine1,
			PermanentAddressLine2: permanentAddressLine2,
			PermanentAddressState: permanentAddressState,
			PermanentAddressCity: permanentAddressCity,
			CurrentAddress1: currentAddressLine1,
			CurrentAddress2: currentAddressLine2,
			CurrentState: currentState,
			CurrentCity: currentCity,
			CurrentPincode: currentPincode,
			AnualIncome: anualIncome,
			MonthlyIncome: monthlyIncome,
			NomineeType: nomineeType,
			AadharNumber: aadharNumber,
			DrivingLicenceNumber: drivingLicenceNumber,
			PanNumber: panNumber,
			VoterIDNumber: voterIDNumber,
			CompanyID: companyID,
			BranchID: branchID,
			ModifiedBy: userID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateMember, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(memberUpdateFailure(responseData.data));
		} else {
			yield put(memberUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(memberUpdateFailure(error));
	}
}

export function* memberUpdateResponseReset() {
	yield put(memberUpdateResponseChanged());
}

export function* onUpdateMember() {
	yield takeLatest(MemberActionTypes.MEMBER_UPDATE_START, updateExitsMember);
}

export function* onMemberUpdateResponseReset() {
	yield takeLatest(MemberActionTypes.MEMBER_UPDATE_RESET_START, memberUpdateResponseReset);
}
/** *********************** END MEMBER FETCH LIST ************************ */

/** *********************** SATRT MEMBER DELETE LIST ************************ */
export function* MemberDelete({ payload }: any) {
	const key = 'Delete_Member_Details';
	try {
		const { memberID } = payload;

		const jsonData = {
			MemberID: memberID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(deleteMember, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(memberDeleteFailure(responseData.data));
		} else {
			yield put(memberDeleteSuccess(responseData.data));
		}
	} catch (error) {
		yield put(memberDeleteFailure(error));
	}
}

export function* memberDeleteResponseReset() {
	yield put(memberDeleteResponseChanged());
}

export function* onDeleteMember() {
	yield takeLatest(MemberActionTypes.MEMBER_DELETE_START, MemberDelete);
}

export function* onMemberDeleteResponseReset() {
	yield takeLatest(MemberActionTypes.MEMBER_DELETE_RESET_START, memberDeleteResponseReset);
}
/** *********************** END MEMBER DELETE LIST ************************ */

/** *********************** SATRT MEMBER FETCH LIST ************************ */
export function* memberListFetch({ payload }: any) {
	const key = 'Fetch_Member_List';
	try {
		const { limit, companyID, branchID } = payload;

		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
			BranchID: branchID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchMembersList, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(memberListFetchFailure(responseData.data));
		} else {
			yield put(memberListFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(memberListFetchFailure(error));
	}
}

export function* memberFetchResponseReset() {
	yield put(memberListFetchResponseChanged());
}

export function* onFetchListMember() {
	yield takeLatest(MemberActionTypes.MEMBER_LIST_FETCH_START, memberListFetch);
}

export function* onMemberListFetchResponseReset() {
	yield takeLatest(MemberActionTypes.MEMBER_LIST_RESPONSE_RESET_START, memberFetchResponseReset);
}
/** *********************** END MEMBER FETCH LIST ************************ */

/** *********************** SATRT  MEMBER DETAILS LIST ************************ */
export function* fetchMemberDetails({ payload }: any) {
	const key = 'Fetch_Member_Details';
	try {
		const { memberID, companyID } = payload;

		const jsonData = {
			MemberID: memberID,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchMembersDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMemberDetailsFailure(responseData.data));
		} else {
			yield put(fetchMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberDetailsFailure(error));
	}
}

export function* fetchMemberDetailsResponseReset() {
	yield put(fetchMemberDetailsResponseChanged());
}

export function* onFetchMemberDetails() {
	yield takeLatest(MemberActionTypes.FETCH_MEMBER_DETAILS_START, fetchMemberDetails);
}

export function* onFetchMemberDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_DETAILS_RESPONSE_RESET_START,
		fetchMemberDetailsResponseReset,
	);
}
/** *********************** END MEMBER DETAILS FETCH LIST ************************ */

/** *********************** SATRT AADHAR FETCH LIST ************************ */
export function* aadharDetailsFetch({ payload }: any) {
	const key = 'Fetch_Aadhar_Details';
	try {
		const { memberID, companyID, branchID, aadahrType } = payload;

		const jsonData = {
			MemberID: memberID,
			AadahrType: aadahrType,
			BranchID: branchID,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchAadhar, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(aadharDetailsFetchFailure(responseData.data));
		} else {
			yield put(aadharDetailsFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(aadharDetailsFetchFailure(error));
	}
}

export function* aadharFetchResponseReset() {
	yield put(aadharFetchResponseChanged());
}

export function* onFetchListaadhar() {
	yield takeLatest(MemberActionTypes.AADHAR_FETCH_START, aadharDetailsFetch);
}

export function* onAadhareListFetchResponseReset() {
	yield takeLatest(MemberActionTypes.AADHAR_FETCH_RESPONSE_CHANGED, aadharFetchResponseReset);
}
/** *********************** END AADHAR FETCH LIST ************************ */

/** *********************** SATRT AADHAR UPDATE LIST ************************ */
export function* aadharDetailsUpdate({ payload }: any) {
	const key = 'Update_Aadhar_Details';
	try {
		const { aadharFront, aadharBack, memberID } = payload;

		const jsonData = {
			AadharFront: aadharFront,
			AadharBack: aadharBack,
			MemberID: memberID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateAadharDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(aadharUpdateFailure(responseData.data));
		} else {
			yield put(aadharUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(aadharUpdateFailure(error));
	}
}

export function* aadharUpdateResponseReset() {
	yield put(aadharUpdateResponseChanged());
}

export function* onUpdateAadhar() {
	yield takeLatest(MemberActionTypes.AADHAR_UPDATE_START, aadharDetailsUpdate);
}

export function* onAadharUpdateResponseReset() {
	yield takeLatest(
		MemberActionTypes.AADHAR_UPDATE_RESPONSE_RESET_START,
		aadharUpdateResponseReset,
	);
}
/** *********************** END AADHAR UPDATE LIST ************************ */

/** *********************** SATRT PAN FETCH LIST ************************ */
export function* panDetailsFetch({ payload }: any) {
	const key = 'Fetch_Pan_Card';
	try {
		const { limit, companyID } = payload;

		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchPanCard, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(panDetailsFetchFailure(responseData.data));
		} else {
			yield put(panDetailsFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(panDetailsFetchFailure(error));
	}
}

export function* panFetchResponseReset() {
	yield put(panFetchResponseChanged());
}

export function* onFetchListpan() {
	yield takeLatest(MemberActionTypes.PAN_FETCH_START, panDetailsFetch);
}

export function* onpanListFetchResponseReset() {
	yield takeLatest(MemberActionTypes.PAN_FETCH_RESPONSE_CHANGED, panFetchResponseReset);
}
/** *********************** END PAN FETCH LIST ************************ */

/** *********************** SATRT PAN UPDATE LIST ************************ */
export function* panDetailsUpdate({ payload }: any) {
	const key = 'Update_Pan_Card';
	try {
		const { panPhoto, memberID } = payload;

		const jsonData = {
			PanPhoto: panPhoto,
			MemberID: memberID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updatePanCard, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(panUpdateFailure(responseData.data));
		} else {
			yield put(panUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(panUpdateFailure(error));
	}
}

export function* panUpdateResponseReset() {
	yield put(panUpdateResponseChanged());
}

export function* onUpdatePancardDetails() {
	yield takeLatest(MemberActionTypes.PAN_UPDATE_START, panDetailsUpdate);
}

export function* onPancardUpdateResponseReset() {
	yield takeLatest(MemberActionTypes.PAN_UPDATE_RESPONSE_RESET_START, panUpdateResponseReset);
}
/** *********************** END PAN UPDATE LIST ************************ */

/** *********************** SATRT DRIVING LICENCE FETCH LIST ************************ */
export function* drivingLicenceDetailsFetch({ payload }: any) {
	const key = 'Fetch_Driving_Licence';
	try {
		const { limit, companyID } = payload;

		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchDrivingLicence, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(drivingLicenceFetchFailure(responseData.data));
		} else {
			yield put(drivingLicenceFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(drivingLicenceFetchFailure(error));
	}
}

export function* drivingLicenceFetchResponseReset() {
	yield put(drivingLicenceFetchResponseChanged());
}

export function* onFetchListDrivingLicence() {
	yield takeLatest(MemberActionTypes.DRIVINGLICENCE_FETCH_START, drivingLicenceDetailsFetch);
}

export function* onDrivingLicenceFetchResponseReset() {
	yield takeLatest(
		MemberActionTypes.DRIVINGLICENCE_FETCH_RESPONSE_CHANGED,
		drivingLicenceFetchResponseReset,
	);
}
/** *********************** END DRIVING LICENCE FETCH LIST ************************ */

/** *********************** SATRT DRIVING LICENCE UPDATE LIST ************************ */
export function* DrivingLicenceDetailsUpdate({ payload }: any) {
	const key = 'Update_Driving_Licen';
	try {
		const { drivingLicenceFront, drivingLicenceBack, memberID } = payload;

		const jsonData = {
			DrivingLicenceFront: drivingLicenceFront,
			DrivingLicenceBack: drivingLicenceBack,
			MemberID: memberID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(DrivingLicenceUpdateDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(drivingLicenceUpdateFailure(responseData.data));
		} else {
			yield put(drivingLicenceUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(drivingLicenceUpdateFailure(error));
	}
}

export function* drivingLicenceUpdateResponseReset() {
	yield put(drivingLicenceUpdateResponseChanged());
}

export function* onUpdateDrivingLicence() {
	yield takeLatest(MemberActionTypes.DRIVINGLICENCE_UPDATE_START, DrivingLicenceDetailsUpdate);
}

export function* onDrivingLicenceUpdateResponseReset() {
	yield takeLatest(
		MemberActionTypes.DRIVINGLICENCE_RESPONSE_UPDATE_RESET_START,
		drivingLicenceUpdateResponseReset,
	);
}
/** *********************** END DRIVING LICENCE UPDATE LIST ************************ */

/** *********************** SATRT VOTERID FETCH LIST ************************ */
export function* voterIDDetailsFetch({ payload }: any) {
	const key = 'Fetch_Voter_ID';
	try {
		const { limit, companyID } = payload;

		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchVoterID, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(voterIDFetchFailure(responseData.data));
		} else {
			yield put(voterIDFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(voterIDFetchFailure(error));
	}
}

export function* voterIDFetchResponseReset() {
	yield put(voterIDFetchResponseChanged());
}

export function* onFetchVoterID() {
	yield takeLatest(MemberActionTypes.VOTERID_FETCH_START, voterIDDetailsFetch);
}

export function* onVoterIDFetchResponseReset() {
	yield takeLatest(MemberActionTypes.VOTERID_RESPONSE_RESET_START, voterIDFetchResponseReset);
}
/** *********************** END VOTERID FETCH LIST ************************ */

/** *********************** SATRT VOTERID UPDATE LIST ************************ */
export function* voterIDDetailsUpdate({ payload }: any) {
	const key = 'Update_Voter_ID';
	try {
		const { voterIDFront, voterIDBack, memberID } = payload;

		const jsonData = {
			VoterIDFront: voterIDFront,
			VoterIDBack: voterIDBack,
			MemberID: memberID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateVoterID, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(voterIDUpdateFailure(responseData.data));
		} else {
			yield put(voterIDUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(voterIDUpdateFailure(error));
	}
}

export function* voterIdUpdateResponseReset() {
	yield put(voterIDUpdateResponseChanged());
}

export function* onUpdateVoterID() {
	yield takeLatest(MemberActionTypes.VOTERID_UPDATE_START, voterIDDetailsUpdate);
}

export function* onVoterIDUpdateResponseReset() {
	yield takeLatest(
		MemberActionTypes.VOTERID_UPDATE_RESPONSE_RESET_START,
		voterIdUpdateResponseReset,
	);
}
/** *********************** END VOTERID UPDATE LIST ************************ */

/** *********************** SATRT BANK DETAILS FETCH LIST ************************ */
export function* bankDetailsFetch({ payload }: any) {
	const key = 'Fetch_Bank_Details';
	try {
		const { limit, companyID } = payload;

		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchBankDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(bankDetailsFetchFailure(responseData.data));
		} else {
			yield put(bankDetailsFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(bankDetailsFetchFailure(error));
	}
}

export function* bankDetailsFetchResponseReset() {
	yield put(bankDetailsFetchResponseChanged());
}

export function* onFetchBankDetails() {
	yield takeLatest(MemberActionTypes.BANKDETAILS_FETCH_START, bankDetailsFetch);
}

export function* onBankDetailsFetchResponseReset() {
	yield takeLatest(
		MemberActionTypes.BANKDETAILS_FETCH_RESPONSE_CHANGED,
		bankDetailsFetchResponseReset,
	);
}
/** *********************** END BANK DETAILS FETCH LIST ************************ */

/** *********************** SATRT VOTERID UPDATE LIST ************************ */
export function* bankDetailsUpdate({ payload }: any) {
	const key = 'Update_Bank_Details';
	try {
		const { memberID, bankAccountType, confirmAccountNumber, bankIfsc, bankBranchDetails } =
			payload;

		const jsonData = {
			MemberID: memberID,
			AccountType: bankAccountType,
			AccountNumber: confirmAccountNumber,
			AccountIFSC: bankIfsc,
			BranchDetails: bankBranchDetails,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateBankDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(bankDetailsUpdateFailure(responseData.data));
		} else {
			yield put(bankDetailsUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(bankDetailsUpdateFailure(error));
	}
}

export function* bankDetailsUpdateResponseReset() {
	yield put(bankDetailsUpdateResponseChanged());
}

export function* onUpdateBankDetails() {
	yield takeLatest(MemberActionTypes.BANKDETAILS_UPDATE_START, bankDetailsUpdate);
}

export function* onBankDetailsUpdateResponseReset() {
	yield takeLatest(
		MemberActionTypes.BANKDETAILS_UPDATE_RESPONSE_RESET_START,
		bankDetailsUpdateResponseReset,
	);
}
/** *********************** END VOTERID UPDATE LIST ************************ */

/** *********************** SATRT NOMINEE DETAILS FETCH  ************************ */
export function* nomineeDetailsFetch({ payload }: any) {
	const key = 'Fetch_Nominee_Details';
	try {
		const { memberID, companyID } = payload;

		const jsonData = {
			MemberID: memberID,
			CompanyID: companyID,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(fetchNomineeDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(nomineeDetailsFetchFailure(responseData.data));
		} else {
			yield put(nomineeDetailsFetchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(nomineeDetailsFetchFailure(error));
	}
}

export function* nomineeDetailsUpdateResponseReset() {
	yield put(nomineeDetailsFetchResponseChanged());
}

export function* oNomineeDetailsFetch() {
	yield takeLatest(MemberActionTypes.NOMINEE_DETAILS_FETCH_START, nomineeDetailsFetch);
}

export function* onNomineeDetailsFetchResponseReset() {
	yield takeLatest(
		MemberActionTypes.NOMINEE_DETAILS_RESPONSE_RESET_START,
		nomineeDetailsUpdateResponseReset,
	);
}
/** *********************** END NOMINEE DETAILS FETCH  ************************ */

/** *********************** SATRT NOMINEE DETAILS UPDATE  ************************ */

export function* nomineeDetailsUpdate({ payload }: any) {
	const key = 'Update_Nominee_Details';
	try {
		const {
			memberID,
			nomineeID,
			nomineePhoto,
			nomineeName,
			nomineePhoneNumber,
			nomineeDOB,
			nomineeRelationship,
			nomineeAadharNumber,
			addressLine1,
			addressLine2,
			nomineeState,
			nomineeCity,
			idProofType,
			idProofNumber,
		} = payload;

		const jsonData = {
			MemberID: memberID,
			NomineeID: nomineeID,
			NomineePhoto: nomineePhoto,
			NomineeName: nomineeName,
			NomineePhoneNumber: nomineePhoneNumber,
			NomineeDOB: nomineeDOB,
			NomineeRelationship: nomineeRelationship,
			NomineeAadharNumber: nomineeAadharNumber,
			AddressLine1: addressLine1,
			AddressLine2: addressLine2,
			NomineeState: nomineeState,
			NomineeCity: nomineeCity,
			IdProofType: idProofType,
			IdProofNumber: idProofNumber,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(updateNomineeDetails, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(nomineeUpdateFailure(responseData.data));
		} else {
			yield put(nomineeUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(nomineeUpdateFailure(error));
	}
}

export function* nomineeUpdateResponseReset() {
	yield put(nomineeUpdateResponseChanged());
}

export function* onNomineeUpdate() {
	yield takeLatest(MemberActionTypes.NOMINEE_UPDATE_START, nomineeDetailsUpdate);
}

export function* onNomineeUpdateResponseReset() {
	yield takeLatest(
		MemberActionTypes.NOMINEE_UPDATE_RESPONSE_RESET_START,
		nomineeUpdateResponseReset,
	);
}
/** *********************** END NOMINEE DETAILS FETCH LIST ************************ */

/** *********************** SATRT NOMINEE ADD ************************ */

export function* nomineeAdd({ payload }: any) {
	const key = 'Add_Nominee_Details';
	try {
		const {
			memberID,
			nomineeID,
			nomineePhoto,
			nomineeName,
			nomineePhoneNumber,
			nomineeDOB,
			nomineeRelationship,
			nomineeAadharNumber,
			addressLine1,
			addressLine2,
			nomineeState,
			nomineeCity,
			idProofType,
			idProofNumber,
		} = payload;

		const jsonData = {
			MemberID: memberID,
			NomineeID: nomineeID,
			NomineePhoto: nomineePhoto,
			NomineeName: nomineeName,
			NomineePhoneNumber: nomineePhoneNumber,
			NomineeDOB: nomineeDOB,
			NomineeRelationship: nomineeRelationship,
			NomineeAadharNumber: nomineeAadharNumber,
			AddressLine1: addressLine1,
			AddressLine2: addressLine2,
			NomineeState: nomineeState,
			NomineeCity: nomineeCity,
			IdProofType: idProofType,
			IdProofNumber: idProofNumber,
		};
		const fetchData = encodeJson(jsonData, key);
		// @ts-ignore
		const responseData = yield call(addNominee, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(nomineeAddFailure(responseData.data));
		} else {
			yield put(nomineeAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(nomineeAddFailure(error));
	}
}

export function* nomineeAddResponseReset() {
	yield put(nomineeAddResponseChanged());
}

export function* onNomineeAdd() {
	yield takeLatest(MemberActionTypes.NOMINEE_ADD_START, nomineeAdd);
}

export function* onNomineeAddResponseReset() {
	yield takeLatest(MemberActionTypes.NOMINEE_ADD_RESPONSE_RESET_START, nomineeAddResponseReset);
}
/** *********************** END NOMINEE ADD ************************ */

export function* memberSaga() {
	yield all([
		call(onAddMember),
		call(onMemberAddResponseReset),
		call(onUpdateMember),
		call(onMemberUpdateResponseReset),
		call(onDeleteMember),
		call(onMemberDeleteResponseReset),
		call(onFetchListMember),
		call(onMemberListFetchResponseReset),
		call(onFetchMemberDetails),
		call(onFetchMemberDetailsResponseReset),
		call(onFetchListaadhar),
		call(onAadhareListFetchResponseReset),
		call(onUpdateAadhar),
		call(onAadharUpdateResponseReset),
		call(onFetchListpan),
		call(onpanListFetchResponseReset),
		call(onUpdatePancardDetails),
		call(onPancardUpdateResponseReset),
		call(onFetchListDrivingLicence),
		call(onDrivingLicenceFetchResponseReset),
		call(onUpdateDrivingLicence),
		call(onDrivingLicenceUpdateResponseReset),
		call(onFetchVoterID),
		call(onVoterIDFetchResponseReset),
		call(onUpdateVoterID),
		call(onVoterIDUpdateResponseReset),
		call(onFetchBankDetails),
		call(onBankDetailsFetchResponseReset),
		call(onUpdateBankDetails),
		call(onBankDetailsUpdateResponseReset),
		call(oNomineeDetailsFetch),
		call(onNomineeDetailsFetchResponseReset),
		call(onNomineeUpdate),
		call(onNomineeUpdateResponseReset),
		call(onNomineeAdd),
		call(onNomineeAddResponseReset),
	]);
}
